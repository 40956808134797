import React, { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import './Header.css';
import { isCapacitor } from '../App';

import {
  FaCog,
  FaBook,
  FaFileAlt,
  FaMicrophone,
  FaDownload,
  FaSignOutAlt, 
  FaHome
} from 'react-icons/fa';

function Header({ handleLogout }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Pour changer dynamiquement le texte de la topbar selon la route
  const location = useLocation();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  // Détermine le label à afficher selon le pathname
  let mobileTopBarLabel = '';
  if (location.pathname.startsWith('/recorder')) {
    mobileTopBarLabel = 'Dictaphone';
  } else if (location.pathname.startsWith('/reports')) {
    mobileTopBarLabel = 'Rapports';
  } else if (location.pathname.startsWith('/settings')) {
    mobileTopBarLabel = 'Réglages';
  } else {
    // Par défaut, s'il y a d'autres routes...
    mobileTopBarLabel = 'Clarity';
  }

  return (
    <>
      {/* 
        Barre carmin mobile:
        - Pas de NavLink, juste un texte synchronisé avec la route
      */}
      {isCapacitor && (
        <div className="mobile-topbar-carmin">
          <img src="logo.png" alt="Logo" className="mobile-topbar-logo" />
          <span className="mobile-topbar-text">{mobileTopBarLabel}</span>
        </div>
      )}

      <header className={`app-header ${isCapacitor ? 'mobile' : ''}`}>
        {/* Left: Logo + Title */}
        <div className="header-left">
          <NavLink to="/reports">
            <img src="logo.png" alt="Logo" className="logo" />
          </NavLink>

          <h1 className="header-title">Clarity</h1>
        </div>

        {/* Right side: Rapports, Dictaphone, Réglages, and the Burger */}
        <div className="header-right">
          <NavLink
            to="/reports"
            className="nav-link nav-main nav-rapports"
            activeClassName="active"
            onClick={closeNav}
          >
            <FaFileAlt />
            <span className="link-text">Rapports</span>
          </NavLink>

          {isCapacitor && (
            <NavLink
              to="/recorder"
              className="nav-link nav-main nav-dictaphone"
              activeClassName="active"
              onClick={closeNav}
            >
              <FaMicrophone />
              <span className="link-text">Dictaphone</span>
            </NavLink>
          )}

{isCapacitor ? (
          <NavLink
            to="/home"
            className="nav-link nav-main nav-home"
            activeClassName="active"
            onClick={closeNav}
          >
            <FaHome />
          </NavLink>
) : (
          <NavLink
            to="/settings"
            className="nav-link nav-main nav-settings"
            activeClassName="active"
            onClick={closeNav}
          >
            <FaCog />
            <span className="link-text">Réglages</span>
          </NavLink>
)}

          {/* Burger icon */}
          <div className="burger-menu" onClick={toggleNav}>
            ☰
          </div>

          {/* Burger dropdown */}
          {isNavOpen && (
            <div className="burger-dropdown">
              <NavLink to="/guide" className="nav-link" onClick={closeNav}>
                <FaBook />
                <span className="link-text">Guide</span>
              </NavLink>

              <NavLink to="/download" className="nav-link" onClick={closeNav}>
                <FaDownload />
                <span className="link-text">Téléchargements</span>
              </NavLink>

              <button
                className="logout-button"
                onClick={() => {
                  closeNav();
                  handleLogout();
                }}
              >
                <FaSignOutAlt />
                Se Déconnecter
              </button>
            </div>
          )}
        </div>
      </header>
    </>
  );
}

export default Header;