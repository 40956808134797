// src/components/UI/Icon.js

import React from 'react';
import './icon.css';

/**
 * Reusable Icon component that can render:
 *  - A circular background with a react-icon in the center
 *  - An optional title next to or below the icon
 *
 * Props:
 *  - icon: A React component from e.g. 'react-icons/fa'
 *  - size: The icon size in pixels (default 24)
 *  - backgroundColor: If provided, overrides the circle's background color
 *  - color: Icon color (default #fff)
 *  - title: Optional text label
 *  - titleColor: Color for the title text (default var(--color-primary))
 *  - titlePosition: "right" (side by side) or "bottom" (icon above, title below)
 *  - className: Additional CSS classes
 *  - style: Inline style overrides
 */
function Icon({
  icon: IconComponent,
  size = 24,
  backgroundColor,
  color = '#fff',
  title = '',
  titleColor,
  titlePosition = 'right', // or 'bottom'
  className = '',
  style = {},
  ...props
}) {
  // Merge inline styles with the custom properties
  const mergedStyle = { ...style };

  // If a backgroundColor is provided, set the --iconBg CSS variable
  if (backgroundColor) {
    mergedStyle['--iconBg'] = backgroundColor;
  }
  // If an icon color is provided, set the --iconColor CSS variable
  if (color) {
    mergedStyle['--iconColor'] = color;
  }
  // If a titleColor is provided, set the --titleColor CSS variable
  if (titleColor) {
    mergedStyle['--titleColor'] = titleColor;
  }

  // Choose a wrapper class based on titlePosition
  const wrapperClass = titlePosition === 'bottom' ? 'icon-wrapper vertical' : 'icon-wrapper';

  return (
    <div className={`${wrapperClass} ${className}`} style={mergedStyle} {...props}>
      {/* Icon circle */}
      <div className="icon-container">
        {IconComponent && <IconComponent size={size} className="icon" />}
      </div>

      {/* Title text (if provided) */}
      {title && (
        <span className="icon-title">{title}</span>
      )}
    </div>
  );
}

export default Icon;
