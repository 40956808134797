import React, { createContext, useState, useContext, useEffect } from 'react';
import Loader from '../Helpers/Loader';

const LoadingContext = createContext();

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleLoading = (event) => {
      setIsLoading(event.detail.isLoading);
    };

    window.addEventListener('loading', handleLoading);
    return () => window.removeEventListener('loading', handleLoading);
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      {isLoading && <Loader />}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  return useContext(LoadingContext);
} 