import React, { useState, useRef, useEffect } from "react";
import { usePopper } from "react-popper";
import Button from "../../Helpers/Button";
import { FaTimes } from "react-icons/fa";

/* ------------------------------------------------------------------
   Données et constantes
------------------------------------------------------------------ */

// Liste d'anomalies possibles
const ANOMALIES = [
  "Normal",
  "Hypokinésie légère",
  "Hypokinésie modérée",
  "Hypokinésie sévère",
  "Akinésie",
  "Dyskinésie",
  "Anévrysme",
  "Non évaluable",
];

// Couleurs associées aux anomalies
function getColorFromAnomaly(anomaly) {
  switch (anomaly) {
    case "Hypokinésie légère":
      return "#ffe0b3";
    case "Hypokinésie modérée":
      return "#ffbf80";
    case "Hypokinésie sévère":
      return "#ff9f40";
    case "Akinésie":
      return "#ff4d4d";
    case "Dyskinésie":
      return "#aa66cc";
    case "Anévrysme":
      return "#666666";
    case "Non évaluable":
      return "#ffffff"; // blanc
    case "Normal":
    default:
      return "lightgreen";
  }
}

const segmentsDefinition = [
  // Basal (6 segments) - épaisseur 45
  {
    id: "basal_anterior",
    label: "BA", 
    startAngle: -30,
    endAngle: 30,
    rIn: 115,
    rOut: 160,
  },
  {
    id: "basal_anterolateral",
    label: "BAL",
    startAngle: 30,
    endAngle: 90,
    rIn: 115,
    rOut: 160,
  },
  {
    id: "basal_inferolateral",
    label: "BIL",
    startAngle: 90,
    endAngle: 150,
    rIn: 115,
    rOut: 160,
  },
  {
    id: "basal_inferior",
    label: "BI",
    startAngle: 150,
    endAngle: 210,
    rIn: 115,
    rOut: 160,
  },
  {
    id: "basal_inferoseptal",
    label: "BIS",
    startAngle: 210,
    endAngle: 270,
    rIn: 115,
    rOut: 160,
  },
  {
    id: "basal_anteroseptal",
    label: "BAS",
    startAngle: 270,
    endAngle: 330,
    rIn: 115,
    rOut: 160,
  },
  // Mid (6 segments) - épaisseur 45
  {
    id: "mid_anterior",
    label: "MA",
    startAngle: -30,
    endAngle: 30,
    rIn: 70,
    rOut: 115,
  },
  {
    id: "mid_anterolateral",
    label: "MAL",
    startAngle: 30,
    endAngle: 90,
    rIn: 70,
    rOut: 115,
  },
  {
    id: "mid_inferolateral",
    label: "MIL",
    startAngle: 90,
    endAngle: 150,
    rIn: 70,
    rOut: 115,
  },
  {
    id: "mid_inferior",
    label: "MI",
    startAngle: 150,
    endAngle: 210,
    rIn: 70,
    rOut: 115,
  },
  {
    id: "mid_inferoseptal",
    label: "MIS",
    startAngle: 210,
    endAngle: 270,
    rIn: 70,
    rOut: 115,
  },
  {
    id: "mid_anteroseptal",
    label: "MAS",
    startAngle: 270,
    endAngle: 330,
    rIn: 70,
    rOut: 115,
  },

  // Apical (4 segments) - épaisseur 45
  {
    id: "apical_anterior",
    label: "AA", 
    startAngle: -45,
    endAngle: 45,
    rIn: 25,
    rOut: 70,
  },
  {
    id: "apical_lateral",
    label: "AL",
    startAngle: 45,
    endAngle: 135,
    rIn: 25,
    rOut: 70,
  },
  {
    id: "apical_inferior",
    label: "AI",
    startAngle: 135,
    endAngle: 225,
    rIn: 25,
    rOut: 70,
  },
  {
    id: "apical_septal",
    label: "AS",
    startAngle: 225,
    endAngle: 315,
    rIn: 25,
    rOut: 70,
  },

  // Apex (segment 17)
  {
    id: "apex",
    label: "AP",
    rIn: 0,
    rOut: 25,
  },
];

// Labels extérieurs (On veut qu’ils se positionnent autour du cercle basal).
// On place des angles adaptés à la nouvelle orientation (0° = en haut).
const outerLabels = [
  { text: "Antérieur", angle: 0, rotate: false },
  { text: "Antéro-latéral", angle: 60, rotate: false },
  { text: "Inféro-septal", angle: 240, rotate: false },
  { text: "Inférieur", angle: 180, rotate: false },
  { text: "Inféro-latéral", angle: 120, rotate: false },
  { text: "Antéro-septal", angle: 300, rotate: false },
];

/* ------------------------------------------------------------------
   Fonctions utilitaires
------------------------------------------------------------------ */

// Crée un objet où tous les segments = "normal"
function createAllNormalSegments() {
  const normalData = {};
  segmentsDefinition.forEach((seg) => {
    normalData[seg.id] = "Normal";
  });
  return normalData;
}

function parseInitialValue(value) {
  if (!value) return {};
  if (typeof value === "string") {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.warn("Invalid JSON in value prop:", e);
      return {};
    }
  }
  return value;
}

function isEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}

// Conversion polaire => cartésienne
function polarToCartesian(cx, cy, r, angleDeg) {
  const rad = ((angleDeg - 90) * Math.PI) / 180;
  return {
    x: cx + r * Math.cos(rad),
    y: cy + r * Math.sin(rad),
  };
}

// Path d'arc
function arcPath(cx, cy, rIn, rOut, startAngle, endAngle) {
  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  const p1 = polarToCartesian(cx, cy, rOut, startAngle);
  const p2 = polarToCartesian(cx, cy, rOut, endAngle);
  const p3 = polarToCartesian(cx, cy, rIn, endAngle);
  const p4 = polarToCartesian(cx, cy, rIn, startAngle);

  return [
    `M ${p1.x},${p1.y}`,
    `A ${rOut},${rOut} 0 ${largeArcFlag} 1 ${p2.x},${p2.y}`,
    `L ${p3.x},${p3.y}`,
    `A ${rIn},${rIn} 0 ${largeArcFlag} 0 ${p4.x},${p4.y}`,
    "Z",
  ].join(" ");
}

// Arc pour un label textPath
function simpleArcPath(cx, cy, r, startAngle, endAngle) {
  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  const pStart = polarToCartesian(cx, cy, r, startAngle);
  const pEnd = polarToCartesian(cx, cy, r, endAngle);
  return [
    `M ${pStart.x},${pStart.y}`,
    `A ${r},${r} 0 ${largeArcFlag} 1 ${pEnd.x},${pEnd.y}`,
  ].join(" ");
}

/* ------------------------------------------------------------------
   Sous-composants : SegmentArc / SegmentApex
------------------------------------------------------------------ */

function SegmentArc({ segment, anomaly, onClickSegment }) {
  const arcRef = useRef(null);
  const cx = 200;  // centre
  const cy = 200;
  const fillColor = getColorFromAnomaly(anomaly);
  const d = arcPath(cx, cy, segment.rIn, segment.rOut, segment.startAngle, segment.endAngle);

  const midAngle = (segment.startAngle + segment.endAngle) / 2;
  const midRadius = (segment.rIn + segment.rOut) / 2;
  const { x, y } = polarToCartesian(cx, cy, midRadius, midAngle);

  const handleClick = (e) => {
    onClickSegment(e, segment.id, arcRef.current);
  };

  return (
    <g ref={arcRef}>
      <path
        d={d}
        fill={fillColor}
        stroke="#555"
        strokeWidth="2"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <text
        x={x}
        y={y}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="12"
        fill="#000"
        style={{ pointerEvents: "none", fontWeight: "bold" }}
      >
        {segment.label}
      </text>
    </g>
  );
}

function SegmentApex({ segment, anomaly, onClickSegment }) {
  const apexRef = useRef(null);
  const cx = 200;
  const cy = 200;
  const fillColor = getColorFromAnomaly(anomaly);

  const handleClick = (e) => onClickSegment(e, segment.id, apexRef.current);

  return (
    <g ref={apexRef}>
      <circle
        cx={cx}
        cy={cy}
        r={segment.rOut}
        fill={fillColor}
        stroke="#555"
        strokeWidth="2"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="12"
        fill="#000"
        style={{ pointerEvents: "none", fontWeight: "bold" }}
      >
        {segment.label}
      </text>
    </g>
  );
}

/* ------------------------------------------------------------------
   Composant principal : BullseyeDiagram
------------------------------------------------------------------ */

export default function BullseyeDiagram({
  className = "",
  value = {},      // Peut être un objet ou un string JSON
  onChange,        // callback(newData) : pour prévenir le parent
  width = 500,
  height = 500,
}) {
  const [mouseAnchor, setMouseAnchor] = useState(null);

  // -- 1) État local pour les anomalies
  const [segmentAnomalies, setSegmentAnomalies] = useState({});

  // -- 2) useEffect pour synchroniser l’état quand `value` change
  useEffect(() => {
    let parsedVal = parseInitialValue(value);
    if (isEmpty(parsedVal)) {
      parsedVal = createAllNormalSegments();
    }
    setSegmentAnomalies(parsedVal);
  }, [value]);

  // État popover
  const [popover, setPopover] = useState({ visible: false, segmentId: null });
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(mouseAnchor, popperElement, {
    placement: "right",
    modifiers: [
      { name: "offset", options: { offset: [10, 10] } },
      { name: "preventOverflow", options: { padding: 8 } },
    ],
  });

  // -- 3) Gestion du clic sur un segment => ouvre le popover
  const handleSegmentClick = (e, segmentId) => {
    e.stopPropagation();

    // Création d'un div en position absolue
    const anchorDiv = document.createElement("div");
    anchorDiv.style.position = "absolute";
    anchorDiv.style.top = `${e.clientY}px`;
    anchorDiv.style.left = `${e.clientX}px`;
    document.body.appendChild(anchorDiv);

    setMouseAnchor(anchorDiv);

    setPopover({ visible: true, segmentId });
  };

  // -- 4) Choix d'une anomalie dans le popover
  const handleSelectAnomaly = (anomaly) => {
    if (!popover.segmentId) return;
    const segId = popover.segmentId;

    // Mettre à jour l'état local
    const newData = { ...segmentAnomalies, [segId]: anomaly };
    setSegmentAnomalies(newData);

    // Notifier le parent
    onChange(newData);

    // Fermer popover
    setPopover({ visible: false, segmentId: null });
  };

  // -- 5) Fermer le popover
  const handleClosePopover = () => {
    if (mouseAnchor) {
      document.body.removeChild(mouseAnchor);
    }
    setMouseAnchor(null);
    setPopover({ visible: false, segmentId: null });
  };

  // Légende des anomalies présentes
  const anomaliesUsed = new Set(Object.values(segmentAnomalies));
  anomaliesUsed.delete("Normal");

  // Arc pour les labels extérieurs (exemple)
  const arcRadius = 180;
  const arcSpreadDegrees = 30;
  const halfSpread = arcSpreadDegrees / 2;

  return (
    <div style={{ width, margin: "0 auto" }}>
      <div style={{ position: "relative", display: "block", textAlign: "center" }}>
        <svg
          className={className}
          viewBox="0 0 400 400"
          width={width}
          height={height}
          style={{ backgroundColor: "#fff" }}
          onClick={() => {
            if (popover.visible) handleClosePopover();
          }}
        >
          {/* Segments */}
          {segmentsDefinition.map((seg) => {
            const anomaly = segmentAnomalies[seg.id] || "Normal";
            return seg.id === "apex" ? (
              <SegmentApex
                key={seg.id}
                segment={seg}
                anomaly={anomaly}
                onClickSegment={handleSegmentClick}
              />
            ) : (
              <SegmentArc
                key={seg.id}
                segment={seg}
                anomaly={anomaly}
                onClickSegment={handleSegmentClick}
              />
            );
          })}

          {/* Labels extérieurs */}
          <defs>
            {outerLabels.map((item, idx) => {
              const startAngle = item.angle - halfSpread;
              const endAngle = item.angle + halfSpread;
              const d = simpleArcPath(200, 200, arcRadius, startAngle, endAngle);
              return (
                <path
                  key={idx}
                  id={`outerLabelPath-${idx}`}
                  d={d}
                  fill="none"
                  stroke="none"
                />
              );
            })}
          </defs>

          {outerLabels.map((item, idx) => (
            <text key={idx} fontSize="12" fill="#000" fontWeight="bold">
              <textPath
                href={`#outerLabelPath-${idx}`}
                startOffset="50%"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                {item.text}
              </textPath>
            </text>
          ))}
        </svg>

        {/* Popover anomalies */}
        {popover.visible && (
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: 6,
              padding: 10,
              boxShadow: "0 2px 8px rgba(0,0,0,0.25)",
              zIndex: 9999,
              minWidth: 120,
            }}
            {...attributes.popper}
            onClick={(ev) => ev.stopPropagation()}
          >
            <div style={{ fontWeight: "bold", marginBottom: 8, fontSize: 14 }}>
              {popover.segmentId?.toUpperCase().replace(/_/g, " ")}
            </div>
            {ANOMALIES.map((anom) => (
              <div
                key={anom}
                onClick={() => handleSelectAnomaly(anom)}
                style={{
                  cursor: "pointer",
                  padding: "4px 6px",
                  borderRadius: 4,
                  marginBottom: 2,
                  backgroundColor:
                    segmentAnomalies[popover.segmentId] === anom
                      ? "#ededed"
                      : "transparent",
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                  fontSize: 14,
                }}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    backgroundColor: getColorFromAnomaly(anom),
                    border: "1px solid #ccc",
                  }}
                />
                {anom}
              </div>
            ))}
            <hr style={{ margin: "6px 0" }} />
            <Button
            icon={FaTimes}
              onClick={handleClosePopover}
              variant="secondary"
            >
            </Button>
          </div>
        )}
      </div>

      {/* Légende */}
      {anomaliesUsed.size > 0 && (
        <div
          style={{
            marginTop: 10,
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: 4,
            background: "#fafafa",
            display: "flex",
            flexWrap: "wrap",
            gap: 12,
          }}
        >
          {[...anomaliesUsed].map((anom) => (
            <div
              key={anom}
              style={{ display: "flex", alignItems: "center", gap: 6 }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: getColorFromAnomaly(anom),
                  border: "1px solid #ccc",
                }}
              />
              <span style={{ fontSize: 12 }}>{anom}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
