import React, { useEffect, useState } from 'react';
import { FaCamera, FaTimes, FaDrawPolygon, FaSpinner } from 'react-icons/fa';
import Card from '../styles/card';
import Button from '../Helpers/Button';
import { doOCRWithGoogleVision } from './googleVisionOCR';
import './screenshot-picker.css';

/**
 * ScreenshotPicker
 *   - Lists all available electron "sources" (screens/windows).
 *   - For each source => 2 buttons:
 *       (A) Capture All => direct to OCR
 *       (B) Capture Part => opens region selection overlay for that source, then OCR
 */
export default function ScreenshotPicker({ onClose, onScreenshotDone }) {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadSources();
  }, []);

  async function loadSources() {
    try {
      if (!window.electronAPI) return;
      const srcs = await window.electronAPI.listAvailableSources();
      setSources(srcs);
    } catch (err) {
      console.error('Error listing sources =>', err);
    }
  }

  // Common function for sending recognized text back to parent, plus ending "loading"
  async function processScreenshotDataUrl(dataUrl) {
    if (!dataUrl) {
      console.log('No screenshot or user canceled');
      return;
    }
    // Convert dataUrl => base64
    const base64Only = dataUrl.split(',')[1];
    if (!base64Only) return;

    setLoading(true);
    try {
      const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const recognizedText = await doOCRWithGoogleVision(base64Only, googleApiKey);
      console.log('Recognized text =>', recognizedText);
      if (onScreenshotDone && recognizedText) {
        onScreenshotDone(recognizedText);
      }
    } catch (err) {
      console.error('Error processing screenshot =>', err);
    } finally {
      setLoading(false);
      onClose();
    }
  }

  // (A) Capture entire source => direct OCR
  async function handleCaptureAll(sourceId) {
    try {
      if (!window.electronAPI) return;
      const dataUrl = await window.electronAPI.captureScreenshotFromId(sourceId);
      await processScreenshotDataUrl(dataUrl);
    } catch (err) {
      console.error('Error capturing entire source =>', err);
    }
  }

  // (B) Capture Part => open region selection for a single source
  async function handleCapturePart(sourceId) {
    try {
      // This calls the function we just defined in main.js
      const dataUrl = await window.electronAPI.captureRegionScreenshotFromId(sourceId);
      if (!dataUrl) {
        console.log('User canceled or black screen?');
        return;
      }
      await processScreenshotDataUrl(dataUrl); // do OCR
    } catch (err) {
      console.error('Error capturing region =>', err);
    }
  }

  return (
    <div className="dictaphone-overlay screenshot-picker-overlay">
      <Card className="dictaphone-overlay-card screenshot-picker-card">
        <div className="screenshot-picker-header">
          <h3>Fenêtre à capturer</h3>

        {loading && (
          <span className="dictaphone-transcribing">
          <FaSpinner className={`dictaphone-spinner 'spinner-rouge'}`} />
        </span>
        )}

          <Button
            icon={FaTimes}
            collapseOnSmallScreen={false}
            variant="secondary"
            onClick={onClose}
          >
            Fermer
          </Button>
        
        </div>
        <div className="screenshot-picker-sources">
          {sources.map((src) => (
            <div className="screenshot-picker-source" key={src.id}>
              <img
                src={src.thumbnail} alt={src.name}
                className="screenshot-picker-thumbnail"
              />
              <div className="screenshot-picker-source-name">{src.name}</div>

              <div className="screenshot-picker-source-buttons">
              <Button
                icon={FaCamera}
                collapseOnSmallScreen={false}
                variant="reporttype-consultation"
                
                onClick={() => handleCaptureAll(src.id)}
                
              >
                Capturer l'ensemble
              </Button>

              <Button
                icon={FaDrawPolygon}
                collapseOnSmallScreen={false}
                variant="reporttype-consultation"
                onClick={() => handleCapturePart(src.id)}
              >
                Choisir une zone
              </Button>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}