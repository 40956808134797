import React, { useState, useEffect } from 'react';
import './uploader.css';

function Uploader() {
  // ---------------------------
  // STATE VARIABLES
  // ---------------------------
  const [autoEnabled, setAutoEnabled] = useState(false);
  const [setIsManualRetrieve] = useState(false);

  // fileStates => { "file.mp3": "En attente" | "uploading" | "done" }
  const [fileStates, setFileStates] = useState({});

  // availableReportTypes => e.g. ["typeExam", "typeConsult"]
  const [availableReportTypes, setAvailableReportTypes] = useState([]);

  // chosenTypes => { "file.mp3": "typeExam" }
  const [chosenTypes, setChosenTypes] = useState({});

  // Actual MP3 file list
  const [mp3Files, setMp3Files] = useState([]);

  // Status message => store color & text
  const [statusMsg, setStatusMsg] = useState({ color: '', text: '' });

  // Device name for "deviceInfo"
  const [deviceName, setDeviceName] = useState(null);

  // ---------------------------
  // EFFECT: On Mount
  //  - add electron listeners
  //  - request initial file list & report types
  // ---------------------------
  useEffect(() => {
    // Handlers corresponding to your old IPC event listeners

    // 1) Called when we receive the current MP3 file list from main
    function handleMp3FilesList(mp3Array) {
      // Merge old file states so we keep 'uploading'/'done' where possible
      setFileStates((prev) => {
        const updated = { ...prev };
        // set "En attente" if it’s new
        mp3Array.forEach((f) => {
          if (!updated[f]) {
            updated[f] = 'En attente';
          }
        });
        // remove files that no longer exist
        Object.keys(updated).forEach((key) => {
          if (!mp3Array.includes(key)) {
            delete updated[key];
          }
        });
        return updated;
      });

      // Keep track of the new file list
      setMp3Files(mp3Array);

      // If we clicked "Récupérer maintenant" manually
      setIsManualRetrieve((prevManual) => {
        if (prevManual) {
          // We reset it
          const newManual = false;

          if (mp3Array.length === 0) {
            setStatusMsg({ color: 'orange', text: 'Aucun fichier MP3 trouvé. Rien à envoyer.' });
          } else {
            setStatusMsg({ color: '#333', text: 'Récupération en cours...' });
            // set each file’s state to uploading
            setFileStates((prevStates) => {
              const updated = { ...prevStates };
              mp3Array.forEach((name) => {
                updated[name] = 'uploading';
              });
              return updated;
            });

            // Tell main process to start retrieval
            window.electronAPI.retrieveNow();

            // ask for updated list after a couple seconds
            setTimeout(() => {
              window.electronAPI.requestFileList();
            }, 2000);
          }
          return newManual;
        }
        return prevManual;
      });
    }

    // 2) Called when auto‑retrieve is toggled in main or elsewhere
    function handleAutoRetrieveUpdated(enabled) {
      setAutoEnabled(enabled);
      setStatusMsg({
        color: 'green',
        text: enabled ? 'Récupération auto ACTIVÉE' : 'Récupération auto désactivée',
      });
    }

    // 3) Called when a single file upload has finished
    function handleRetrievalFinished({ fileName, serverMessage }) {
      setFileStates((prevStates) => {
        const updated = { ...prevStates };
        updated[fileName] = 'done';
        return updated;
      });
      setStatusMsg({
        color: '#28a745',
        text: serverMessage
          ? `(${fileName}) ${serverMessage}`
          : `Fichier '${fileName}' récupéré avec succès.`,
      });
      // Refresh after a short delay
      setTimeout(() => {
        window.electronAPI.requestFileList();
      }, 1000);
    }

    // 4) Called when retrieval or upload has an error
    function handleRetrievalError(errorMsg) {
      setStatusMsg({ color: 'red', text: `Erreur: ${errorMsg}` });
    }

    // 5) Called when a dictaphone device is mounted/unmounted
    function handleDeviceMounted({ isMounted, deviceName }) {
      if (isMounted) {
        setDeviceName(deviceName || 'PHILIPS');
        window.electronAPI.requestFileList();
      } else {
        setDeviceName(null);
        setMp3Files([]); // clear
        setFileStates({});
      }
    }

    // 6) Called when we receive the list of report types from main
    function handleReportTypesList(types) {
      setAvailableReportTypes(types || []);
    }

    // Register all listeners (equivalent to old "ipcRenderer.on(...)")
    window.electronAPI.onMp3FilesList(handleMp3FilesList);
    window.electronAPI.onAutoRetrieveUpdated(handleAutoRetrieveUpdated);
    window.electronAPI.onRetrievalFinished(handleRetrievalFinished);
    window.electronAPI.onRetrievalError(handleRetrievalError);
    window.electronAPI.onDeviceMounted(handleDeviceMounted);
    window.electronAPI.onReportTypesList(handleReportTypesList);

    // Ask for initial data
    window.electronAPI.requestFileList();
    window.electronAPI.requestReportTypes();
    console.log('asked from dicta report type');

    // CLEANUP: remove listeners if you have "removeListener" in electronAPI
    return () => {
      // example: window.electronAPI.removeMp3FilesList(handleMp3FilesList);
      // etc...
    };
  }, [setIsManualRetrieve]);

  // ---------------------------
  // HANDLERS FOR BUTTONS
  // ---------------------------

  // Toggle auto retrieve
  function handleToggleAuto() {
    const newVal = !autoEnabled;
    setAutoEnabled(newVal);
    window.electronAPI.setAutoRetrieve(newVal);
    // We also show immediate feedback
    setStatusMsg({
      color: newVal ? 'green' : 'orange',
      text: newVal ? 'Récupération auto ACTIVÉE' : 'Récupération auto désactivée',
    });
  }

  // Manually retrieve now
  function handleRetrieveNow() {
    setIsManualRetrieve(true);
    // Send chosenTypes map to main
    window.electronAPI.setTypesMap(chosenTypes);
    // Then request an updated file list (which triggers the upload, etc.)
    window.electronAPI.requestFileList();
  }

  // Close the window
  function handleClose() {
    window.close();
  }

  // ---------------------------
  // RENDERING
  // ---------------------------

  // Render the file list
  const renderFileList = () => {
    if (!mp3Files || mp3Files.length === 0) {
      return (
        <div>
          Aucune dictée détectée. Clarity ne peut détecter que des fichiers .mp3.
        </div>
      );
    }

    return mp3Files.map((fileName) => {
      const currentState = fileStates[fileName] || 'En attente';
      const selectValue = chosenTypes[fileName] || '';

      // Format the status class
      let statusClass = 'file-status';
      if (currentState === 'uploading') {
        statusClass += ' uploading';
      } else if (currentState === 'done') {
        statusClass += ' uploaded';
      }

      return (
        <div className="file-item" key={fileName}>
          <div className="file-name">{fileName}</div>

          {/* Select for the report type */}
          <select
            className="report-type-selector"
            value={selectValue}
            onChange={(e) => {
              const newType = e.target.value;
              setChosenTypes((prev) => ({ ...prev, [fileName]: newType }));
            }}
          >
            <option value="">Type du rapport</option>
            {availableReportTypes.map((typeStr) => {
              // Capitalize as in old code
              const textDisplayed =
                typeStr.charAt(0).toUpperCase() + typeStr.slice(1).toLowerCase();
              return (
                <option key={typeStr} value={typeStr}>
                  {textDisplayed}
                </option>
              );
            })}
          </select>

          {/* Status */}
          <div className={statusClass}>{currentState}</div>
        </div>
      );
    });
  };

  // Device info text
  const deviceInfoText = deviceName
    ? `Dictaphone actuel : ${deviceName}`
    : 'Aucun dictaphone détecté.';

  return (
    <div className="dictaphone-wrapper">
      {/* Title Bar */}
      <div className="title-bar">
        <div className="title">Dictaphone</div>
        <button className="close-button" id="closeBtn" onClick={handleClose}>
          ✕
        </button>
      </div>

      {/* Main Content */}
      <div className="content">
        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {/* BOUTON AUTO-RETRIEVE */}
          <button
            id="autoBtn"
            className={`btn ${autoEnabled ? 'btn-auto-on' : 'btn-auto-off'}`}
            onClick={handleToggleAuto}
          >
            Récupération automatique : {autoEnabled ? 'ON' : 'OFF'}
          </button>
        </div>

        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <button id="retrieveBtn" className="btn btn-retrieve" onClick={handleRetrieveNow}>
            Récupérer maintenant
          </button>
        </div>

        {/* Status Message */}
        <div
          id="statusMsg"
          style={{ color: statusMsg.color, minHeight: '20px', margin: '10px 0' }}
        >
          {statusMsg.text}
        </div>

        {/* Device Info */}
        <div id="deviceInfo" style={{ fontStyle: 'italic', color: '#555' }}>
          {deviceInfoText}
        </div>

        <h3>Dictées détectées</h3>
        <div id="fileList" className="file-list">
          {renderFileList()}
        </div>
      </div>
    </div>
  );
}

export default Uploader;
