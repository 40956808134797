// Settings.js

import React, { useState, useEffect } from 'react';
import axios from '../services/api';
import './Settings.css';

import ButtonFixed from '../Helpers/ButtonFixed';
import Card from '../styles/card.js';
import LanguagesList from './LanguagesList';
import { isElectron, isCapacitor, isNative } from '../App';

// Import the needed icons
import {
  FaUser,
  FaListAlt,
  FaLanguage,
  FaKeyboard,
  FaTags,
  FaUndo,
  FaSave,
  FaMicrophone,
} from 'react-icons/fa';

// Import the reusable Icon component
import Icon from '../styles/icon.js';

// The base URL for your API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Settings() {
  const [username, setUsername] = useState('Guest');
  const [vocabulary, setVocabulary] = useState([]);
  const [newVocab, setNewVocab] = useState('');
  const [selectedVocabulary, setSelectedVocabulary] = useState('');
  const [reportLanguage, setReportLanguage] = useState('français');
  const [dictationLanguage, setDictationLanguage] = useState('fr');
  const [hasChanges, setHasChanges] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [showKeywords, setShowKeywords] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');
  const [isProMode, setIsProMode] = useState(() => localStorage.getItem('isProMode') === 'true');
  const [isTranscribeOff, setIsTranscribeOff] = useState(() => localStorage.getItem('isTranscribeOff') === 'true');

  

  // Example keyboard shortcuts
  const shortcuts = [
    { key: 'Insert', action: 'Activer le mode insertion' },
    { key: 'Ctrl + 1', action: 'Copier la première section' },
    { key: 'Ctrl + 2', action: 'Copier la deuxième section' },
    { key: 'Ctrl + Shift + C', action: 'Copier le rapport entier' },
    { key: 'Ctrl + Shift + R', action: 'Rafraîchir les rapports' },
  ];

  const electronShortcuts = [
    { key: 'Insert', action: 'Activer le mode insertion' },
    { key: 'PageUp', action: 'Afficher/masquer les fenêtres' },
    { key: 'PageDown', action: 'Ouvrir le dictaphone' },
    { key: 'Ctrl + D', action: 'Afficher/masquer le dictaphone' },
    { key: 'Ctrl + R', action: 'Afficher/masquer les rapports' },
    { key: 'Ctrl + 1', action: 'Copier la première section' },
    { key: 'Ctrl + 2', action: 'Copier la deuxième section' },
    { key: 'Ctrl + Shift + C', action: 'Copier le rapport entier' },
    { key: 'Ctrl + Shift + R', action: 'Rafraîchir les rapports' },
  ];

  // Add / remove keyword
  const handleAddKeyword = () => {
    if (newKeyword && !keywords.includes(newKeyword.trim())) {
      const updatedKeywords = [...keywords, newKeyword.trim()];
      setKeywords(updatedKeywords);
      setNewKeyword('');
      setHasChanges(true);
      localStorage.setItem('keywords', updatedKeywords.join(', '));
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    const updatedKeywords = keywords.filter((kw) => kw !== keywordToRemove);
    setKeywords(updatedKeywords);
    setHasChanges(true);
    localStorage.setItem('keywords', updatedKeywords.join(', '));
  };

  // Cancel modifications
  const handleCancel = () => {
    window.location.reload();
  };

  // Fetch existing settings from the server
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const chatId = localStorage.getItem('chatId');
        const response = await axios.post(`${API_BASE_URL}/getSettings`, { chatId });
        const data = response.data;

        setUsername(data.name || '');
        
        setVocabulary(
          data.voc
            ? data.voc.split(',')
                .map((word) => word.trim())
                .filter((word) => word)
            : []
        );
        setReportLanguage(data.langue || 'français');
        setDictationLanguage(data.french || 'fr');
       // setIsProMode(Boolean(data.isProMode));
        //setIsTranscribeOff(Boolean(data.isTranscribeOff));
        localStorage.setItem('langue', data.langue)
        localStorage.setItem('langue_iso', data.french)
        localStorage.setItem('name', data.name)
      //  localStorage.setItem('isProMode', data.isProMode)
      //  localStorage.setItem('isTranscribeOff', data.isTranscribeOff)
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();

    // Load keywords from localStorage
    const storedKeywords = localStorage.getItem('keywords');
    if (storedKeywords) {
      setKeywords(storedKeywords.split(',').map((word) => word.trim()));
    }
  }, []);

  // Save settings
  const handleSave = async () => {
    try {
      const chatId = localStorage.getItem('chatId');
      const vocabularyString = vocabulary.join(', ');
      const keywordsString = keywords.join(', ');

      localStorage.setItem('isProMode', isProMode);
      localStorage.setItem('isTranscribeOff', isTranscribeOff);

      const response = await axios.post(`${API_BASE_URL}/updateSettings`, {
        chatId,
        email: localStorage.getItem('email'),
        vocabulary: vocabularyString,
        french: dictationLanguage,
        langue: reportLanguage,
        isProMode,
        isTranscribeOff
      });

      if (response.status === 200) {
        alert('Réglages sauvegardés avec succès');
        setHasChanges(false);
        localStorage.setItem('keywords', keywordsString);
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des réglages:', error);
    }
  };

  // Generic handlers
  const handleToggleChange = (setter) => (e) => {
    setter(e.target.checked);
    setHasChanges(true);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setHasChanges(true);
  };

  // Vocabulary
  const handleAddVocabulary = () => {
    if (newVocab && !vocabulary.includes(newVocab.trim())) {
      setVocabulary([...vocabulary, newVocab.trim()]);
      setNewVocab('');
      setHasChanges(true);
    }
  };

  const handleRemoveVocabulary = () => {
    const updatedVocabulary = vocabulary.filter((v) => v !== selectedVocabulary);
    setVocabulary(updatedVocabulary);
    setSelectedVocabulary('');
    setHasChanges(true);
  };

  return (
    <div className="settings-container">
      {/* --- SECTION COMPTE --- */}
      <Card>
        
          {/* Instead of <span className="icon-section"><FaUser/></span>, use Icon */}
          <Icon icon={FaUser} size={24} title="Compte"/>
          
          <div className="form-group">
          <label htmlFor="username" className="form-label">
            Utilisateur : {username}
          </label>
      

          </div>
        {isNative && (
          <div className="toggle-section">
            <div className="toggle-row">
              <label>
                <span>Mode Pro</span>
                <input
                  type="checkbox"
                  checked={isProMode}
                  onChange={() => {
                    setIsProMode(!isProMode);
                    setHasChanges(true);
                  }}
                />
              </label>
            </div>
            <p style={{fontSize: '0.9em', color: '#888'}}><i>Le mode Pro permet de customiser le dictaphone au maximum en permettant d'enregister des brouillons directements et de desactiver la transcription live.</i></p>
          </div>
        )}


  
        </Card>
      {/* --- SECTION VOCABULAIRE --- */}
      <Card>
        
          <Icon icon={FaListAlt} size={24} title="Vocabulaire"/>
        
        <p>
          📄 Liste de vocabulaire :{' '}
          {vocabulary.length > 0 ? vocabulary.join(', ') : 'Aucun mot pour le moment'}
        </p>

        <div className="vocabulary-input">
          <input
            type="text"
            placeholder="Ajouter du vocabulaire..."
            value={newVocab}
            onChange={(e) => setNewVocab(e.target.value)}
          />
          <ButtonFixed
            collapseOnSmallScreen={false}
            variant="primary"
            onClick={handleAddVocabulary}
            disabled={!newVocab.trim()}
          >
            Ajouter
          </ButtonFixed>
        </div>
        <div className="vocabulary-controls">
          <select
            value={selectedVocabulary}
            onChange={handleInputChange(setSelectedVocabulary)}
          >
            <option value="">Choisir un mot à supprimer</option>
            {vocabulary.map((vocab) => (
              <option key={vocab} value={vocab}>
                {vocab}
              </option>
            ))}
          </select>
          <ButtonFixed
            collapseOnSmallScreen={false}
            variant="danger"
            disabled={!selectedVocabulary}
            onClick={handleRemoveVocabulary}
          >
            Supprimer
          </ButtonFixed>
        </div>
      </Card>

      {/* --- SECTION LANGUE --- */}
      <Card>
        
          <Icon icon={FaLanguage} size={24} title="Langue"/>
        
        <label className="input-label">
          📄 Langue des rapports :
          <select value={reportLanguage} onChange={handleInputChange(setReportLanguage)}>
            {Object.keys(LanguagesList.languages).map((lang) => (
              <option key={lang} value={LanguagesList.languages[lang]}>
                {lang}
              </option>
            ))}
          </select>
        </label>
        <label className="input-label">
          🎙️ Langue de dictée :
          <select value={dictationLanguage} onChange={handleInputChange(setDictationLanguage)}>
            {Object.keys(LanguagesList.languages).map((lang) => (
              <option key={lang} value={LanguagesList.languages[lang]}>
                {lang}
              </option>
            ))}
          </select>
        </label>
      </Card>


     
{isElectron && (
        <Card>
          <h3><Icon icon={FaMicrophone} size={24} title="Dictaphone"/></h3>
          
          <div className="toggle-section">
            <div className="toggle-row">
              <label>
                <span>Désactiver la transcription live</span>
                <input
                  type="checkbox" 
                  checked={isTranscribeOff}
                  onChange={() => {
                    setIsTranscribeOff(!isTranscribeOff);
                    setHasChanges(true);
                  }}
                />
              </label>
              </div>
              <p style={{fontSize: '0.9em', color: '#888'}}><i>Désactiver la transcription live peut améliorer la qualité des dictées mais nécessite d'appuyer manuellement sur le bouton de transcription après l'enregistrement.</i></p>
          </div>
        </Card>
      )}
      
      {/* --- SECTION RACCOURCIS CLAVIER --- */}
      
      {!isCapacitor && (
        !isElectron ? (
          <Card>
            <Icon icon={FaKeyboard} size={24} title="Raccourcis clavier"/>
            <ul className="shortcut-list">
              {shortcuts.map((shortcut, index) => (
                <li key={index}>
                  <span className="key-icon">{shortcut.key}</span> : {shortcut.action}
                </li>
              ))}
            </ul>
          </Card>
        ) : (
          <Card>
            <Icon icon={FaKeyboard} size={24} title="Raccourcis clavier"/>
            <ul className="shortcut-list">
              {electronShortcuts.map((shortcut, index) => (
                <li key={index}>
                  <span className="key-icon">{shortcut.key}</span> : {shortcut.action}
                </li>
              ))}
            </ul>
          </Card>
        )
      )}

  
      {/* --- SECTION MOTS-CLÉS --- */}
      {!isCapacitor && (
        <Card>
          <Icon icon={FaTags} size={24} title="Mots-clés"/>
          
          <p>
            Plusieurs mots-clés déjà enregistrés servent à créer de nouvelles sections lors de la dictée.
          </p>
          <p>
            Ajoutez-en ci-dessous pour personnaliser la structure de vos rapports.
          </p>

          <div className="add-keyword-section">
            <input
              type="text"
              placeholder="Ajouter un mot-clé..."
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
            />
            <ButtonFixed
              collapseOnSmallScreen={false}
              variant="primary"
              onClick={handleAddKeyword}
              disabled={!newKeyword.trim()}
            >
              Ajouter
            </ButtonFixed>
          </div>
          <ButtonFixed
            collapseOnSmallScreen={false}
            variant="primary"
            onClick={() => setShowKeywords((prev) => !prev)}
          >
            {showKeywords ? 'Masquer les mots-clés' : 'Afficher les mots-clés'}
          </ButtonFixed>

          {showKeywords && (
            <div className="keywords-section">
              <h4>Liste des mots-clés</h4>
              <ul>
                {keywords.length === 0 && <p>Aucun mot-clé pour l'instant.</p>}
                {keywords.map((keyword) => (
                  <li key={keyword}>
                    {keyword}
                    <ButtonFixed
                      collapseOnSmallScreen={false}
                      variant="danger"
                      onClick={() => handleRemoveKeyword(keyword)}
                    >
                      Supprimer
                    </ButtonFixed>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Card>
      )}


      {/* --- BOUTONS SAUVEGARDE --- */}
      <div className={`save-button-bar ${isElectron ? 'electron-mode' : ''}`}>
        <ButtonFixed
          collapseOnSmallScreen={false}
          variant="warning"
          disabled={!hasChanges}
          onClick={handleCancel}
        >
          <FaUndo /> Annuler
        </ButtonFixed>
        <ButtonFixed
          collapseOnSmallScreen={false}
          variant="primary"
          disabled={!hasChanges}
          onClick={handleSave}
        >
          <FaSave /> Sauvegarder les réglages
        </ButtonFixed>
      </div>
    </div>
  );
}

export default Settings;
