import React, { useState, useEffect } from 'react';
import Button from '../Helpers/Button';
import { FaChevronLeft, FaFileWord, FaToggleOn, FaToggleOff, FaUndo } from 'react-icons/fa';

const getCurrentDateFormatted = () => {
  const today = new Date();
  const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
  const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];

  const dayName = days[today.getDay()];
  const day = today.getDate();
  const monthName = months[today.getMonth()];
  const year = today.getFullYear();

  return `${dayName} ${day} ${monthName} ${year}`;
};

const ExportOptions = ({ onExportPDF, onExportWord, onClose, reportContent }) => {
  // ========================
  // 1. Checkboxes toggles
  // ========================
  const [includeUserAddress, setIncludeUserAddress] = useState(localStorage.getItem('includeUserAddress') === 'true');
  const [includeDestinationAddress, setIncludeDestinationAddress] = useState(localStorage.getItem('includeDestinationAddress') === 'true');
  const [includeConcerne, setIncludeConcerne] = useState(localStorage.getItem('includeConcerne') === 'true');
  const [includeIntroduction, setIncludeIntroduction] = useState(localStorage.getItem('includeIntroduction') === 'true');
  const [includeLocationDate, setIncludeLocationDate] = useState(localStorage.getItem('includeLocationDate') === 'true');
  const [includeInitialSalutation, setIncludeInitialSalutation] = useState(localStorage.getItem('includeInitialSalutation') === 'true');
  const [includeFinalSalutation, setIncludeFinalSalutation] = useState(localStorage.getItem('includeFinalSalutation') === 'true');
  const [includeSignature, setIncludeSignature] = useState(localStorage.getItem('includeSignature') === 'true');

  const [isMale, setIsMale] = useState(true);

  // ========================
  // 2. Default placeholders
  // ========================
  //  If you want these to appear in gray, we track if user typed anything different
  //  and set a style accordingly.

  const DEFAULT_USER_ADDRESS = 'Votre adresse';
  const DEFAULT_DESTINATION_ADDRESS = 'Adresse de destination';
  const DEFAULT_CONCERNE = 'Concerne:';
  const DEFAULT_SIGNATURE = 'Signature';

  // ========================
  // 3. States for text values 
  //    We load from localStorage if available; otherwise empty,
  //    but show placeholders in the <textarea>.
  // ========================
  const [userAddress, setUserAddress] = useState(
    localStorage.getItem('userAddress') || ''
  );
  const [destinationAddress, setDestinationAddress] = useState(
    localStorage.getItem('destinationAddress') || ''
  );
  const [concerneText, setConcerneText] = useState(
    localStorage.getItem('concerneText') || ''
  );
  const [introductionText, setIntroductionText] = useState(
    localStorage.getItem('introductionText') ||
      `Veuillez trouver ci-joint les informations relatives à ce patient que j'ai vu en consultation.`
  );

  const [currentDate] = useState(getCurrentDateFormatted());
  const [locationText, setLocationText] = useState(
    localStorage.getItem('locationText') || 'Genève, le'
  );

  const defaultSalutationMale = "Monsieur et Cher Confrère,";
  const defaultSalutationFemale = "Madame et Chère Consœur,";

  const defaultFinalSalutationMale =
    "Je vous prie d'agréer, Monsieur et Cher Confrère, l'expression de mes salutations distinguées.";
  const defaultFinalSalutationFemale =
    "Je vous prie d'agréer, Madame et Chère Consœur, l'expression de mes salutations distinguées.";

  const [initialSalutation, setInitialSalutation] = useState(() => {
    // If localStorage has something, use it; otherwise pick male or female default
    const fromStorage = isMale
      ? localStorage.getItem('initialSalutationMale')
      : localStorage.getItem('initialSalutationFemale');
    if (fromStorage) return fromStorage;
    return isMale ? defaultSalutationMale : defaultSalutationFemale;
  });

  const [finalSalutation, setFinalSalutation] = useState(() => {
    const fromStorage = isMale
      ? localStorage.getItem('finalSalutationMale')
      : localStorage.getItem('finalSalutationFemale');
    if (fromStorage) return fromStorage;
    return isMale ? defaultFinalSalutationMale : defaultFinalSalutationFemale;
  });

  const [signature, setSignature] = useState(
    localStorage.getItem('signature') || ''
  );

  // ========================
  // 4. Utility: reset all fields
  // ========================
  const resetAllFields = () => {
    setUserAddress('');
    setDestinationAddress('');
    setConcerneText('');
    setIntroductionText(
      `Veuillez trouver ci-joint les informations relatives à ce patient que j'ai vu en consultation.`
    );
    setLocationText('Genève, le');
    setInitialSalutation(isMale ? defaultSalutationMale : defaultSalutationFemale);
    setFinalSalutation(
      isMale ? defaultFinalSalutationMale : defaultFinalSalutationFemale
    );
    setSignature('');

    setIncludeUserAddress(true);
    setIncludeDestinationAddress(true);
    setIncludeConcerne(true);
    setIncludeIntroduction(true);
    setIncludeLocationDate(true);
    setIncludeInitialSalutation(true);
    setIncludeFinalSalutation(true);
    setIncludeSignature(true);
  };

  // ========================
  // 5. Toggle all fields on/off
  // ========================
  const areAllFieldsActive = () => {
    return (
      includeUserAddress &&
      includeDestinationAddress &&
      includeConcerne &&
      includeIntroduction &&
      includeLocationDate &&
      includeInitialSalutation &&
      includeFinalSalutation &&
      includeSignature
    );
  };

  const toggleAllFields = () => {
    const newValue = !areAllFieldsActive();
    setIncludeUserAddress(newValue);
    setIncludeDestinationAddress(newValue);
    setIncludeConcerne(newValue);
    setIncludeIntroduction(newValue);
    setIncludeLocationDate(newValue);
    setIncludeInitialSalutation(newValue);
    setIncludeFinalSalutation(newValue);
    setIncludeSignature(newValue);
  };

  // ========================
  // 6. Save data to localStorage
  // ========================
  useEffect(() => {
    localStorage.setItem('userAddress', userAddress);
    localStorage.setItem('destinationAddress', destinationAddress);
    localStorage.setItem('concerneText', concerneText);
    localStorage.setItem('introductionText', introductionText);
    localStorage.setItem('locationText', locationText);
    localStorage.setItem(isMale ? 'initialSalutationMale' : 'initialSalutationFemale', initialSalutation);
    localStorage.setItem(isMale ? 'finalSalutationMale' : 'finalSalutationFemale', finalSalutation);
    localStorage.setItem('signature', signature);

    localStorage.setItem('includeUserAddress', includeUserAddress);
    localStorage.setItem('includeDestinationAddress', includeDestinationAddress);
    localStorage.setItem('includeConcerne', includeConcerne);
    localStorage.setItem('includeIntroduction', includeIntroduction);
    localStorage.setItem('includeLocationDate', includeLocationDate);
    localStorage.setItem('includeInitialSalutation', includeInitialSalutation);
    localStorage.setItem('includeFinalSalutation', includeFinalSalutation);
    localStorage.setItem('includeSignature', includeSignature);
  }, [
    userAddress,
    destinationAddress,
    concerneText,
    introductionText,
    locationText,
    initialSalutation,
    finalSalutation,
    signature,
    isMale,
    includeUserAddress,
    includeDestinationAddress,
    includeConcerne,
    includeIntroduction,
    includeLocationDate,
    includeInitialSalutation,
    includeFinalSalutation,
    includeSignature
  ]);

  // ========================
  // 7. Update salutations depending on isMale
  // ========================
  useEffect(() => {
    if (isMale) {
      const savedInitial = localStorage.getItem('initialSalutationMale');
      const savedFinal = localStorage.getItem('finalSalutationMale');

      setInitialSalutation(savedInitial || defaultSalutationMale);
      setFinalSalutation(savedFinal || defaultFinalSalutationMale);
    } else {
      const savedInitial = localStorage.getItem('initialSalutationFemale');
      const savedFinal = localStorage.getItem('finalSalutationFemale');

      setInitialSalutation(savedInitial || defaultSalutationFemale);
      setFinalSalutation(savedFinal || defaultFinalSalutationFemale);
    }
  }, [isMale]);

  // ========================
  // 8. Render
  // ========================
  return (
    <div
      style={{
        minWidth: '500px',
        maxWidth: '100%',
        margin: 'auto',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
      }}
    >
      {/* Top buttons */}
      <div style={{ display: 'flex', justifyContent: 'left', gap: '15px', marginTop: '30px', marginBottom: '30px' }}>
        <Button onClick={onClose} variant="secondary" collapseOnSmallScreen={false}>
          <FaChevronLeft /> Retour
        </Button>
        <Button
          onClick={() =>
            onExportWord({
              userAddress,
              destinationAddress,
              concerneText,
              introductionText,
              locationDate: `${locationText} ${currentDate}`,
              initialSalutation,
              finalSalutation,
              signature,
              reportContent,
              includeUserAddress,
              includeDestinationAddress,
              includeConcerne,
              includeIntroduction,
              includeLocationDate,
              includeInitialSalutation,
              includeFinalSalutation,
              includeSignature,
            })
          }
          variant="word" collapseOnSmallScreen={false}
        >
          <FaFileWord /> Générer le rapport en Word
        </Button>
      </div>

      {/* Addresses on the same line */}
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        {/* User Address */}
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold' }}>
            <input
              type="checkbox"
              checked={includeUserAddress}
              onChange={() => setIncludeUserAddress(!includeUserAddress)}
              style={{ marginRight: '8px' }}
            />
            Adresse de l'expéditeur
          </label>
          {includeUserAddress && (
            <textarea
              value={userAddress}
              onChange={(e) => setUserAddress(e.target.value)}
              placeholder={DEFAULT_USER_ADDRESS}
              style={{
                width: '100%',
                height: '60px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px',
                // If userAddress is empty, text is shown in gray
                color: userAddress.trim() === '' ? '#999' : '#000',
              }}
            />
          )}
        </div>

        {/* Destination Address */}
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold' }}>
            <input
              type="checkbox"
              checked={includeDestinationAddress}
              onChange={() => setIncludeDestinationAddress(!includeDestinationAddress)}
              style={{ marginRight: '8px' }}
            />
            Adresse de destination
          </label>
          {includeDestinationAddress && (
            <textarea
              value={destinationAddress}
              onChange={(e) => setDestinationAddress(e.target.value)}
              placeholder={DEFAULT_DESTINATION_ADDRESS}
              style={{
                width: '100%',
                height: '60px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px',
                color: destinationAddress.trim() === '' ? '#999' : '#000',
              }}
            />
          )}
        </div>
      </div>

      {/* Concerne */}
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeConcerne}
            onChange={() => setIncludeConcerne(!includeConcerne)}
            style={{ marginRight: '8px' }}
          />
          Concerne:
        </label>
        {includeConcerne && (
          <textarea
            value={concerneText}
            onChange={(e) => setConcerneText(e.target.value)}
            placeholder={DEFAULT_CONCERNE}
            style={{
              width: '100%',
              height: '35px',
              marginTop: '8px',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontFamily: 'Arial',
              fontSize: '12px',
              color: concerneText.trim() === '' ? '#999' : '#000',
            }}
          />
        )}
      </div>

      {/* Lieu et Date */}
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeLocationDate}
            onChange={() => setIncludeLocationDate(!includeLocationDate)}
            style={{ marginRight: '8px' }}
          />
          Lieu et la date
        </label>
        {includeLocationDate && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '8px' }}>
            <input
              type="text"
              value={locationText}
              onChange={(e) => setLocationText(e.target.value)}
              placeholder="Lieu"
              style={{
                width: '30%',
                height: '35px',
                marginTop: '8px',
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Arial',
                fontSize: '12px',
              }}
            />
            <span>{currentDate}</span>
          </div>
        )}
      </div>

      {/* Salutations initiales */}
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeInitialSalutation}
            onChange={() => setIncludeInitialSalutation(!includeInitialSalutation)}
            style={{ marginRight: '8px' }}
          />
          Salutations initiales de genre
        </label>
        <button
          onClick={() => setIsMale(!isMale)}
          style={{
            marginLeft: '10px',
            padding: '8px 16px',
            borderRadius: '20px',
            border: '1px solid #ccc',
            backgroundColor: '#960018',
            color: 'white',
            cursor: 'pointer',
            outline: 'none',
          }}
        >
          {isMale ? 'Masculin' : 'Féminin'}
        </button>

        {includeInitialSalutation && (
          <textarea
            value={initialSalutation}
            onChange={(e) => setInitialSalutation(e.target.value)}
            placeholder="Entrez les salutations initiales"
            style={{
              width: '100%',
              height: '60px',
              marginTop: '8px',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontFamily: 'Arial',
              fontSize: '12px',
            }}
          />
        )}
      </div>

      {/* Introduction */}
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeIntroduction}
            onChange={() => setIncludeIntroduction(!includeIntroduction)}
            style={{ marginRight: '8px' }}
          />
          Introduction
        </label>
        {includeIntroduction && (
          <textarea
            value={introductionText}
            onChange={(e) => setIntroductionText(e.target.value)}
            placeholder="Entrez l'introduction"
            style={{
              width: '100%',
              height: '60px',
              marginTop: '8px',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontFamily: 'Arial',
              fontSize: '12px',
            }}
          />
        )}
      </div>

      {/* Salutations finales */}
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeFinalSalutation}
            onChange={() => setIncludeFinalSalutation(!includeFinalSalutation)}
            style={{ marginRight: '8px' }}
          />
          Salutations finales
        </label>
        {includeFinalSalutation && (
          <textarea
            value={finalSalutation}
            onChange={(e) => setFinalSalutation(e.target.value)}
            placeholder="Entrez les salutations finales"
            style={{
              width: '100%',
              height: '60px',
              marginTop: '8px',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontFamily: 'Arial',
              fontSize: '12px',
            }}
          />
        )}
      </div>

      {/* Signature */}
      <div style={{ marginBottom: '20px' }}>
        <label style={{ fontWeight: 'bold' }}>
          <input
            type="checkbox"
            checked={includeSignature}
            onChange={() => setIncludeSignature(!includeSignature)}
            style={{ marginRight: '8px' }}
          />
          Signature
        </label>
        {includeSignature && (
          <textarea
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
            placeholder={DEFAULT_SIGNATURE}
            style={{
              width: '100%',
              height: '60px',
              marginTop: '8px',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontFamily: 'Arial',
              fontSize: '12px',
              color: signature.trim() === '' ? '#999' : '#000',
            }}
          />
        )}
      </div>

      {/* Buttons for resetting or toggling all fields */}
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
        <Button icon={FaUndo} onClick={resetAllFields} variant="warning" collapseOnSmallScreen={false}>
          Rééinitialiser les champs
        </Button>
        <Button icon={!areAllFieldsActive() ? FaToggleOn : FaToggleOff} onClick={toggleAllFields} variant="primary" collapseOnSmallScreen={false}>
          {areAllFieldsActive() ? 'Désactiver tous les champs' : 'Activer tous les champs'}
        </Button>
      </div>
    </div>
  );
};

export default ExportOptions;
