import React, { useState, useEffect } from 'react';

/**
 * Props:
 *  - isGold: Boolean indicating if the user is on a gold plan
 *  - usageLeft: number of remaining usages
 *  - usageTotal: total usage (to compute fraction used)
 *
 * Example usage:
 * <UsageCircleView
 *    isGold={false}
 *    usageLeft={10}
 *    usageTotal={20}
 * />
 */

function UsageCircleView({ isGold, usageLeft, usageTotal }) {
  const [timeToMidnight, setTimeToMidnight] = useState('');

  // If gold, fractionLeft = 1.0; else usageLeft/usageTotal (guarding for zero).
  const fractionLeft = isGold
    ? 1.0
    : usageTotal > 0
    ? usageLeft / usageTotal
    : 0;

  // Decide ring color based on fractionLeft.
  // (Mirroring the Swift switch/case logic as closely as possible)
  const ringColor = (() => {
    if (fractionLeft >= 0.6) {
      return 'green';
    } else if (fractionLeft >= 0.3) {
      return 'orange';
    } else if (fractionLeft >= 0.1) {
      return 'orange';
    } else {
      return 'red';
    }
  })();

  // Called once usageLeft=0 to show countdown
  const updateTimeToMidnight = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0); // next midnight

    const diff = Math.floor((tomorrow - now) / 1000);
    if (diff <= 0) {
      setTimeToMidnight('00:00:00');
      return;
    }

    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = diff % 60;

    setTimeToMidnight(
      `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    );
  };

  useEffect(() => {
    if (!isGold && usageLeft <= 0) {
      // Update once immediately
      updateTimeToMidnight();
      // Then update every second
      const interval = setInterval(updateTimeToMidnight, 1000);
      return () => clearInterval(interval);
    }
  }, [usageLeft, isGold]);

  // For the circular progress bar, we use an SVG with two circles:
  //  - A "track" circle in the background (gray)
  //  - A "progress" circle whose stroke-dashoffset is set by fractionLeft
  const size = 40;
  const strokeWidth = 4;
  const center = size / 2;
  const radius = center - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - fractionLeft * circumference;

  // Handle tapping/clicking => open link
  const handleCircleClick = () => {
    window.open('https://gbm-medical.ch/abonnement', '_blank');
  };

  if (isGold) {
    // Gold plan => infinite usage
    return (
      <div
        style={styles.container}
        onClick={handleCircleClick}
        role="button"
        tabIndex={0}
      >
        <svg width={size} height={size} style={styles.svg}>
          {/* Background circle */}
          <circle
            stroke="gray"
            fill="transparent"
            strokeOpacity="0.2"
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
          />
          {/* Full "progress" circle */}
          <circle
            stroke={ringColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            cx={center}
            cy={center}
            r={radius}
            strokeDasharray={circumference}
            strokeDashoffset={0} // Full circle => no offset
            style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
          />
          {/* Infinity symbol in the center */}
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fill: ringColor,
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'system-ui',
            }}
          >
            ∞
          </text>
        </svg>
      </div>
    );
  } else if (usageLeft > 0) {
    // Standard usage => fraction
    return (
      <div
        style={styles.container}
        onClick={handleCircleClick}
        role="button"
        tabIndex={0}
      >
        <svg width={size} height={size} style={styles.svg}>
          {/* Background circle */}
          <circle
            stroke="gray"
            fill="transparent"
            strokeOpacity="0.2"
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
          />
          {/* Progress circle */}
          <circle
            stroke={ringColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            cx={center}
            cy={center}
            r={radius}
            strokeDasharray={circumference}
            strokeDashoffset={progressOffset}
            style={{
              transform: 'rotate(-90deg)',
              transformOrigin: '50% 50%',
              transition: 'stroke-dashoffset 0.3s ease-in-out',
            }}
          />
          {/* usageLeft text in the center */}
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fill: ringColor,
              fontSize: '20 px',
              fontWeight: 'bold',
              fontFamily: 'system-ui',
            }}
          >
            {usageLeft}
          </text>
        </svg>
      </div>
    );
  } else {
    // usageLeft=0 => show lock + countdown
    return (
      <div
        style={{ ...styles.container, cursor: 'pointer' }}
        onClick={handleCircleClick}
        role="button"
        tabIndex={0}
      >
        <div style={styles.lockContainer}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            {/* A simple lock icon (using an Emoji here or FontAwesome, etc.) */}
            <span style={{ color: 'red' }}>🔒</span>
            <span style={{ color: 'red', fontSize: '0.75rem' }}>
              {timeToMidnight}
            </span>
          </div>
          <div>
            <span style={{ color: 'red', fontSize: '0.75rem' }}>
              Dictées épuisées.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'inline-block',
    padding: '5px',
  },
  svg: {
    display: 'block',
  },
  lockContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default UsageCircleView;
