import React from 'react';
import './ButtonRecord.css';

function ButtonRecord({
  children,
  icon: Icon,
  variant = 'primary',
  isSpinning = false,
  ...props
}) {
  const iconClass = `button-record-icon ${isSpinning ? 'spinning' : ''}`;

  return (
    <button className={`button-record button-record-${variant}`} {...props}>
      {Icon && <Icon className={iconClass} />}
      {children}
    </button>
  );
}

export default ButtonRecord;
