import React, { useState, useEffect, useRef } from 'react';

import { OpenAI } from 'openai';
import { FaTimes, FaTrash,FaPlay, FaSpinner, FaMicrophone, FaStop, FaUserMd, FaUserAlt, FaLanguage, FaFileAlt } from 'react-icons/fa';

import './translator.css';
import LanguagesList from '../Settings/LanguagesList';
import Button from '../Helpers/Button';
import ButtonFixed from '../Helpers/ButtonFixed';
import UsageCircleView from '../Helpers/useCircle.js';
import Card from '../styles/card';
import Notification from '../Helpers/Notification';
import { useUniversalRecorder } from './useTranslator';

import { isCapacitor, isNative } from '../App';

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export default function Translator() {
  // ----------------------------------------------------------------
  // Gestion des états
  // ----------------------------------------------------------------
  const [notification, setNotification] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isTranscribing, setIsTranscribing] = useState(false);

  // Seule langue configurable dans l'UI : celle du patient
  const [patientLang, setPatientLang] = useState(null);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Langue du médecin, fixée dans le localStorage (ou par défaut 'en')
  const [doctorLang] = useState(() => localStorage.getItem('langue_iso') || 'fr');

  // Bascule : qui parle ?
  const [speakerRole, setSpeakerRole] = useState('doctor');

  // Stockage messages sous forme conversation
  const [messages, setMessages] = useState([]);

    // État overlay : contient un message (patient) si on l'a ouvert
    const [overlayMessage, setOverlayMessage] = useState(null);

    // Gérer la lecture audio (Play/Stop)
    // On stocke l'index du message en cours de lecture, ou null si aucun.
    const [playingIndex, setPlayingIndex] = useState(null);
    const audioRef = useRef(null); // Pour stocker l'objet Audio en cours

  // Enregistrement audio
  const {
    isRecording,
    setIsRecording,
    startUniversalRecording,
    stopUniversalRecording,
    resetUniversalRecorder
  } = useUniversalRecorder();

  // Gestion d'une file (queue) de blobs audio
  const [queue, setQueue] = useState([]);
  const queueRef = useRef(queue);
  queueRef.current = queue;
  const processingRef = useRef(false);

  // ----------------------------------------------------------------
  // Effets : online/offline
  // ----------------------------------------------------------------
  useEffect(() => {
    function handleOnline() { setIsOnline(true); }
    function handleOffline() { setIsOnline(false); }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // ----------------------------------------------------------------
  // Toggle (patient / médecin)
  // ----------------------------------------------------------------
  const toggleSpeakerRole = () => {
    setSpeakerRole(prev => (prev === 'doctor' ? 'patient' : 'doctor'));
  };

  // ----------------------------------------------------------------
  // Menu déroulant langue du patient
  // ----------------------------------------------------------------
  const allLanguages = Object.keys(LanguagesList.languages); 
  const filteredLanguages = allLanguages.filter((lang) =>
    lang.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectLanguage = (lang) => {
    setPatientLang(lang);
    setShowLanguageMenu(false);
  };

  // ----------------------------------------------------------------
  // Process queue (envoie chaque audio blob à Whisper)
  // ----------------------------------------------------------------
  async function processQueue(forcedQueue) {
    if (processingRef.current) return;
    processingRef.current = true;
    setIsTranscribing(true);

    try {
      let localQueue = forcedQueue || [...queueRef.current];
      while (localQueue.length > 0) {
        const firstAudio = localQueue[0];
        await sendAudioToOpenAI(firstAudio);
        localQueue = localQueue.slice(1);
        setQueue(localQueue);
        queueRef.current = localQueue;
      }
    } finally {
      processingRef.current = false;
      setIsTranscribing(false);
    }
  }

  // ----------------------------------------------------------------
  // Envoi du Blob audio vers OpenAI (Whisper)
  // ----------------------------------------------------------------
  async function sendAudioToOpenAI(audioBlob) {
    const patientLangIso = LanguagesList.languages[patientLang];
    
    if (!patientLangIso) {
      alert('Erreur dans le choix de la langue du patient; recharger la page.');
      return;
    }
    const whisperLanguage = (speakerRole === 'doctor') ? patientLangIso : doctorLang;

    try {
      setIsTranscribing(true);

      // Transcription du message
      const openai = new OpenAI({
        apiKey: OPENAI_API_KEY,
        dangerouslyAllowBrowser: true
      });

      const fileData = new File([audioBlob], 'partial.webm', {
        type: audioBlob.type,
      });

      const response = await openai.audio.transcriptions.create({
        file: fileData,
        model: !isCapacitor ? 'gpt-4o-transcribe' : 'whisper-1',
        language: whisperLanguage
      });

      const recognizedText = response?.text || '(Aucune transcription)';

      // Ajouter le message à la conversation
      setMessages((prev) => {
        // 1) Construire le nouveau tableau de messages
        const newMessages = [
          ...prev,
          {
            role: speakerRole,
            text: recognizedText,
            ttsStatus: 'idle',
            ttsUrl: null
          }
        ];
        
        // 2) Calculer l'index du message qu'on vient d'ajouter
        const newMessageIndex = newMessages.length - 1;
      
        // 3) Si c’est le médecin qui parle, on déclenche le TTS pour ce message
        if (speakerRole === 'doctor') {
          generateAndPlayTTS(recognizedText, newMessageIndex);
        }
      
        return newMessages;
      });
      

    } catch (err) {
      console.error('Erreur transcription =>', err);
      setNotification({
        message: `${err}`,
        type: 'warning'
      });
    } finally {
      setIsTranscribing(false);
    }
  }

  async function generateAndPlayTTS(text, messageIndex) {
    console.log('generateAndPlayTTS =>', text, messageIndex);
    try {
      // 1) Marquer le message comme "loading"
      setMessages(prev =>
        prev.map((msg, idx) =>
          idx === messageIndex
            ? { ...msg, ttsStatus: 'loading', ttsUrl: null }
            : msg
        )
      );
  
      const openai = new OpenAI({
        apiKey: OPENAI_API_KEY,
        dangerouslyAllowBrowser: true
      });
  
      const mp3 = await openai.audio.speech.create({
        model: "gpt-4o-mini-tts",
        voice: "alloy",
        input: text, 
        instructions:'Speak in a slow, disctintive way. You have to be understandable by a patient.',
        speed:'0.9'
      });
  
      // 2) Récupérer le blob audio
      const arrayBuffer = await mp3.arrayBuffer();
      const audioBlob = new Blob([arrayBuffer], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
  
      // 3) Stocker l’URL + marquer comme "ready"
      setMessages(prev =>
        prev.map((msg, idx) =>
          idx === messageIndex
            ? { ...msg, ttsStatus: 'ready', ttsUrl: audioUrl }
            : msg
        )
      );
    } catch (error) {
      console.error('Erreur TTS =>', error);
      setMessages(prev =>
        prev.map((msg, idx) =>
          idx === messageIndex
            ? { ...msg, ttsStatus: 'error', ttsUrl: null }
            : msg
        )
      );
    }
  }
  
  const handlePlayStop = (msgIndex) => {
    // 1) Si on clique sur un message qui n'est pas en train de jouer
    if (playingIndex !== msgIndex) {
      // - On arrête l'audio précédent s'il y en a un
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      // - On crée un nouvel audio
      const currentMessage = messages[msgIndex];
      if (currentMessage?.ttsUrl) {
        const audio = new Audio(currentMessage.ttsUrl);
        audioRef.current = audio;
        audio.play();
        setPlayingIndex(msgIndex);

        // Quand la lecture se termine => on repasse playingIndex à null
        audio.onended = () => {
          setPlayingIndex(null);
          // On peut libérer l'URL si besoin
          // URL.revokeObjectURL(currentMessage.ttsUrl);
        };
      }
    } else {
      // 2) Sinon, on clique sur le message déjà en lecture => Stop
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      setPlayingIndex(null);
    }
  };

  // ----------------------------------------------------------------
  // Démarrer/arrêter l'enregistrement
  // ----------------------------------------------------------------
  const triggerRecord = async () => {
    // Vérifier si le navigateur est Chrome
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (!isChrome && !isNative) {
      alert("L'enregistrement vocal ne fonctionne que sur le navigateur Google Chrome.");
      return;
    }
    if (!patientLang) {
      alert('Choisir une langue de traduction pour le patient avant de commencer.');
      return;
    }
    if (isRecording) {
      // On stop
      const finalAudio = await stopUniversalRecording();
      setIsRecording(false);

      // On met dans la file
      setQueue((prev) => [...prev, finalAudio]);
      if (!processingRef.current) {
        processQueue([...queueRef.current, finalAudio]);
      }

    } else {
      // On start
      startUniversalRecording();
    }
  };

  // ----------------------------------------------------------------
  // Réinitialiser
  // ----------------------------------------------------------------
  const handleClearAll = () => {
    resetUniversalRecorder();
    setQueue([]);
    setIsRecording(false);
    setPatientLang();
    setMessages([]);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
  };

  const handleMessageClick = (msg) => {
    if (msg.role === 'doctor') {
      setOverlayMessage(msg);
    }
  };

  // ----------------------------------------------------------------
  // Rendu UI
  // ----------------------------------------------------------------
  return (
    <div className="translator-container">
      {/* Notification globale */}
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}

       {/* Overlay si on a cliqué sur un message patient */}
       {overlayMessage && (
        <div className="translator-overlay">
          <div className="translator-overlay-card">
            
            <p style={{ fontSize: '2rem' }}>{overlayMessage.text}</p>

            {overlayMessage.ttsStatus === 'loading' && (
              <FaSpinner className="spinner" style={{ marginLeft: '8px' }} />
            )}

            <div  style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            {overlayMessage.ttsStatus === 'ready' && (
              <button className='button-fixed-play big'
                onClick={() => {
                  // Trouver l'index de ce message dans la liste
                  const index = messages.indexOf(overlayMessage);
                  if (index !== -1) handlePlayStop(index);
                }}
              >
                {playingIndex === messages.indexOf(overlayMessage) ? <FaStop></FaStop> : <FaPlay></FaPlay>}
              </button>
            )}
     

            <Button icon={FaTimes} variant="secondary" onClick={() => setOverlayMessage(null)} style={{ marginTop: '1rem' }}>
              

            </Button>
          </div>
          </div>
        </div>
      )}

      {/* Barre du haut : 
          - Choix langue du patient
          - Toggle qui parle
          - Indicateurs (offline / isTranscribing)
          - UsageCircleView
       */}
      <div className="translator-top-bar">
        
        {/* À gauche : Choix de la langue (patient) */}
        <div className="translator-top-bar-left">
          <ButtonFixed
            icon={FaLanguage}
            variant="primary"
            onClick={() => setShowLanguageMenu(!showLanguageMenu)}
            collapseOnSmallScreen={false}
          >
            {patientLang || 'Choisir langue patient'}
          </ButtonFixed>
          
          {showLanguageMenu && (
            <div className="language-menu">
              <div className="language-menu-search">
                <input
                  type="text"
                  placeholder="Rechercher…"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              {filteredLanguages.map((lang) => (
                <div
                  key={lang}
                  className="language-item"
                  onClick={() => handleSelectLanguage(lang)}
                >
                  {lang}
                </div>
              ))}
            </div>
          )}
        </div>

     

        {/* Indicateur : en cours / offline */}
        <div>
          {!isOnline ? (
            <div className={`${isCapacitor ? 'warning-bar' : 'warning-bar'} danger`}>
              ⚠️ Hors ligne
            </div>
          ) : isTranscribing ? (
            <div className={`${isCapacitor ? 'warning-bar' : 'warning-bar'} primary`}>
              <FaSpinner className="translator-spinner spinner-rouge" />
              <span> Transcription…</span>
            </div>
          ) : null}
        </div>

          
        {/* Usage */}
        <UsageCircleView isGold={true} usageLeft={20} usageTotal={20} />

        <Button
            icon={FaTrash}
            variant="danger"
            onClick={handleClearAll}
            disabled={!patientLang}
          >
            Réinitialiser
          </Button>
      </div>

      {/* Zone conversation (type chat) */}
      <Card className="translator-transcript-card">
        <div className="conversation-list">
          {messages.map((msg, index) => (
            <div 
              key={index}
              className={`conversation-bubble ${msg.role}`}
              onClick={() => handleMessageClick(msg)}
              style={{ cursor: msg.role === 'doctor' ? 'pointer' : 'default' }}
            >
              {msg.text}

              {/* État TTS */}
              {msg.ttsStatus === 'loading' && (
                <FaSpinner className="spinner" style={{ marginLeft: '8px' }} />
              )}
              {msg.ttsStatus === 'ready' && (
                 <button className='button-fixed-play'
                 onClick={() => {
                   // Trouver l'index de ce message dans la liste
                   const index = messages.indexOf(overlayMessage);
                   if (index !== -1) handlePlayStop(index);
                 }}
               >
                 {playingIndex === messages.indexOf(overlayMessage) ? <FaStop></FaStop> : <FaPlay></FaPlay>}
               </button>
              )}
              {msg.ttsStatus === 'error' && (
                <span style={{ color: 'red', marginLeft: '8px' }}>
                  [TTS indisponible]
                </span>
              )}
            </div>
          ))}
        </div>
      </Card>

      {/* Contrôles bas : record + reset */}
      <div className="translator-bottom-controls">
        <div className="translator-row">
     
     <div className="translator-first-row">

   {/* Toggle : patient / doctor */}
   <ButtonFixed
          variant={speakerRole === 'doctor' ? 'patient' : 'doctor'}
          onClick={toggleSpeakerRole}
          collapseOnSmallScreen={false}
        >
          {speakerRole === 'doctor' ? (
            <>
              Qui parle : <FaUserMd /> Médecin
            </>
          ) : (
            <>
              Qui parle : <FaUserAlt /> Patient
            </>
          )}
          
        </ButtonFixed>
        <div className={`recording-dot ${isRecording ? 'active' : 'inactive'}`} />

        </div>
          <button className="record-button" onClick={triggerRecord}>
            {isRecording ? <FaStop /> : <FaMicrophone />}
          </button>

        </div>
      </div>
    </div>
  );
}