import React, { useState, useMemo } from 'react';
import Button from '../Helpers/Button';
import ButtonFixed from '../Helpers/ButtonFixed';
import {
  FaTrash,
  FaTimes, 
  FaFilter
} from 'react-icons/fa';

export default function DraftsPanel({
  activeDrafts = [],
  sentDrafts = [],
  deletedDrafts = [],
  onSelectDraft,
  onDeleteDraft,
  onDeleteAllDraft,
  onClose,
  formatDate
}) {
  // ==== État pour filtrer par type de rapport ====
  // 'all' indiquera "Tous types"
  const [reportTypeFilter, setReportTypeFilter] = useState('all');

  // ==== État pour filtrer par statut ====
  // on laisse le sélecteur existant (active / sent / deleted / all)
  const [statusFilter, setStatusFilter] = useState('all');
  const [showStatusPicker, setShowStatusPicker] = useState(false);

  // Construire la liste complète des types rencontrés dans tous les brouillons
  const allDrafts = useMemo(
    () => [...activeDrafts, ...sentDrafts, ...deletedDrafts],
    [activeDrafts, sentDrafts, deletedDrafts]
  );
  
  const allReportTypes = useMemo(() => {
    const setOfTypes = new Set();
    allDrafts.forEach((d) => {
      if (d.reportType) setOfTypes.add(d.reportType);
    });
    return Array.from(setOfTypes);
  }, [allDrafts]);
  

  // Filtrer par type (si != 'all', on regarde si d.reportType === reportTypeFilter)
  const matchReportTypeFilter = (draft) => {
    if (reportTypeFilter === 'all') return true;
    return draft.reportType === reportTypeFilter;
  };

  // Trier les brouillons par date (du plus récent au plus ancien)
  const sortByDate = (drafts) => {
    return [...drafts].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;  // Ordre décroissant
    });
  };

  // Appliquer le tri aux trois listes
  const sortedActiveDrafts = useMemo(() => sortByDate(activeDrafts), [activeDrafts]);
  const sortedSentDrafts = useMemo(() => sortByDate(sentDrafts), [sentDrafts]);
  const sortedDeletedDrafts = useMemo(() => sortByDate(deletedDrafts), [deletedDrafts]);

  // Utiliser les listes triées pour le filtrage
  const activeDraftsFiltered = sortedActiveDrafts.filter(matchReportTypeFilter);
  const sentDraftsFiltered = sortedSentDrafts.filter(matchReportTypeFilter);
  const deletedDraftsFiltered = sortedDeletedDrafts.filter(matchReportTypeFilter);

  // ==== Handler pour charger un brouillon et fermer la panel ====
  const handleSelectDraftAndClose = (draft, action) => {
    onSelectDraft(draft, action);
    onClose();
  };

  /**
   *  Vider la section : 
   *  - "active" / "sent": basculer en "deleted".
   *  - "deleted": on les retire complètement.
   */
  const handleClearSection = (section) => {
    // On itère sur la liste correspondante + onDeleteDraft
    if (section === 'active') {
      onDeleteAllDraft(activeDraftsFiltered.map(draft => draft.id));
    }
    else if (section === 'sent') {
      onDeleteAllDraft(sentDraftsFiltered.map(draft => draft.id));
    }
    else if (section === 'deleted') {
      onDeleteAllDraft(deletedDraftsFiltered.map(draft => draft.id));
    }
  };

  return (
    <div style={{ marginTop: '8px' }}>
      {/* Topbar */}
      <div style={{ 
        position: 'sticky', 
        top: -20,
        width: '100%',
        backgroundColor: 'white', 
        zIndex: 1000, 
        padding: '8px 0', 
        borderBottom: '1px solid #ccc' 
      }}>
        {/* Header panel */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3>Brouillons</h3>
          <Button
            icon={FaFilter}
            variant="secondary"
            onClick={() => {
              setShowStatusPicker(!showStatusPicker);
              setReportTypeFilter('all');
              setStatusFilter('all');
            }}
          >
          </Button>
          <div style={{ textAlign: 'right' }}>
            <Button
              icon={FaTimes}
              
              variant="secondary"
              onClick={onClose}
            >
              Fermer
            </Button>
          </div>
        </div>

        <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', gap: '16px' }}>
          {showStatusPicker && (
            <>
              <select
                value={reportTypeFilter}
                onChange={(e) => setReportTypeFilter(e.target.value)}
                style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
              >
                <option value="all">Tous types</option>
                {allReportTypes.map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </select>
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc', maxWidth: 200 }}
              >
                <option value="all">Visible: tous</option>
                <option value="active">Actifs</option>
                <option value="sent">Envoyés</option>
                <option value="deleted">Supprimés</option>
              </select>
            </>
          )}
        </div>

        <p style={{ fontStyle: 'italic', fontSize: '12px' }}>
          Sélectionnez un brouillon à charger (ou seulement le texte / l'annexe).
        </p>
      </div>

      {/* ACTIFS */}
      {(statusFilter === 'all' || statusFilter === 'active') && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Brouillons actifs</h4>
            {activeDraftsFiltered.length > 0 && (
              <Button
                icon={FaTrash}
                variant="danger"
                onClick={() => handleClearSection('active')}
              >
                Vider la section
              </Button>
            )}
          </div>
          {activeDraftsFiltered.length === 0 && <p>Aucun brouillon actif.</p>}
          {activeDraftsFiltered.map((d) => (
            <div
              key={d.id}
              style={{
                border: '1px solid #aaa',
                borderRadius: '4px',
                marginBottom: '8px',
                padding: '8px'
              }}
            >
              <div><strong>{d.reportType}</strong></div>
              <div>
                {(d.text.length > 40 ? d.text.slice(0, 40) + '...' : d.text)}
              </div>
              {d.annex && d.annex.length > 0 && (
                <div>
                  Annexe : 
                  {' ' + (d.annex.length > 40 ? d.annex.slice(0, 40) + '...' : d.annex)}
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '6px' }}>
                <div style={{ display: 'flex', gap: '6px' }}>
                  <ButtonFixed
                    variant="primary"
                    onClick={() => handleSelectDraftAndClose(d, 'replaceAll')}
                  >
                    Reprendre
                  </ButtonFixed>
                 
                  {d.annex && d.annex.length > 0 && (
                     <ButtonFixed
                    
                     variant="info"
                     onClick={() => handleSelectDraftAndClose(d, 'replaceText')}
                   >
                     Texte
                   </ButtonFixed>
                  )}
                  
                  {d.annex && d.annex.length > 0 && (
                    <ButtonFixed
                      variant="reporttype-consultation"
                      onClick={() => handleSelectDraftAndClose(d, 'replaceAnnex')}
                    >
                      Annexe
                    </ButtonFixed>
                  )}
                </div>
                <Button icon={FaTrash} variant="danger" onClick={() => onDeleteDraft(d.id)} />
              </div>
            </div>
          ))}
        </>
      )}

      {/* ENVOYÉS */}
      {(statusFilter === 'all' || statusFilter === 'sent') && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Brouillons envoyés</h4>
            {sentDraftsFiltered.length > 0 && (
              <Button
                icon={FaTrash}
                variant="danger"
                onClick={() => handleClearSection('sent')}
              >
                Vider la section
              </Button>
            )}
          </div>
          {sentDraftsFiltered.length === 0 && <p>Aucun brouillon envoyé.</p>}
          {sentDraftsFiltered.map((d) => (
            <div
              key={d.id}
              style={{
                border: '1px solid #aaa',
                borderRadius: '4px',
                marginBottom: '8px',
                padding: '8px'
              }}
            >
              <div><strong>Date :</strong> {formatDate(d.date)}</div>
              <div><strong>Type :</strong> {d.reportType}</div>
              <div>
                {(d.text.length > 40 ? d.text.slice(0, 40) + '...' : d.text)}
              </div>
              {d.annex && d.annex.length > 0 && (
                <div>
                  Annexe : 
                  {' ' + (d.annex.length > 40 ? d.annex.slice(0, 40) + '...' : d.annex)}
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '6px' }}>
                <div style={{ display: 'flex', gap: '6px' }}>
                  <ButtonFixed
                    variant="primary"
                    onClick={() => handleSelectDraftAndClose(d, 'replaceAll')}
                  >
                    Tout
                  </ButtonFixed>
                
                  {d.annex && d.annex.length > 0 && (
                      <ButtonFixed
                      variant="info"
                      onClick={() => handleSelectDraftAndClose(d, 'replaceText')}
                    >
                      Texte
                    </ButtonFixed>
                  )}
                  {d.annex && d.annex.length > 0 && (
                    <ButtonFixed
                      variant="reporttype-consultation"
                      onClick={() => handleSelectDraftAndClose(d, 'replaceAnnex')}
                    >
                      Annexe
                    </ButtonFixed>
                  )}
                </div>
                <Button icon={FaTrash} variant="danger" onClick={() => onDeleteDraft(d.id)} />
              </div>
            </div>
          ))}
        </>
      )}

      {/* SUPPRIMÉS */}
      {(statusFilter === 'all' || statusFilter === 'deleted') && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Brouillons supprimés</h4>
            {deletedDraftsFiltered.length > 0 && (
              <Button
                icon={FaTrash}
                variant="danger"
                onClick={() => handleClearSection('deleted')}
              >
                Vider la section
              </Button>
            )}
          </div>
          {deletedDraftsFiltered.length === 0 && <p>Aucun brouillon supprimé.</p>}
          {deletedDraftsFiltered.map((d) => (
            <div
              key={d.id}
              style={{
                border: '1px solid #aaa',
                borderRadius: '4px',
                marginBottom: '8px',
                padding: '8px'
              }}
            >
              <div><strong>Date :</strong> {formatDate(d.date)}</div>
              <div><strong>Type :</strong> {d.reportType}</div>
              <div>
                   
                {' ' + (d.text.length > 40 ? d.text.slice(0, 40) + '...' : d.text)}
              </div>
              {d.annex && d.annex.length > 0 && (
                <div>
                  Annexe : 
                  {' ' + (d.annex.length > 40 ? d.annex.slice(0, 40) + '...' : d.annex)}
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '6px' }}>
                <div style={{ display: 'flex', gap: '6px' }}>
                  <ButtonFixed
                    
                    variant="primary"
                    onClick={() => handleSelectDraftAndClose(d, 'replaceAll')}
                  >
                    Tout
                  </ButtonFixed>
                
                  {d.annex && d.annex.length > 0 && (
                      <ButtonFixed
                      variant="info"
                      onClick={() => handleSelectDraftAndClose(d, 'replaceText')}
                    >
                      Texte
                    </ButtonFixed>
                  )}
                  {d.annex && d.annex.length > 0 && (
                    <ButtonFixed
                      variant="reporttype-consultation"
                      onClick={() => handleSelectDraftAndClose(d, 'replaceAnnex')}
                    >
                      Annexe
                    </ButtonFixed>
                  )}
                </div>
                <Button icon={FaTrash} variant="danger" onClick={() => onDeleteDraft(d.id)} />
              </div>
            </div>
          ))}
        </>
      )}
    </  div>
  );
}