import React from 'react';
import './ButtonFixed.css';

function ButtonFixed({
  children,
  icon: Icon,
  variant = 'primary',
  iconSize = 'default',
  fontSize = 'default',
  isSpinning = false,
  ...props
}) {
  const iconClass = `button-icon ${iconSize}`;

  return (
    <button className={`button-fixed button-fixed-${variant} button-fixed-${fontSize}`} {...props}>
      {Icon && <Icon className={`${iconClass} ${isSpinning ? 'spinning' : ''}`} />}
      <span className="button-fixed-text">
        {children}
      </span>
    </button>
  );
}

export default ButtonFixed; 