import React, { useState, useEffect } from 'react';
import './Button.css';

function Button({
  children,
  icon: Icon,
  variant = 'primary',
  // If true, the button hides its text on narrow screens
  collapseOnSmallScreen = true,
  fontSize = 'default',
  // Lower threshold => below this => icon-only
  // Upper threshold => above this => icon + text
  // Between the two => keep previous state
  lowerThreshold = 500,
  upperThreshold = 500,
  isSpinning = false,
  // New prop to set icon size
  iconSize = 'default',
  ...props
}) {
  // We'll start by checking if we’re already below the lower threshold
  // If so, we’re in "small" mode. Otherwise, "large" mode by default.
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < lowerThreshold
  );

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      setIsSmallScreen((prev) => {
        // If we're currently large and we go below the lower threshold => go small
        if (!prev && width < lowerThreshold) {
          return true;
        }
        // If we're currently small and we go above the upper threshold => go large
        if (prev && width > upperThreshold) {
          return false;
        }
        // Otherwise, do not change (in between)
        return prev;
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [lowerThreshold, upperThreshold]);

  // Decide whether to hide text
  const hideText = collapseOnSmallScreen && isSmallScreen;

  // Determine icon class based on size and visibility
  const iconClass = `${hideText ? 'button-icon icon-big' : 'button-icon'} ${iconSize} ${isSpinning ? 'spinning' : ''}`;


  return (
    <button className={`button button-${variant} button-${fontSize}`} {...props}>
      {Icon && <Icon className={iconClass} />}
      {!hideText && children}
    </button>
  );
}

export default Button;
