import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import axios from '../services/api';
import realAxios from 'axios';
import { format, isToday } from 'date-fns';
import './ReportList.css';
import './mobileReportList.css';

// Icons et composants divers
import {
  FaCopy, FaUndo, FaSync, FaBrain,  FaPrint,
  FaArchive, FaEnvelope, FaChevronRight, FaFileWord, FaFileImport, FaShare, FaEllipsisV, FaBroom,
  FaTextHeight,
  FaArrowUp,
  FaArrowDown, 
  FaSpinner, 
  FaTimes, FaSearch
} from 'react-icons/fa';
import Button from '../Helpers/Button';
import ButtonFixed from '../Helpers/ButtonFixed';
import ExportOptions from './ExportOptions';
import Notification from '../Helpers/Notification';
import { exportToWord } from './exportFunctions';
import PopoverBullseye from './Bullseye/EchoPopover';
import { isElectron, isCapacitor } from '../App';
import { Clipboard } from '@capacitor/clipboard';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/* ---------------------------------------------
   1) FONCTIONS : chargement, parse, etc.
--------------------------------------------- */

const loadKeywordsFromLocalStorage = () => {
  const storedKeywords = localStorage.getItem('keywords');
  if (!storedKeywords) {
    const defaultKeywords = [
      'Actuellement','Analyse','Allergie','Anamnèse','Antécédent','Attitude',
      'Comorbidité','Motif','Conclusion','Diagnostic','Discussion',
      'Échographie','Entrée','Exposition','Évaluation','Evolution','Examen',
      'Fonction','Informations','Indication','Interprétation','Imagerie',
      'Médicament','Médication','Objectif','Observation','Plan','Pronostic',
      'Proposition','Prochain patient','Prochain cas','Rapport','Radiologie',
      'Recommandation','Réglages','Remarque','Section','Prochaine section', 'Impressions/Propositions',
      'Statistique','Status','Suivi','Synthèse','Thérapie','Traitement','Deuxième', 'Troisième', 'Quatrième','Motif','Facteurs','Facteur','Comorbidités','Antécédents',
      //Scientifique
      'Abstract','Introduction','Objective','Method','Materials','Study Design',
      'Statistical','Results','Limitations',
      //Allemand
      'Aktuell','Analyse','Allergien','Anamnese','Vorgeschichte','Einstellung',
      'Komorbiditäten','Schlussfolgerung','Diagnose','Diskussion',
      'Ultraschall','Eintritt','Expositionen','Bewertung','Verlauf',
      'Untersuchung','Funktion','Informationen','Indikation','Interpretation',
      'Bildgebung','Frau','Medikament','Medikation','Herr','Hr','Fr','Ziel',
      'Beobachtung','Prognose','Vorschlag','Empfehlung','Einstellungen','Bemerkung',
      'Abschnitt','Nächster Abschnitt','Austritt','Statistik','Nachsorge',
      'Zusammenfassung','Therapie','Behandlung'
    ];
    localStorage.setItem('keywords', defaultKeywords.join(', '));
    return defaultKeywords;
  }
  return storedKeywords.split(',').map(word => word.trim());
};

function parseReportSections(reportText) {
  const keywords = loadKeywordsFromLocalStorage();
  const lines = reportText.split('\n');
  let sections = [];
  let currentSection = { title: '', content: '' };
  let previousLineWasEmpty = true;
  let hasTitle = false;
  let contentBeforeFirstTitle = '';
  let lastLineWasTitle = false;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const trimmedLine = line.trim();
    if (trimmedLine === '') {
      previousLineWasEmpty = true;
      continue;
    }
    let isTitle = false;

    // Détection d'un titre
    if (previousLineWasEmpty && !lastLineWasTitle) {
      for (let keyword of keywords) {
        if (trimmedLine.startsWith(keyword) && trimmedLine.length <= 70) {
          isTitle = true;
        }
        if (isTitle && trimmedLine.includes(':') && !trimmedLine.endsWith(':')) {
          isTitle = false;
        }
        if (isTitle) break;
      }
    }

    if (isTitle) {
      hasTitle = true;
      if (currentSection.title || currentSection.content.trim() !== '') {
        sections.push(currentSection);
      }
      currentSection = { title: trimmedLine, content: '' };
      lastLineWasTitle = true;
    } else {
      lastLineWasTitle = false;
      if (!hasTitle) {
        contentBeforeFirstTitle += line + '\n';
      } else {
        if (previousLineWasEmpty) {
          currentSection.content += '\n' + line + '\n';
        } else {
          currentSection.content += line + '\n';
        }
      }
    }
    previousLineWasEmpty = false;
  }

  if (currentSection.title || currentSection.content.trim() !== '') {
    sections.push(currentSection);
  }
  if (contentBeforeFirstTitle.trim() !== '') {
    sections.unshift({ title: '', content: contentBeforeFirstTitle });
  }
  if (!hasTitle && contentBeforeFirstTitle.trim() === '' && sections.length === 0) {
    sections.push({ title: '', content: reportText });
  }
  return sections;
}


function parseMailSections(reportText) {
  const lines = reportText.split('\n');
  let result = {};
  let emailFound = false;
  let objetFound = false;
  let emailValue = '';
  let objetValue = '';
  let bodyLines = [];
  let previousLineWasEmpty = true;
  let hasBodyStarted = false;

  lines.forEach((line) => {
    const trimmedLine = line.trim();
    const lowerCaseLine = trimmedLine.toLowerCase();
    if (!emailFound && (lowerCaseLine.startsWith('email') || lowerCaseLine.startsWith('mail'))) {
      const emailLine = trimmedLine.split(/[:\s]+/).slice(1).join(' ').trim();
      emailValue = emailLine;
      emailFound = true;
    } else if (!objetFound && lowerCaseLine.startsWith('objet')) {
      const objetLine = trimmedLine.split(/[:\s]+/).slice(1).join(' ').trim();
      objetValue = objetLine;
      objetFound = true;
    } else {
      if (trimmedLine !== '') {
        hasBodyStarted = true;
        bodyLines.push(line + '\n');
        previousLineWasEmpty = false;
      } else if (hasBodyStarted) {
        if (!previousLineWasEmpty) {
          bodyLines.push('\n');
        }
        previousLineWasEmpty = true;
      }
    }
  });

  if (emailFound) {
    result['Email'] = emailValue;
  }
  if (objetFound) {
    result['Objet'] = objetValue;
  }
  let formattedBody = '';
  bodyLines.forEach((ln) => {
    if (ln === '') {
      formattedBody += `\n`;
    } else {
      formattedBody += `${ln}`;
    }
  });
  result['Corps'] = '\n' + formattedBody + '\n';
  return result;
}


/*----------------------------------------
  AUTRES HELPERS : localStorage, etc.
----------------------------------------*/
function saveReportsToLocalStorage(reportsData) {
  localStorage.setItem('reports', JSON.stringify(reportsData));
}
function loadReportsFromLocalStorage() {
  const stored = localStorage.getItem('reports');
  return stored ? JSON.parse(stored) : [];
}
function saveLastRefreshDateToLocalStorage(date) {
  localStorage.setItem('lastRefreshDate', date);
}
function loadLastRefreshDateFromLocalStorage() {
  const stored = localStorage.getItem('lastRefreshDate');
  return stored ? new Date(stored) : null;
}

function getLatestReportDate(reports) {
  if (!reports || !reports.length) return null;
  return reports.reduce((latest, r) => {
    const rd = new Date(r.date);
    return rd > latest ? rd : latest;
  }, new Date(reports[0].date));
}
function getOldestReportDate(reports) {
  if (!reports || !reports.length) return null;
  return reports.reduce((oldest, r) => {
    const rd = new Date(r.date);
    return rd < oldest ? rd : oldest;
  }, new Date(reports[0].date));
}
function mergeReports(existing, incoming) {
  const map = new Map();
  existing.forEach(r => map.set(r.messageID, r));
  incoming.forEach(r => {
    if (!map.has(r.messageID)) map.set(r.messageID, r);
  });
  return Array.from(map.values()).sort((a,b) => new Date(b.date)-new Date(a.date));
}

function capitalizeFirstLetter(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function groupReportsByDay(reports) {
  return reports.reduce((groups, report) => {
    if (!report || !report.date) return groups;
    const rd = new Date(report.date);
    const dateKey = isToday(rd) ? "Aujourd'hui" : format(rd, 'dd/MM/yyyy');
    if (!groups[dateKey]) groups[dateKey] = [];
    groups[dateKey].push(report);
    return groups;
  }, {});
}

function createMailtoLink(email, subject, body) {
  const encodedBody = body.replace(/\r?\n/g, '%0D%0A');
  return `mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent(subject)}&body=${encodedBody}`;
}



/*----------------------------------------
  3) COMPONENT PRINCIPAL : ReportList
----------------------------------------*/
export default function ReportList() {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastReportDate, setLastReportDate] = useState(null);
  const [reportTypes, setReportTypes] = useState([]);
  
  // On stocke le contenu texte (plain text) dans mdReport
  const [mdReport, setMdReport] = useState('');

  // "Insertion" mode
  const [isInsertMode, setIsInsertMode] = useState(false);
  const [sections, setSections] = useState([]);

  //scroll
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const scrollableRef = useRef(null);
  

  const [filteredReports, setFilteredReports] = useState([]);
  const [refreshCountdown, setRefreshCountdown] = useState(0);
  const lastReportDateRef = useRef(lastReportDate);
  const isRefreshingRef = useRef(false);
  const isSendingRef = useRef(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [reportTypeFilter, setReportTypeFilter] = useState('');

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 950);

  const [showExportOptions, setShowExportOptions] = useState(false);
  

  const [ currentInsertIndex, setCurrentInsertIndex] = useState(0);

  const [notification, setNotification] = useState(null);

  const limitPerPage = 50;

  
  const [showExportMenu, setShowExportMenu] = useState(false);
  const [showTextOptions, setShowTextOptions] = useState(false);
  

  const [ showEchoReport, setShowEchoReport] = useState(false);
  const buttonRef = useRef(null);

  // Ajouter un nouveau state
  const [showSearchBar, setShowSearchBar] = useState(false);

  /*----------------------------------------
    FETCH / INIT
  ----------------------------------------*/
  useEffect(() => {
    // 1. Définition des fonctions de localStorage
    const loadReportsFromLocalStorage = () => {
      const stored = localStorage.getItem('reports');
      return stored ? JSON.parse(stored) : [];
    };

    const loadLastRefreshDateFromLocalStorage = () => {
      const stored = localStorage.getItem('lastRefreshDate');
      return stored ? new Date(stored) : null;
    };

    // 2. Définition de getLatestReportDate
    const getLatestReportDate = (reports) => {
      if (!reports || !reports.length) return null;
      return reports.reduce((latest, r) => {
        const rd = new Date(r.date);
        return rd > latest ? rd : latest;
      }, new Date(reports[0].date));
    };

    // 3. Définition de extractReportTypes
    const extractReportTypes = (arr) => {
      const uniqueTypes = Array.from(new Set(arr.map((r) => r.reportType || '')));
      setReportTypes(uniqueTypes);
    };

    // 4. Définition de sendChatId
    const sendChatId = async () => {
      if (window.electronAPI) {
        const chatId = localStorage.getItem('chatId');
        window.electronAPI.storeChatId(chatId);
      }
    };

    // 5. Définition de fetchInitialReports
    const fetchInitialReports = async () => {
      const chatId = localStorage.getItem('chatId');
      if (!chatId) return;
      setLoading(true);
      try {
        const response = await axios.post(`${API_BASE_URL}/api/fetchInitialReports`, {
          chatId,
          limit: limitPerPage,
        });
        if (response.data.length === 0) {
          setHasMore(false);
        } else {
          const data = response.data;
          setReports(data);
          saveReportsToLocalStorage(data);

          const latestDate = getLatestReportDate(data);
          setLastReportDate(latestDate);
          saveLastRefreshDateToLocalStorage(latestDate.toISOString());
          setHasMore(data.length >= limitPerPage);
          extractReportTypes(data);
        }
      } catch (error) {
        console.error('Error fetching initial reports:', error);
      } finally {
        setLoading(false);
      }
    };

    // 6. Exécution du code principal
    const stored = loadReportsFromLocalStorage();
    const storedLastRefresh = loadLastRefreshDateFromLocalStorage();
    sendChatId();
    
    if (stored.length > 0 && storedLastRefresh) {
      setReports(stored);
      setLastReportDate(storedLastRefresh);
    } else {
      fetchInitialReports();
    }
  }, [
    // Dépendances externes qui ne changent pas
    limitPerPage,
    // States setters de React
    setReports,
    setLastReportDate,
    setHasMore,
    setLoading,
    setReportTypes
  ]); // Toutes les dépendances externes sont listées

  const extractReportTypes = useCallback((arr) => {
    const uniqueTypes = Array.from(new Set(arr.map((r) => r.reportType || '')));
    setReportTypes(uniqueTypes);
  }, []);

  const sendChatId = useCallback(async () => {
    if (window.electronAPI) {
      const chatId = localStorage.getItem('chatId');
      // Envoi au main process via IPC
      window.electronAPI.storeChatId(chatId);
    } else {
      return;
    }
  }, []);
  
  const handleRefresh = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    sendChatId();
    if (!chatId) return;
    if (isRefreshingRef.current) {
      console.log('Veuillez patienter avant de rafraîchir à nouveau.');
      return;
    }
    isRefreshingRef.current = true;
    setRefreshCountdown(2);

    let dateToUse = lastReportDateRef.current;
    if (!dateToUse) {
      const stored = loadLastRefreshDateFromLocalStorage();
      if (stored) {
        setLastReportDate(stored);
        dateToUse = stored;
      } else {
        return;
      }
    }
    setLoading(true);

    try {
      const resp = await realAxios.post(`${API_BASE_URL}/api/refreshReports`, {
        chatId,
        lastReportDate: dateToUse,
      });
      if (resp.data.length > 0) {
        const newReports = resp.data;
        const localExisting = loadReportsFromLocalStorage();
        const combined = mergeReports(localExisting, newReports);
        setReports(combined);
        saveReportsToLocalStorage(combined);

        const latestDate = getLatestReportDate(newReports);
        setLastReportDate(latestDate);
        saveLastRefreshDateToLocalStorage(latestDate.toISOString());

        setHasMore(resp.data.length >= limitPerPage);
        setNotification({ message: `${resp.data.length} nouveau(x) rapport(s)`, type: 'success' });

        extractReportTypes(combined);
      } else {
        setNotification({ message: 'Vos rapports sont à jour.', type: 'classic' });
        setHasMore(false);
      }
    } catch (error) {
      console.error('Erreur lors du rafraîchissement des rapports:', error);
    } finally {
      setLoading(false);
    }
  }, [limitPerPage, extractReportTypes, sendChatId]);

  useEffect(() => {
   
      handleRefresh();
   
  }, [handleRefresh]);

  const loadMoreReports = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId || loading || !hasMore) return;
    setLoading(true);
    try {
      const oldest = getOldestReportDate(reports);
      const response = await axios.post(`${API_BASE_URL}/api/loadMoreReports`, {
        chatId,
        lastReportDate: oldest,
        limit: limitPerPage,
      });
      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        const data = response.data;
        const combined = mergeReports(reports, data);
        setReports(combined);
        saveReportsToLocalStorage(combined);
        setHasMore(data.length >= limitPerPage);
      }
    } catch (error) {
      console.error('Error loading more reports:', error);
    } finally {
      setLoading(false);
    }
  }, [hasMore, loading, limitPerPage, reports]);



  /*----------------------------------------
    ÉDITEUR (SIMPLE TEXTAREA)
  ----------------------------------------*/
  const stopInsertMode = useCallback(() => {
    if (window.electronAPI) {
      window.electronAPI.stopInsertMode();
    }
    setIsInsertMode(false);
  }, []);

  const handleEditorChange = useCallback((updatedText) => {
    if (isInsertMode) {
      stopInsertMode();
    }
    setShowExportMenu(false);
    setShowTextOptions(false);
    setMdReport(updatedText);
  }, [isInsertMode,  stopInsertMode]);

  /*----------------------------------------
    BOUTONS DE NETTOYAGE/MODIF DE TEXTE
  ----------------------------------------*/

  // Retire caractères spéciaux / espaces invisibles
  const handleRemoveSpecialChars = useCallback(() => {
    setMdReport((prev) =>
      prev
        // Remove HTML tags
        .replace(/<\/?[^>]+(>|$)/g, '')
        // Replace <br> and <p> with newline
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/?p>/gi, '\n')
        // Remove special characters
        .replace(/[*]/g, '')
        .replace(/[#]/g, '')
        .replace(/[•]/g, '')
        .replace(/[|]/g, '')
        .replace(/[\t ]/g, ' ')
        .replace(/[  ]/g, ' ')
        .replace(/[\u200B-\u200F\u00A0]/g, ' ')
        // Trim + normalize multiple spaces
        .replace(/[ ]{2,}/g, ' ')
        .trim()
    );
  }, []);

  // Double chaque saut de ligne
  const handleDoubleLineBreaks = useCallback(() => {
    setMdReport((prev) =>
      // Remplace chaque saut de ligne simple par deux sauts de ligne
      prev.replace(/\r?\n/g, '\n\n')
    );
  }, []);

  // Divise par 2 les sauts de ligne (remplace doubles \n par un seul \n)
  const handleHalveLineBreaks = useCallback(() => {
    setMdReport((prev) =>
      prev.replace(/\n\s*\n/g, '\n')
    );
  }, []);

  // Amélioration de la détection de "phrase" :
  // => Ajoute un saut de ligne APRES un point/point-virgule, seulement si
  // (a) on a au moins 50 caractères avant ce point ; 
  // (b) le caractère suivant est une majuscule.
  // => Puis ajoute un saut de ligne si la ligne commence par un "keyword".
  const handleSmartLineBreakAndKeywords = useCallback(() => {
    const keywords = loadKeywordsFromLocalStorage();
    
    setMdReport((prev) => {
      let text = prev;
      
      // 1) Ajout d'un saut de ligne après un point/; SI >= 50 caractères devant ET majuscule après
      //    Regex : match un bloc d'au moins 50 caractères, suivi d'un point/; 
      //            ensuite potentiellement des espaces, puis une majuscule
      //    On insère un saut de ligne avant la majuscule (group 4).
      text = text.replace(
        /(.{50,}?)([.;])(\s*)([A-Z])/g,
        '$1$2\n$4'  
      );
      // Explication:
      // (.{50,}?) => au moins 50 caractères (lazy)
      // ([.;])    => capture le point ou le point-virgule
      // (\s*)     => éventuellement des espaces
      // ([A-Z])   => la majuscule qu'on remet sur la ligne suivante

      // 2) Pour chaque ligne, on ajoute un saut de ligne supplémentaire si ça commence par un keyword
      const lines = text.split('\n');
      let outLines = [];
      for (const line of lines) {
        outLines.push(line);
        const trimmed = line.trim();
        for (let kw of keywords) {
          if (trimmed.startsWith(kw)) {
            outLines.push('');
            break;
          }
        }
      }
      return outLines.join('\n');
    });
  }, []);

  const handleSave = useCallback((reportArg) => {
    // 1) On prend la version qu'on a en param si disponible,
    //    sinon on utilise selectedReport
    const repToSave = reportArg || selectedReport;
  
    const updatedReport = {
      ...repToSave,
      finalReport: mdReport,
      bullseyeData: repToSave.bullseyeData || {},
    };
  
    console.log("handleSave => updatedReport =>", updatedReport);
  
    setReports((prev) => {
      const updatedArr = prev.map((r) =>
        r.messageID === updatedReport.messageID ? updatedReport : r
      );
      saveReportsToLocalStorage(updatedArr);
      return updatedArr;
    });
  
    setSelectedReport(updatedReport);
  }, [mdReport, selectedReport]);


  const handleInsertion = useCallback(() => {
    console.log("ReportList => handleInsertion with selectedReport =>", selectedReport);

    if (!isInsertMode){
      if (!selectedReport) return;
      setIsInsertMode(true);
      setNotification({ message: `Cliquez sur les boutons pour copier la section (ou Ctrl + 1...9)`, type: 'insert' });
    } else {
      stopInsertMode();
      return;
    }

    handleSave();
    setCurrentInsertIndex(0);
    const newSecs = parseReportSections(mdReport);
    setSections(newSecs);


    // On referme les autres
    setShowExportMenu(false);
    setShowTextOptions(false);

    if (window.electronAPI) {
      window.electronAPI.setInsertData({
        name: selectedReport.name,
        sections: newSecs
      });
    }

    // On envoie les sections à la fenêtre d'insertion
  }, [selectedReport, mdReport, isInsertMode, stopInsertMode, handleSave, setCurrentInsertIndex]);



  /*----------------------------------------
    SAVE, ARCHIVE, etc.
  ----------------------------------------*/



// handleBullseyeChange
const handleBullseyeChange = useCallback((newData) => {
  // On crée l'objet "à jour"
  const updated = {
    ...selectedReport,
    bullseyeData: newData,
  };
  // On met à jour l'état local
  setSelectedReport(updated);
  // On appelle handleSave() en lui passant updated
  handleSave(updated);

}, [selectedReport, handleSave]);

  const handleReportClick = useCallback((report) => {
    console.log("ReportList => handleReportClick with report =>", report.bullseyeData);
    setShowExportOptions(false);
    setMdReport(report.finalReport || '');
    setSelectedReport(report);
    stopInsertMode();
    setShowEchoReport(false);
    setShowTextOptions(false);
    setSections([]);
  }, [stopInsertMode]);

  const handleReportChange = useCallback((newRep) => {
    if (selectedReport) handleSave();
    handleReportClick(newRep);
  }, [selectedReport, handleSave, handleReportClick]);

  const handleReportItemClick = useCallback((report) => {
    handleReportChange(report);
    if (window.electronAPI) {
      console.log("ReportList => Storing currentReportId =>", report.messageID);
      window.electronAPI.storeCurrentReportId(report.messageID);  

    }
  }, [handleReportChange]);

  const handleArchiveReport = useCallback((messageID) => {
    const updated = reports.filter((r) => r.messageID !== messageID);
    setReports(updated);
    saveReportsToLocalStorage(updated);
  }, [reports]);

  const handleSendToServer = useCallback(async () => {
    if (!selectedReport) return;
    isSendingRef.current = true;
    handleSave(); 
    const chatId = localStorage.getItem('chatId');
    if (!chatId) return;
  
    try {  
      await realAxios.post(`${API_BASE_URL}/updateReport`, {
        chatId,
        correctedVersion: mdReport, 
        messageID: selectedReport.messageID,
      });
  
      setNotification({ message: 'Rapport envoyé avec succès', type: 'success' });
  
      const updatedReport = {
        ...selectedReport,
        isCorrected: true,
      };
      setReports((prevReports) => {
        const newReports = prevReports.map((r) =>
          r.messageID === updatedReport.messageID ? updatedReport : r
        );
        saveReportsToLocalStorage(newReports);
        return newReports;
      });
      setSelectedReport(updatedReport);
  
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du rapport:', error);
      alert('Erreur lors de la sauvegarde du rapport');
    } finally {
      isSendingRef.current = false;
    }
  }, [handleSave, selectedReport, mdReport]);

  const handleUndo = useCallback(() => {
    if (!selectedReport) return;
    setMdReport(selectedReport.finalReport);
    stopInsertMode();
    setSections([]);
  }, [selectedReport, stopInsertMode]);

  const handlePrint = useCallback(() => {
    if (!selectedReport) return;
    const textToPrint = mdReport || '';
    
    // Ouvre une nouvelle fenêtre vide
    const printWindow = window.open('', '_blank');
    
    // Écris le HTML que tu veux imprimer 
    printWindow.document.write(`
      <html>
        <head>
          <title>${capitalizeFirstLetter(selectedReport.name)}</title>
          <style>
            /* Force l'utilisation d'Arial dans la fenêtre d'impression */
            body, pre {
              font-family: Arial, sans-serif;
            }
            pre {
              white-space: pre-wrap;
            }
          </style>
        </head>
        <body>
          <h3>${capitalizeFirstLetter(selectedReport.name)}</h3>
          <pre>${textToPrint}</pre>
        </body>
      </html>
    `);
  
    // Termine l'écriture, puis lance la boîte de dialogue d'impression
    printWindow.document.close();
    printWindow.print();
  }, [mdReport, selectedReport]);
  

  /*----------------------------------------
    COPY (texte brut)
  ----------------------------------------*/
  const copyTextToClipboard = async (text) => {
    if (isCapacitor) {
      await Clipboard.write({
        string: text
      });
      return Promise.resolve();
    }
    return navigator.clipboard.writeText(text);
  };

  const handleCopy = useCallback(() => {
    if (!selectedReport) return;
    copyTextToClipboard(mdReport).then(() => {
      setNotification({
        message: `${capitalizeFirstLetter(selectedReport.reportType)} copié.`,
        type: 'info'
      });
    }).catch(err => console.error('Erreur copie :', err));
  }, [selectedReport, mdReport]);

  const handleCopySection = useCallback((sectionText, key, number) => {
    return copyTextToClipboard(sectionText).then(() => {
      let message = 'Section copiée.';
      if (key) message = `${key} copié(e)`;
      if (number !== undefined) message += ` (raccourci : Ctrl + ${number + 1})`;
      setNotification({ message, type: 'insert' });
    }).catch(err => console.error('Erreur copie section :', err));
  }, []);

  /*----------------------------------------
    Raccourcis clavier (Insertion)
  ----------------------------------------*/
  const handleKeyDown = useCallback((e) => {
    if (!selectedReport) return;

    // En insertion => Ctrl + 1..9 => copies section
    if (isInsertMode && e.ctrlKey && !e.shiftKey) {
      const numberKey = parseInt(e.key, 10);
      if (numberKey >= 1 && numberKey <= sections.length) {
        e.preventDefault();
        const idx = numberKey - 1;
        const sec = sections[idx];
        if (sec) {
          handleCopySection(sec.content, sec.title, idx);
        }
      }
    }

    // Ctrl+Shift combos
    if (e.ctrlKey && e.shiftKey) {
      switch (e.key.toUpperCase()) {

        case 'C':
          e.preventDefault();
          handleCopy();
          break;
        case 'R':
          e.preventDefault();
          handleRefresh();
          break;
        default:
          break;
      }
    }


    if (e.key === 'Insert') {
      e.preventDefault();
      handleInsertion();
    }

  }, [
    isInsertMode,
    sections,
    selectedReport,
    handleInsertion,
    handleCopySection, 
      handleCopy, handleRefresh
    
  ]);

  /*----------------------------------------
    SEARCH & FILTER
  ----------------------------------------*/
  useEffect(() => {
    // Fonction de callback quand on scrolle
    const handleScroll = (e) => {
      if (e.target.scrollTop > 200) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    const scrollEl = scrollableRef.current;
    if (scrollEl) {
      scrollEl.addEventListener('scroll', handleScroll);
    }
    // Nettoyage
    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // Remonte le scroll en haut
  const scrollToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };



  useEffect(() => {
    // Définition de la fonction déclenchée avant la fermeture
    const handleBeforeUnload = (event) => {
      // 1) Sauvegarde synchrone en localStorage
      handleSave();
      stopInsertMode();
    };

    // On écoute l'événement beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Nettoyage lors du démontage
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleSave, stopInsertMode]);


  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (refreshCountdown > 0) {
      const timer = setTimeout(() => setRefreshCountdown(c => c - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      isRefreshingRef.current = false;
    }
  }, [refreshCountdown]);

  useEffect(() => {
    let filtered = reports;
    if (searchQuery) {
      const q = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (r) => r.name.toLowerCase().includes(q) || r.finalReport.toLowerCase().includes(q)
      );
    }
    if (reportTypeFilter) {
      filtered = filtered.filter((r) => r.reportType === reportTypeFilter);
    }
    setFilteredReports(filtered);
  }, [reports, searchQuery, reportTypeFilter]);


  /*----------------------------------------
    UI resizing logic
  ----------------------------------------*/
  useEffect(() => {
    const handleResize = () => {
      const small = window.innerWidth < 950;
      setIsSmallScreen(small);
      if (!small) {
        setIsSidebarVisible(true);
      } else if (!selectedReport) {
        setIsSidebarVisible(true);
      } else {
        setIsSidebarVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [selectedReport]);

  useEffect(() => {
    lastReportDateRef.current = lastReportDate;
  }, [lastReportDate]);


  /*----------------------------------------
    AUTRES UTILS
  ----------------------------------------*/

  const handleSendEmail = useCallback(() => {
    // Déplacer getMailtoLink à l'intérieur
    const getMailtoLink = () => {
      const structuredEmail = parseMailSections(mdReport);
      const email = structuredEmail.Email || '';
      const subject = structuredEmail.Objet || '';
      const body = structuredEmail.Corps || '';
      return createMailtoLink(email, subject, body);
    };

    const mailLink = getMailtoLink();
    if (!mailLink.includes('mailto:') || mailLink === 'mailto:?subject=&body=') {
      setNotification({
        message: 'Impossible de détecter Email: ou Objet: dans ce texte.',
        type: 'warning'
      });
      return;
    }
    window.open(mailLink, '_blank');
  }, [mdReport,   setNotification]);
  


  const handleExportButtonClick = useCallback(() => {
    // Toggle le sous-menu export
    

    setShowTextOptions(false);
    setShowExportOptions(true);
  }, []);

  // Appel direct ou utilisation du composant <ExportOptions>
  const handleExportWord = useCallback((options) => {
    // Exporte réellement
    exportToWord(options);
    // Ferme la fenêtre d'options
    setShowExportOptions(false);
    setNotification({ message: 'Export en Word terminé.', type: 'success' });
  }, []);

  /*----------------------------------------
    Options de texte
  ----------------------------------------*/


  const handleToggleTextOptions = useCallback(() => {
    setShowTextOptions((prev) => !prev);

    setShowExportMenu(false);
  }, []);

  const handleToggleExportMenu = useCallback(() => {
    setShowExportMenu((prev) => !prev);
    setShowTextOptions(false);
  }, []);
/*
useEffect(() => {
  if (!window.electronAPI) return;
  const handleReopenReport = (messageID) => {
    console.log('React got "reopen-report" =>', messageID);
    if (!messageID) return;

    // 2) Temporarily disable transitions
    setDisableTransitions(true);

    // 3) Actually find and open the requested report
    const storedReports = loadReportsFromLocalStorage();
    const matchingReport = storedReports.find(r => r.messageID === messageID);
    if (matchingReport) {
      handleReportItemClick(matchingReport);
    }

    // 4) Re-enable transitions after a short delay (so React can render w/o animation)
    setTimeout(() => {
      setDisableTransitions(false);
    }, 100); // e.g. 100ms
  };

  // Listen for the event from main
  window.electronAPI.onReopenReport(handleReopenReport);

  // Cleanup
  return () => {
    window.electronAPI.offReopenReport(handleReopenReport);
  };
}, [handleReportItemClick]);
*/


  /*----------------------------------------
    RENDER
  ----------------------------------------*/
  const groupedReports = groupReportsByDay(filteredReports);
  const containerClass = isCapacitor
  ? 'mobile-report-list-container'
  : 'report-list-container';

  return (
<div 
    className={containerClass} 
    onKeyDown={handleKeyDown}
  >
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}

      {isSmallScreen && (!isSidebarVisible || !selectedReport) && (
        <div
          className={isCapacitor ? "mobile-sidebar-hover-area" : "sidebar-hover-area"}
          onMouseEnter={() => isSmallScreen && setIsSidebarVisible(true)}
        ></div>
      )}

{isCapacitor && showScrollTopButton && isSidebarVisible && (
<button
            onClick={scrollToTop}
            className={isCapacitor ? "mobile-scroll-to-top-btn" : "scroll-to-top-btn"}
          >
            <FaArrowUp />
          </button>
     )}    
      
      {/* ---- SIDEBAR ---- */}
      <div
        className={`${isCapacitor ? 'mobile-sidebar-container' : 'sidebar-container'} ${isSidebarVisible || !selectedReport ? '' : isCapacitor ? 'mobile-collapsed' : 'collapsed'} ${
          !isElectron ? isCapacitor ? 'mobile-web-mode' : 'web-mode' : ''
        }`}
        onMouseLeave={() => isSmallScreen && setIsSidebarVisible(false)}
      >
        <div className={isCapacitor ? "mobile-sidebar-fixed" : "sidebar-fixed"}>
        <div className={isCapacitor ? "mobile-topbar-container" : "topbar-container"}>
          <div className={isCapacitor ? "mobile-filter-container" : "filter-container"}>
            <div className={isCapacitor ? "mobile-top-row" : "top-row"}>
              <div className={isCapacitor ? "mobile-refresh-button-container" : "refresh-button-container"}>
                <ButtonFixed 
                  icon={  isRefreshingRef.current ? FaSpinner : FaSync} 
                  variant="primary" 
                  collapseOnSmallScreen={false} 
                  onClick={handleRefresh} 
                  fontSize="large"
                  disabled={loading}
                  isSpinning={isRefreshingRef.current}
                >
                  Rafraîchir
                </ButtonFixed>
              </div>

              <Button
                icon={FaSearch}
                variant="secondary"
                onClick={() => setShowSearchBar(!showSearchBar)}
                collapseOnSmallScreen={false}
              />
            </div>

            {showSearchBar && (
              <div className={isCapacitor ? "mobile-search-row" : "search-row"}>
                <div className={isCapacitor ? "mobile-report-type-filter-container" : "report-type-filter-container"}>
                  <select
                    value={reportTypeFilter}
                    onClick={() => extractReportTypes(reports)}
                    onChange={(e) => setReportTypeFilter(e.target.value)}
                  >
                    <option value="">Tous les types</option>
                    {reportTypes.map((type) => (
                      <option key={type} value={type}>
                        {capitalizeFirstLetter(type)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={isCapacitor ? "mobile-search-container" : "search-container"}>
                  <input
                    type="text"
                    placeholder="Rechercher..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {(searchQuery || reportTypeFilter) && (
                    <Button
                      icon={FaTimes}
                      variant="secondary"
                      onClick={() => {
                        setSearchQuery('');
                        setReportTypeFilter('');
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        </div>

        <div className={isCapacitor ? "mobile-report-list-scrollable" : "report-list-scrollable"} ref={scrollableRef}>
          {Object.keys(groupedReports).map((dateKey, idx) => (
            <div key={idx} className={isCapacitor ? "mobile-report-group" : "report-group"}>
              <h3 className={isCapacitor ? "mobile-report-group-title" : "report-group-title"}>{dateKey}</h3>
              <ul>
  {groupedReports[dateKey].map((rep) => (
    <li
      key={rep.messageID}
      className={`${isCapacitor ? 'mobile-report-item' : 'report-item'} ${
        selectedReport && selectedReport.messageID === rep.messageID
          ? isCapacitor ? 'mobile-selected-report' : 'selected-report'
          : ''
      }`}
      onClick={() => handleReportItemClick(rep)}
    >
      <p><strong>{capitalizeFirstLetter(rep.name)}</strong></p>
      <p className={isCapacitor ? "mobile-report-preview" : "report-preview"}>
        {rep.finalReport ? rep.finalReport.slice(0, isCapacitor ? 200 : 80) + '...' : ''}
      </p>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleArchiveReport(rep.messageID);
        }}
        className={isCapacitor ? "mobile-archive-button" : "archive-button"}
      >
        <FaArchive /> Archiver
      </button>
    </li>
  ))}
</ul>
            </div>
          ))}
          {filteredReports.length === 0 && <p>Aucun rapport disponible</p>}
          {loading && <p>Chargement des rapports...</p>}
          {hasMore && (
            <Button variant="secondary" onClick={loadMoreReports}>
              Charger 50 rapports de plus...
            </Button>
          )}
        </div>
        
{!isCapacitor && showScrollTopButton  && (
<button
            onClick={scrollToTop}
            className={"scroll-to-top-btn"}
          >
            <FaArrowUp />
          </button>
     )}    
 
      </div>

      {/* ---- MAIN CONTENT ---- */}
      <div className={`${isCapacitor ? 'mobile-main-content' : 'main-content'} ${isSidebarVisible ? '' : isCapacitor ? 'mobile-full-width mobile-collapsed-sidebar' : 'full-width collapsed-sidebar'}`}>
        {showExportOptions ? (
          <ExportOptions
               // On transmet le contenu du rapport
               reportContent={mdReport}
               // Quand on valide l'export Word
               onExportWord={handleExportWord}
               // Quand on veut fermer l'UI d'options
               onClose={() => setShowExportOptions(false)}
          />
        ) : selectedReport ? (
          <div className={isCapacitor ? "mobile-report-details" : "report-details"}>
            <div className={isCapacitor ? "mobile-sticky-header" : "sticky-header"}>

              {/* Barre de boutons sur une seule ligne */}
              <div className={isCapacitor ? "mobile-buttons-container mobile-single-line-toolbar" : "buttons-container single-line-toolbar"}>
              <Button icon={  isSendingRef.current ? FaSpinner : FaBrain} 
              collapseOnSmallScreen={isCapacitor ? false : true}
              variant={!selectedReport.isCorrected ? 'success' : 'secondary'} 
              onClick={handleSendToServer} lowerThreshold={460} upperThreshold={460}
              isSpinning={isSendingRef.current}
              >
         {selectedReport.isCorrected ? 'IA déjà améliorée' : 'Améliorer votre IA'}
        </Button>

        {isCapacitor ? (
          <Button icon={FaCopy} variant="info" onClick={handleCopy} lowerThreshold={500} upperThreshold={500} >
            Copier
          </Button>
        ) : selectedReport.reportType !== 'mail' && (
          <Button icon={FaFileImport} variant="insert" onClick={handleInsertion} lowerThreshold={520} upperThreshold={520}>
            Insérer
          </Button>
        )}

        {!isCapacitor && (
                <Button icon={FaCopy} variant="info" onClick={handleCopy} lowerThreshold={565} upperThreshold={565} >
                Copier
              </Button>
        )}

        {selectedReport.reportType === 'mail' || selectedReport.reportType === 'convocation' ? (
          <Button icon={FaEnvelope} variant="lavender" onClick={handleSendEmail} lowerThreshold={600} upperThreshold={600}>
            Envoyer par e-mail
          </Button>
        ) : !isCapacitor && (
          <Button icon={FaShare} variant="word" onClick={handleToggleExportMenu} lowerThreshold={620} upperThreshold={620}>
            Exporter
          </Button>
        )}
        

{selectedReport.reportType === 'echocardiographie' && (
  <PopoverBullseye
    btnRef={buttonRef}
    // Ajout important :
    bullseyeData={selectedReport.bullseyeData}
    // On lui passe le callback du parent :
    onBullseyeChange={handleBullseyeChange}
  />
)}

  {isCapacitor ? (
    <Button icon={FaUndo} variant="warning" onClick={handleUndo}></Button>
  ) : (
    <Button icon={FaEllipsisV} variant="print" onClick={handleToggleTextOptions}></Button>
  )}

                  
              </div>
              </div>
           
              {isInsertMode && sections.length > 0 && (
                <div className="subtoolbar-container">
                  <div className="handlecopysection-scrollable">
                    
                    <div className="handlecopysection-buttons">
         
                      {sections.map((section, index) => (
                        <Button icon={FaCopy} variant="insert" collapseOnSmallScreen={false}
                          key={index}
                          onClick={() =>
                            handleCopySection(
                              section.content,
                              section.title || `Section ${index+1}`,
                              index
                            ).then(() =>  (section.content))
                          }
                        >
                          <div className="button-header">
                           <span className="section-index">{index+1}</span>
                          </div>
                          <div className="section-title">
                            {section.title ? section.title.slice(0, 30) : `Section ${index+1}`}
                          </div>
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            

              {/* ---------- Sous-menu Options de texte ---------- */}
              {showTextOptions && (
              <div className={isCapacitor ? "mobile-subtoolbar-container" : "subtoolbar-container"}>
                <Button  icon={FaBroom} variant="print" onClick={handleRemoveSpecialChars} lowerThreshold={370} upperThreshold={370}>
                  Nettoyer
                </Button>
                <Button icon={FaArrowUp} variant="print" onClick={handleDoubleLineBreaks} lowerThreshold={430} upperThreshold={430}>
                  Doubler ↲
                </Button>
                <Button  icon={FaArrowDown} variant="print" onClick={handleHalveLineBreaks} lowerThreshold={500} upperThreshold={500}>
                  Réduire ↲
                </Button>
                <Button  icon={FaTextHeight} variant="print" onClick={handleSmartLineBreakAndKeywords} lowerThreshold={560} upperThreshold={560}>
                  Ajouter ↲
                </Button>
                <Button  icon={FaUndo} variant="warning" onClick={handleUndo} lowerThreshold={630} upperThreshold={630}>
                  Annuler
                </Button>
              </div>
            )}

                  {/* ---------- Sous-menu Options de texte ---------- */}
                  {showExportMenu && (
              <div className={isCapacitor ? "mobile-subtoolbar-container" : "subtoolbar-container"}>
              <ButtonFixed icon={FaFileWord} variant="word" onClick={handleExportButtonClick}>
                Exporter en Word
              </ButtonFixed>
              <ButtonFixed icon={FaPrint} variant="word" onClick={handlePrint}>
                Imprimer
              </ButtonFixed>
              </div>
              
            )}
            
            {/* Zone de texte (scrollable) */}
            <div className={isCapacitor ? "mobile-editor-scrollable" : "editor-scrollable"}>
              <textarea
                className={isCapacitor ? "mobile-simple-text-editor" : "simple-text-editor"}
                value={mdReport}
                onChange={(e) => handleEditorChange(e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div className={isCapacitor ? "mobile-report-details-placeholder" : "report-details-placeholder"}>
           
          </div>
        )}

        {!isSidebarVisible && (
          <div
            className={isCapacitor ? "mobile-collapsed-sidebar-indicator" : "collapsed-sidebar-indicator"}
            onMouseEnter={() => isSmallScreen && setIsSidebarVisible(true)}
          >
            <FaChevronRight className={isCapacitor ? "mobile-arrow-icon" : "arrow-icon"} />
          </div>
        )}
      </div>
    </div>
  );
}