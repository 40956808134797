// App.js
import React, { useEffect, useState } from 'react';
import {
  HashRouter as Router,  // Switch from BrowserRouter to HashRouter
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import Login from './Login/Login';
import ReportList from './ReportList/ReportList';
import Recorder from './Dictaphone/liveRecorder.js';
import Settings from './Settings/Settings';
import Download from './Download/download';
import Uploader from './Electron/uploader.js';
import Guide from './Guide/guide';
import Header from './Helpers/Header';
import Translator from './Translator/translator';
import Home from './Home/home.js';
import './global.css';
import { LoadingProvider } from './context/LoadingContext';
import { Capacitor } from '@capacitor/core';


export const isElectron =  typeof window !== 'undefined' && window.isElectron;
export const isCapacitor = Capacitor.isNativePlatform();
export const isNative = isElectron || isCapacitor;


function App() {
  return (
    <LoadingProvider>
      <Router>
        <AppContent />
      </Router>
    </LoadingProvider>
  );
}

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // État de chargement
  
  

  useEffect(() => {
    const chatId = localStorage.getItem('chatId');
    setIsAuthenticated(!!chatId);
    setIsLoading(false); // Fin du chargement après vérification
  }, []);  // no dependencies => only runs once at mount
  
  
useEffect(() => {
    if (!isElectron) return;

    // 1) define the callback that calls handleLogout
    function onRequestLogout() {
      console.log("React got 'request-logout' => calling handleLogout()");
      handleLogout();
    }

    // 2) subscribe to "request-logout" 
    window.electronAPI.on('request-logout', onRequestLogout);

    // 3) cleanup on unmount
    return () => {
      window.electronAPI.off('request-logout', onRequestLogout);
    };
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  
    // Informer le processus principal que l'utilisateur est authentifié
    if (window.electronAPI) {
      const chatId = localStorage.getItem('chatId');
      window.electronAPI.storeChatId(chatId);
      console.log("App.js => handleLoginSuccess => userAuthenticated =>", chatId);
      window.electronAPI.userAuthenticated();
    }
  };
  
  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  
    // Informer le processus principal que l'utilisateur s'est déconnecté
    if (isElectron) {
      window.electronAPI.userLoggedOut();
    }
  };  
  
  if (isLoading) {
    return <div>Loading...</div>; // Afficher un indicateur de chargement
  }

  return (
    <div className={`app ${isElectron ? 'electron-mode' : isCapacitor ? 'capacitor-mode' : 'web-mode'}`}>
      {/* Affichez l'en-tête seulement en mode Web */}
      {!isElectron && isAuthenticated && <Header handleLogout={handleLogout} />}
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/reports" /> : <Login onLoginSuccess={handleLoginSuccess} />}
        />
       <Route
  path="/reports"
  element={
    isAuthenticated ? <ReportList /> : <Navigate to="/login" state={{ from: '/reports' }} />}
/>
            <Route 
            path="/recorder"
            element={isAuthenticated ? <Recorder /> : <Navigate to="/login" state={{ from: '/recorder' }} />}
          />
          <Route
            path="/translator"
            element={isAuthenticated ? <Translator /> : <Navigate to="/login" state={{ from: '/translator' }} />}
          />
        <Route
          path="/settings"
          element={isAuthenticated ? <Settings  /> : <Navigate to="/login" state={{ from: '/settings' }} />}
        />
        <Route
          path="/download"
          element={isAuthenticated ? <Download /> : <Navigate to="/login" state={{ from: '/download' }} />}
        />
           <Route
          path="/home"
          element={isAuthenticated ? <Home handleLogout={handleLogout} /> : <Navigate to="/login" state={{ from: '/home' }}  />}
        />
        <Route
          path="/guide"
          element={<Guide /> }
        />
         <Route
          path="/uploader"
          element={isAuthenticated ? <Uploader /> : <Navigate to="/login" state={{ from: '/uploader' }} />}
        />
        
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}


export default App;