import React, { useState, useEffect } from 'react';
import axios from '../services/api';
import './home.css';

// Styles et composants réutilisables
import Card from '../styles/card';
import Icon from '../styles/icon';
import Button from '../Helpers/Button';

// Icônes
import { 
  FaUser,
  FaCogs,
  FaQuestionCircle,
  FaGlobe,
  FaFileContract,
  FaShieldAlt,
  FaSignOutAlt,
  FaLanguage
} from 'react-icons/fa';

import { NavLink } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Home({ handleLogout }) {
  const [username, setUsername] = useState('Guest');

  // Exemple d’effet pour récupérer le nom de l'utilisateur
  useEffect(() => {
    const storedName = localStorage.getItem('name');
    if (storedName) {
      setUsername(storedName);
      return;
    }

    const fetchUserInfo = async () => {
      try {
        const chatId = localStorage.getItem('chatId');
        if (!chatId) return;
        const response = await axios.post(`${API_BASE_URL}/getSettings`, { chatId });
        if (response.data.name) {
          localStorage.setItem('name', response.data.name);
          setUsername(response.data.name);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du compte:', error);
      }
    };
    fetchUserInfo();
  }, []);

  function handleDecoClick(){
    handleLogout();
  }

 

  return (
    <div className="home-container">
      
      {/* Section Compte (inspirée de Settings) */}
      <Card>
        <div className="home-section-title">
          <Icon icon={FaUser} size={24} title="Compte" />
        </div>
        <div className="form-group">
          <label className="form-label">
            Utilisateur : {username}
          </label>
        </div>
      </Card>

      {/* Section Liens */}
      <Card>
        
        <div className="home-links">
          {/* Lien vers la page de réglages */}
          <NavLink 
            to="/settings"
            className="home-link-button"
          >
            <Icon icon={FaCogs} size={20} title="Réglages" />
            
          </NavLink>

          {/* Lien vers la page de guide */}
          <NavLink
            to="/guide" 
            className="home-link-button"
          >
            <Icon icon={FaQuestionCircle} size={20} title="Guide" />
            
          </NavLink>
          </div>
          </Card>
          <Card>
        
        <div className="home-links">
          {/* Lien vers la page de translator */}
          <NavLink
            to="/translator"
            className="home-link-button"
          >
            <Icon icon={FaLanguage} size={20} title="Traducteur" />
            
          </NavLink>
          </div>
          </Card>


       
   

      {/* Bouton de déconnexion */}
      <Card>
        
        <div className="home-section-logout">
               {/* Lien externe vers www.gbm-medical.ch */}
          <a 
            className="home-link-button" 
            href="https://www.gbm-medical.ch" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <Icon icon={FaGlobe} size={20} title="A propos" />
          </a>

          <a
            className="home-link-button"
            href="https://www.gbm-medical.ch/securite"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={FaShieldAlt} size={20} title="Sécurité des données" />
          </a>

          <a
            className="home-link-button"
            href="https://www.gbm-medical.ch/conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={FaFileContract} size={20} title="Conditions d'utilisations" />
          </a>
          <Button 
            variant="danger" 
            onClick={handleDecoClick}
            icon={FaSignOutAlt}
            collapseOnSmallScreen={false}
          >
            
            <span style={{ color: '#fff' }}>Se déconnecter</span>
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Home;