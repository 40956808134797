import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { OpenAI } from 'openai';
import { v4 as uuidv4 } from 'uuid';
import { doOCRWithGoogleVision } from './googleVisionOCR'; 
import mammoth from 'mammoth';

import {
  FaPaperPlane,
  FaTrash,
  FaCamera,
  FaFile,
  FaFileAlt,
  FaSpinner,
  FaTimes,
  FaMicrophone,
  FaPause,
  FaPlay,
  FaCommentDots
} from 'react-icons/fa';

import './liveRecorder.css';
import './liveRecorderMobile.css';
import { getButtonClass } from './reportType.js';

import Button from '../Helpers/Button';
import ButtonRecord from '../Helpers/ButtonRecord';
import UsageCircleView from '../Helpers/useCircle.js';
import Card from '../styles/card';
import ReportTypeSelector from './reportType.js';
import DraftsPanel from './DraftsPanel';
import AnnexPanel from './AnnexPannel.js';
import Notification from '../Helpers/Notification';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useUniversalRecorder } from './useUniversalRecorder';

import { isElectron, isCapacitor } from '../App';

// ================== CONFIGS ==================
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


// ================== MOTS À SUPPRIMER (nettoyage transcript) ==================
const wordsToClean = [
  "Sous-titres réalisés para la communauté d'Amara.org",
  "Sous-titres réalisés par la communauté d'Amara.org",
  "sous-titrage ST'501",
  "Sous-titrage ST'501",
  "Sous-titrage Société Radio-Canada",
  "Sous-titres par derivatives Madlip",
  "Société Radio-Canada",
  "www.cdc.gc.ca",
  "Réalisé par Neo0352",
  "Merci à mes tipeurs et souscripteurs",
  "Réalisé par Neo035",
  "Merci d'avoir regardé cette vidéo",
  "Merci à mes Tipeurs Tipeee et Zeph0r",
  "amara.org",
  "Amara",
  "Sous-titrage",
  "ST'501",
  "ST' 501",
  "Merci !",
  "Merci.",
  "Merci d'avoir regardé.",
  "d'avoir regardé cette vidéo",
  "regardé cette vidéo",
  "Merci à tous",
  "!",
  "❤️ par SousTitreur.com",
  "❤️ par",
  "❤️",
  "SousTitreur.com",
];

function cleanTranscript(originalText) {
  let cleaned = originalText;
  for (const phrase of wordsToClean) {
    const safePhrase = phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(safePhrase, 'gi');
    cleaned = cleaned.replace(regex, '');
  }
  return cleaned.trim();
}

function isOlderThanOneDay(timestamp) {
  return (Date.now() - timestamp) > 24 * 60 * 60 * 1000;
}

// ================== DRAFTS LOGIC ==================


function createDraftObject({ reportType, text, annex, status }) {
  return {
    id: uuidv4(),
    date: Date.now(),
    reportType: reportType || 'Dictée',
    text: text || '',
    annex: annex || '',
    status: status || 'active'
  };
}

function loadDraftsFromStorage() {
  try {
    const stored = localStorage.getItem('drafts');
    if (!stored) return [];
    return JSON.parse(stored).reverse();
  } catch (err) {
    console.error('loadDraftsFromStorage =>', err);
    return [];
  }
}

function saveDraftsToStorage(drafts) {
  try {
    localStorage.setItem('drafts', JSON.stringify(drafts));
  } catch (err) {
    console.error('saveDraftsToStorage =>', err);
  }
}

// ================== LE COMPOSANT PRINCIPAL ==================
export default function RecorderQueueWithAnnex() {
  // ================== ÉTATS GLOBAUX ==================
  const [notification, setNotification] = useState(null);
  const [chatId, setChatId] = useState(() => localStorage.getItem('chatId') || '');
  const [langue] = useState(() => localStorage.getItem('langue_iso') || 'fr');
  const [isProMode] = useState(() => localStorage.getItem('isProMode') === 'true');
  const [isTranscribeOff] = useState(() => localStorage.getItem('isTranscribeOff') === 'true');
  

  // ======= Pour la sélection du type de rapport =======
  const [reportTypes, setReportTypes] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState('');
  const [showReportTypePanel, setShowReportTypePanel] = useState(false);
  const [hasUserChosenReportType, setHasUserChosenReportType] = useState(false);
  const [highlightReportType, setHighlightReportType] = useState(false);

  // ======= Pour l'enregistrement local / dictée =======
 // const [isRecording, setIsRecording] = useState(false);
  const [isFakeRecording, setisFakeRecording] = useState(false);
  const [isFakePaused, setisFakePaused] = useState(false);


  // ======= Pour le bouton envoyer =======
  const [isSendDisable, setIsSendDisable] = useState(false);
  const [isSending, setIsSending] = useState(false);


  //USAGE
  const [isGold] = useState(true);
  const [usageLeft] = useState(20);
  const [usageTotal] = useState(20);

  // Le "queue" local pour stocker les chunks audio avant transcription
  const [queue, setQueue] = useState([]);
  const queueRef = useRef(queue);
  queueRef.current = queue;
  const processingRef = useRef(false);

  // ======= Pour le texte principal + annexes =======
  const [transcript, setTranscript] = useState('');
  const [annexText, setAnnexText] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  

  // Annexes (overlay)
  const [annexOpen, setAnnexOpen] = useState(false);
  const fileInputRef = useRef(null);

  // Auto-détection du type de rapport
  const [isDeterminingType, setIsDeterminingType] = useState(false);
  const [didAutoDetect, setDidAutoDetect] = useState(false);

  // Brouillons (drafts)
  const [drafts, setDrafts] = useState(() => loadDraftsFromStorage());
  const [currentDraftId, setCurrentDraftId] = useState(null);
  const [draftPanelOpen, setDraftPanelOpen] = useState(false);

  // Contrôle connectivité
  const [isOnline, setIsOnline] = useState(navigator.onLine);



  const [isExtractingText, setIsExtractingText] = useState(false);

  const {
    isRecording,
    isPaused,
    setIsRecording,
    startUniversalRecording,
    stopUniversalRecording,
    pauseUniversalRecording,
    resumeUniversalRecording,
    resetUniversalRecorder
  } = useUniversalRecorder();

  // ================== EFFETS INITIAUX ==================
  

  useEffect(() => {
    const storedId = localStorage.getItem('chatId');
    if (storedId) setChatId(storedId);
  }, []);


  useEffect(() => {
    function handleOnline() { setIsOnline(true); }
    function handleOffline() { setIsOnline(false); }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  
  const fetchReportTypes = useCallback(async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/askReportType`, { chatId });
      let parsed = [];
      if (
        Array.isArray(response.data) &&
        response.data.length > 0 &&
        response.data[0].reportTypes
      ) {
        parsed = response.data[0].reportTypes.split(',').map((t) => t.trim());
      }
      const typed = parsed.map((name) => ({ name, favorite: false }));
      setReportTypes(typed);
      localStorage.setItem('reportTypes', JSON.stringify(typed));
      localStorage.setItem('reportTypesLastFetch', String(Date.now()));
    } catch (err) {
      console.error('Erreur fetchReportTypes:', err.message);
      setReportTypes([]);
    }
  }, [chatId]);

  useEffect(() => {
    const lastFetchStr = localStorage.getItem('reportTypesLastFetch');
    const storedReportTypes = JSON.parse(localStorage.getItem('reportTypes') || '[]');
    if (!storedReportTypes.length || !lastFetchStr || isOlderThanOneDay(Number(lastFetchStr))) {
      fetchReportTypes();
    } else {
      setReportTypes(storedReportTypes);
    }
  }, [fetchReportTypes]);


  const determineReportType = useCallback(async () => {
    if (didAutoDetect || hasUserChosenReportType) {return;}
    const combinedLength = (transcript + annexText).trim().length;
    if (combinedLength < 30 ) {return; }
    if (isDeterminingType) {return; }
      try {
        setIsDeterminingType(true);
        const resp = await axios.post(`${API_BASE_URL}/api/determineReportType`, {
        chatId,
        fullResponse: transcript + annexText,
      });
      const suggestedType = (resp.data?.reportType || '').toLowerCase();
      if (suggestedType === 'inconnu' || suggestedType === 'not found') {
        setHighlightReportType(true);
      } else if (suggestedType) {
        setDidAutoDetect(true);
        setSelectedReportType(suggestedType);
        setHighlightReportType(false);
      }
    } catch (err) {
      console.error('Auto-detect error =>', err);
      setDidAutoDetect(false);
    } finally {
      setIsDeterminingType(false);
    }
    }, [
    chatId,
    transcript,
    annexText,
    didAutoDetect, 
    setDidAutoDetect,
    hasUserChosenReportType,
    isDeterminingType, 
    setIsDeterminingType,
    setHighlightReportType,
    setSelectedReportType
    ]);

  // Auto-détection du type si le user n'a pas encore choisi et qu'on a déjà un certain texte
  useEffect(() => {
    determineReportType();
  }, [    determineReportType  ]);



  // ================== REPORT TYPE: Favoris / Sélection ==================
  const handleToggleFavorite = (typeName) => {
    const updated = reportTypes.map((rt) => {
      if (rt.name === typeName) {
        return { ...rt, favorite: !rt.favorite };
      }
      return rt;
    });
    setReportTypes(updated);
  };

  const handleSelectReportType = (typeName) => {
    setSelectedReportType(typeName);
    setHasUserChosenReportType(true);
    setHighlightReportType(false);
    setShowReportTypePanel(false);
  };

  // ================== ENREGISTREMENT (dictée) ==================

  const triggerRecord = async () => {
if (isTranscribeOff) {
  if (isRecording) {
    if (isPaused) {
      resumeUniversalRecording();
    } else {
      pauseUniversalRecording();
    }
  } else {
    startUniversalRecording();
  }
  handleUiRecording();
  return;
}

if (isRecording) {
  const finalAudio = await stopUniversalRecording();
  setIsRecording(false);

    setQueue((prev) => [...prev, finalAudio]);
    if (!processingRef.current) {
      processQueue([...queueRef.current, finalAudio]);
    }
  
} else {
  startUniversalRecording();
}

handleUiRecording();
};

  function handleUiRecording() {
    // Si on n'enregistre pas, on démarre
    if (!isRecording) {
      setIsSendDisable(true);
      setisFakeRecording(true);
      setisFakePaused(false);
    }
    // Si on est en pause, on reprend
    else if (isFakePaused) {
      setIsSendDisable(true);
      setisFakeRecording(true);
      setisFakePaused(false);
    }
    // Si on enregistre, on met en pause
    else {
      setisFakeRecording(true);
      setisFakePaused(true);
    }
  }




  // Process la file d'attente pour faire la transcription
  async function processQueue(forcedQueue) {
    if (processingRef.current) return;
    processingRef.current = true;
    setIsTranscribing(true);

    try {
      let localQueue = forcedQueue || [...queueRef.current];
      while (localQueue.length > 0) {
        const first = localQueue[0];
        await transcribePartialAudio(first);
        localQueue = localQueue.slice(1);
        setQueue(localQueue);
        queueRef.current = localQueue;
      }
    } finally {
      processingRef.current = false;
      setIsTranscribing(false);
    }
  }


  async function transcribePartialAudio(audioBlob) {
    let timeoutId;
    const slowResponseWarningTime = 20000; // 20 sec

    try {
      setIsTranscribing(true);
      // Après 20 sec, on affiche un avertissement
      timeoutId = setTimeout(() => {
        setNotification({ message: '⚠️ La connexion semble lente.', type: 'warning' });
      }, slowResponseWarningTime);

      const openai = new OpenAI({ apiKey: OPENAI_API_KEY, dangerouslyAllowBrowser: true });

      // On crée un File depuis le Blob, le plugin openai.audio.transcriptions.create() en a besoin
      const fileData = new File([audioBlob], 'partial.webm', { type: audioBlob.type });

      const response = await openai.audio.transcriptions.create({
        file: fileData,
        model: !isCapacitor ? 'gpt-4o-transcribe' : 'whisper-1',
        prompt: transcript + '...', // prompt éventuel
        language: langue,
      });

      clearTimeout(timeoutId);
      const partialText = cleanTranscript(response.text) || '';

      setTranscript((prev) => {
        const newText = prev + partialText + ' ';
        determineReportType();
        return newText;
      });
    } catch (err) {
      clearTimeout(timeoutId);
      console.error('transcribePartialAudio =>', err);
    } finally {
      setIsTranscribing(false);
    }
  }

  // ================== ENVOI DU RAPPORT ==================
  const handleTranscribe = async () => {
    const finalAudio = await stopUniversalRecording();
  
      setQueue((prev) => [...prev, finalAudio]);
      if (!processingRef.current) {
        processQueue([...queueRef.current, finalAudio]);
      }
    setIsSendDisable(false);
  }

  const handleSend = async () => {
    if (!isOnline) {
      alert('Pas de connexion internet. Veuillez vérifier votre connexion et réessayer.');
      return;
    }
    if (isSending) return;
    if (isRecording) {
      alert('Enregistrement encore en cours. Veuillez arrêter la dictée avant.');
      return;
    }
    // Vérifier si tout est transcrit
    if (queueRef.current.length > 0) {
      alert('Transcription encore en cours. Veuillez patienter.');
      return;
    }
    if (!chatId) {
      alert('Vous devez être connecté pour envoyer ce rapport.');
      return;
    }

    let reportType = selectedReportType;
    if (!isProMode && !isElectron) {
    if (!reportType) {
      alert('Veuillez sélectionner un type de rapport ou saisir du texte.');
      return;
    }
  } else {
    if (!reportType) {
     reportType = 'texte';
     setSelectedReportType(reportType);
    }
    console.log('reportType =>', reportType);
  }

    const finalText = transcript.trim();
    const finalAnnex = annexText.trim();
    if (!finalText && !finalAnnex) return;

    try {
      setIsSending(true);
      setIsTranscribing(true);

      // Envoi via l'API existante (mode "reportType")
      const resp = await axios.post(`${API_BASE_URL}/sendWhisperTranscription`, {
        chatId,
        reportType: reportType,
        whisperTranscription: finalText,
        annex: finalAnnex,
      });
      moveCurrentDraftToSent(finalText, finalAnnex);

      if (resp) {
        setNotification({ message: 'Rapport envoyé avec succès !', type: 'success' });
        handleClearAll();
      } else {
        setNotification({
          message: "Erreur lors de l'envoi du rapport. Réessayez.",
          type: 'danger',
        });
      }
    } catch (err) {
      console.error('Send error =>', err);
      setNotification({
        message: `Erreur dans l'envoi du rapport : ${err.message}`,
        type: 'danger',
      });
    } finally {
      setIsSending(false);
      setIsTranscribing(false);
    }
  };

  function moveCurrentDraftToSent(text, annex) {
    if (!currentDraftId) return;
    const updated = drafts.map((d) => {
      if (d.id === currentDraftId && d.status === 'active') {
        return { ...d, status: 'sent', text, annex };
      }
      return d;
    });
    setDrafts(updated);
    saveDraftsToStorage(updated);
    setCurrentDraftId(null);
  }

  // ================== GESTION DES ANNEXES (fichiers/texte) ==================
  const handleToggleAnnex = () => setAnnexOpen((prev) => !prev);
  const handleClearAnnex = () => setAnnexText('');
  const handlePasteAnnex = async () => {
    try {
      const clip = await navigator.clipboard.readText();
      if (!annexText.trim()) {
        setAnnexText(`Prochain annexe:\n${clip}\n`);
      } else {
        setAnnexText((prev) => `${prev}\nProchain annexe:\n${clip}\n`);
      }
    } catch (err) {
      console.error(err);
    }
  };



async function handleTakePhoto() {
  if (isCapacitor) {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
      });
      const base64Data = image.base64String;

      // Décoder la base64 pour créer un vrai fichier binaire
      const raw = atob(base64Data);  
      const rawLength = raw.length;
      const arr = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; i++) {
        arr[i] = raw.charCodeAt(i);
      }

      const file = new File([arr], 'photo.jpg', { type: 'image/jpeg' });
      onFileChange({ target: { files: [file] } });
    } catch (err) {
      console.error('handleTakePhoto =>', err);
      alert(`Erreur prise de photo : ${err.message}`);
    }
  } else {
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  }
}

  const handleSelectFromLibrary = async () => {
    if (isCapacitor) {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          resultType: CameraResultType.Base64,
          source: CameraSource.Photos,
        });
        const base64Data = image.base64String;
        const file = new File([base64Data], "photo_galerie.jpg", { type: "image/jpeg" });
        onFileChange({ target: { files: [file] } });
      } catch (err) {
        console.error('handleSelectFromLibrary =>', err);
        alert(`Erreur sélection galerie : ${err.message}`);
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
  };


  const onFileChange = useCallback(async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    const file = files[0];
    if (!file) return;
    setIsExtractingText(true);
    handleToggleAnnex();
    try {
      const { type, name } = file;

      // 1) PDF => direct to Google Vision with mimeType = 'application/pdf'
      if (type === 'application/pdf') {
        const reader = new FileReader();
        reader.onload = async function (loadEvent) {
          const base64DataUrl = loadEvent.target.result;
          const base64Only = base64DataUrl.split(',')[1];
          if (!base64Only) return;

          try {
            // Pass mimeType for PDF
            const ocrText = await doOCRWithGoogleVision(base64Only, GOOGLE_API_KEY, 'application/pdf');
            if (ocrText) {
              setAnnexText((prev) => prev + `\nProchain annexe:\n${ocrText}\n`);
              setNotification({ message: 'Texte extrait avec succès !', type: 'extract' });
              setIsExtractingText(false);
            } else {
              console.log('Aucun texte reconnu (PDF).');
              setNotification({ message: 'Aucun texte reconnu.', type: 'warning' });
              setIsExtractingText(false);
            }
          } catch (err) {
            console.error('Erreur Vision =>', err);
          }
        };
        reader.readAsDataURL(file);
        return;
      }

      // 2) doc/docx => parse with mammoth
      if (
        type === 'application/msword' ||
        type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        try {
          const arrayBuffer = await file.arrayBuffer();
          const result = await mammoth.extractRawText({ arrayBuffer });
          const extractedText = (result.value || '').trim();
          if (extractedText) {
            setAnnexText((prev) => prev + `\nProchain annexe:\n${extractedText}\n`);
            setNotification({ message: 'Texte extrait avec succès !', type: 'extract' });
            setIsExtractingText(false);
          } else {
            console.log('No doc text found => fallback OCR...');
            setNotification({ message: 'Aucun texte reconnu.', type: 'warning' });
            setIsExtractingText(false);
          }
        } catch (err) {
          console.error('Doc parse error =>', err);
        }
        // Fallback OCR below
      }

      // 3) If text => readAsText
      else if (type === 'text/plain' || /\.txt$/i.test(name)) {
        const reader = new FileReader();
        reader.onload = function (loadEvent) {
          const fileText = loadEvent.target.result;
          if (fileText && fileText.trim()) {
            setAnnexText((prev) => prev + `\nProchain annexe:\n${fileText}\n`);
            setNotification({ message: 'Texte extrait avec succès !', type: 'extract' });
            setIsExtractingText(false);
          } else {
            console.log('Empty text file => no text extracted.');
            setNotification({ message: 'Aucun texte reconnu.', type: 'warning' });
            setIsExtractingText(false);
          }
        };
        reader.readAsText(file);
        return;
      }

      // 4) Everything else => OCR with "image/png"
      const reader = new FileReader();
      reader.onload = async function (loadEvent) {
        const base64DataUrl = loadEvent.target.result;
        const base64Only = base64DataUrl.split(',')[1];
        if (!base64Only) return;

        try {
          // Pass 'image/png' or similar for images
          const ocrText = await doOCRWithGoogleVision(base64Only, GOOGLE_API_KEY, 'image/jpeg');
          if (ocrText) {
            setAnnexText((prev) => prev + `\nProchain annexe:\n${ocrText}\n`);
            setNotification({ message: 'Texte extrait avec succès !', type: 'extract' });
            setIsExtractingText(false);
          } else {
            console.log('Aucun texte reconnu (fallback).');
            setNotification({ message: 'Aucun texte reconnu.', type: 'warning' });
            setIsExtractingText(false);
          }
        } catch (err) {
          console.error('Erreur Vision fallback =>', err);
          setNotification({ message: 'Erreur lors de l\'extraction du texte.', type: 'warning' });
          setIsExtractingText(false);
        }
      };
      reader.readAsDataURL(file);
    } catch (err) {
      console.error('Erreur lecture fichier =>', err);
      setNotification({ message: 'Erreur lors de l\'extraction du texte.', type: 'warning' });
      setIsExtractingText(false);
    } finally {
      e.target.value = '';
      
    }
  }, [ setIsExtractingText]);



  // ================== GESTION DES BROUILLONS ==================
  const handleToggleDrafts = () => setDraftPanelOpen((prev) => !prev);


  
    const autoSaveOrUpdateDraft = useCallback(() => {
      const combined = (transcript + annexText).trim();
      if (!combined) return;
  
      // Mise à jour si on a un draft courant
      if (currentDraftId) {
        const idx = drafts.findIndex((d) => d.id === currentDraftId);
        if (idx !== -1) {
          const draft = drafts[idx];
          if (draft.status === 'active') {
            const updatedDraft = {
              ...draft,
              text: transcript,
              annex: annexText,
              reportType: selectedReportType || 'Dictée',
              date: Date.now(),
            };
            const newDrafts = [...drafts];
            newDrafts[idx] = updatedDraft;
            setDrafts(newDrafts);
            saveDraftsToStorage(newDrafts);
            return;
          }
        }
      }
  
      // Sinon on crée un nouveau brouillon
      const newDraft = createDraftObject({
        reportType: selectedReportType || 'Dictée',
        text: transcript,
        annex: annexText,
        status: 'active',
      });
      const updated = [...drafts, newDraft];
      setDrafts(updated);
      saveDraftsToStorage(updated);
      setCurrentDraftId(newDraft.id);
    }, [drafts, currentDraftId, selectedReportType, transcript, annexText]);

    // Sauvegarde auto si on a plus de 0 caractères
    useEffect(() => {
      const combined = (transcript + annexText).trim();
      if (combined.length > 0) {
        autoSaveOrUpdateDraft();
      }
    }, [transcript, annexText, autoSaveOrUpdateDraft]);

  const handleSelectDraft = useCallback(
    (draft, actionMode = 'replaceAll') => {
      // On sauve d'abord le brouillon courant si besoin
      autoSaveOrUpdateDraft();

      if (actionMode === 'replaceAll') {
        setTranscript(draft.text);
        setAnnexText(draft.annex);
        setSelectedReportType(draft.reportType === 'Dictée' ? '' : draft.reportType);
        setHasUserChosenReportType(true);
      } else if (actionMode === 'replaceText') {
        setTranscript((prev) => prev + draft.text);
      } else if (actionMode === 'replaceAnnex') {
        setAnnexText((prev) => prev + draft.annex);
      }
      setCurrentDraftId(draft.id);
    },
    [ autoSaveOrUpdateDraft]
  );

  useEffect(() => {
    if (!window.electronAPI) return;
    const handleReopenDraft = (draftId) => {
      console.log('React got "reopen-draft" =>', draftId);
      if (!draftId) return;
  
      // 3) Actually find and open the requested draft
      const storedDrafts = loadDraftsFromStorage();
      const matchingDraft = storedDrafts.find(d => d.id === draftId);
      if (matchingDraft) {
        handleSelectDraft(matchingDraft);
      }
    };
  
    // Listen for the event from main
    window.electronAPI.onReopenDraft(handleReopenDraft);
  
    // Cleanup
    return () => {
      window.electronAPI.offReopenDraft(handleReopenDraft);
    };
  }, [handleSelectDraft]);

  function handleDeleteDraft(draftId) {
    const updated = drafts.filter((d) => d.id !== draftId);
    setDrafts(updated);
    saveDraftsToStorage(updated);
    if (draftId === currentDraftId) setCurrentDraftId(null);
  }

  function handleDeleteAllDrafts(draftIds) {
    const updated = drafts.filter((d) => !draftIds.includes(d.id));
    setDrafts(updated);
    saveDraftsToStorage(updated);
    if (draftIds.includes(currentDraftId)) setCurrentDraftId(null);
  }

  function handleRestoreDeleted(draftId) {
    const updated = drafts.map((d) => {
      if (d.id === draftId) {
        return { ...d, status: 'active' };
      }
      return d;
    });
    setDrafts(updated);
    saveDraftsToStorage(updated);
  }

  const activeDrafts = drafts.filter((d) => d.status === 'active');

  // ================== EFFACER ==================
  const handleClearAll = () => {
    resetUniversalRecorder();
    setTranscript('');
    setAnnexText('');
    setQueue([]);
    setIsRecording(false);
    setisFakeRecording(false);
    setisFakePaused(false);
    setSelectedReportType('');
    setHasUserChosenReportType(false);
    setHighlightReportType(false);
    setDidAutoDetect(false);
    setCurrentDraftId(null);
  };

  // Nouvelle fonction pour sauvegarder comme brouillon actif
  const handleSaveAsDraft = () => {
    autoSaveOrUpdateDraft();
    handleClearInterface();
  };



  // Nouvelle fonction pour sauvegarder comme brouillon supprimé
  const handleDelete = () => {
    if (currentDraftId) {
      const updated = drafts.map((d) => {
        if (d.id === currentDraftId) {
          return { ...d, status: 'deleted' };
        }
        return d;
      });
      setDrafts(updated);
      saveDraftsToStorage(updated);
    }
    handleClearInterface();
  };

  // Fonction pour nettoyer l'interface sans logique de sauvegarde
  const handleClearInterface = () => {
    setTranscript('');
    setAnnexText('');
    setQueue([]);
    setIsRecording(false);
    setisFakeRecording(false);
    setisFakePaused(false);
    setSelectedReportType('');
    setHasUserChosenReportType(false);
    setHighlightReportType(false);
    setDidAutoDetect(false);
    setCurrentDraftId(null);
  };



  function formatDate(ts) {
    return new Date(ts).toLocaleString();
  }
  
  /*const containerClass = isCapacitor
  ? 'mobile-dictaphone-container'
  : 'dictaphone-container';*/

  const containerClass =  'mobile-dictaphone-container';
  
  return (
    <div className={containerClass}>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}

      {/* Top bar : Type de rapport, brouillons, etc. */}
      <div className="dictaphone-top-bar">
        <div className="dictaphone-top-bar-left">
          <Button
            icon={isDeterminingType ? FaSpinner : FaFileAlt}
            isSpinning={isDeterminingType}
            variant={
              selectedReportType
                ? `button ${getButtonClass(selectedReportType)}`
                : 'info'
            }
            fontSize="large"
            onClick={() => setShowReportTypePanel(!showReportTypePanel)}
            collapseOnSmallScreen={false}
          >
            {selectedReportType ? (
              <span style={{ textTransform: 'capitalize' }}>
                {selectedReportType}
              </span>
            ) : (
              'Types de rapport'
            )}
          </Button>
        </div>

      
        {/* Indicateur connexion/transcription */}
        <div>
          {!isOnline ? (
            <div className={`${isCapacitor ? 'mobile-warning-bar' : 'warning-bar'} danger`}>
              ⚠️ Aucune connexion
              <br />
              Mode avion activé.
            </div>
          ) : isTranscribing ? (
            <div className={`${isCapacitor ? 'mobile-warning-bar' : 'warning-bar'} ${isSending ? 'vert' : 'primary'}`}>
              <FaSpinner
                className={`dictaphone-spinner ${
                  isSending ? 'spinner-vert' : 'spinner-rouge'
                }`}
              />
              {!isProMode && (
                <span>
                  {isSending ? ' Envoi en cours...' : ' Transcription en cours...'}
                </span>
              )}
            </div>
          ) : isExtractingText ? (
            <div className={`${isCapacitor ? 'mobile-warning-bar' : 'warning-bar'} bleu`}>
              <FaSpinner className="spinner-bleu" />
              {!isProMode && (
              <span>
                {'Extraction du texte...'}
              </span>
              )}
            </div>
          ) : null}
        </div>

        {/* Indicateur d'usage */}
        <UsageCircleView
          isGold={isGold}
          usageLeft={usageLeft}
          usageTotal={usageTotal}
        />

        {/* Brouillons */}
        <div>
          <Button
            icon={FaFile}
            variant={activeDrafts.length > 0 ? 'yellow' : 'secondary'}
            onClick={handleToggleDrafts}
            collapseOnSmallScreen={false}
            fontSize="large"
          >
            {draftPanelOpen ? 'Fermer' : 'Brouillons'}
          </Button>
        </div>
      </div>

      {/* Zone texte dicté */}
      <Card className="dictaphone-transcript-card">
        <textarea
          className="dictaphone-textarea"
          value={transcript}
          onChange={(e) => setTranscript(e.target.value)}
          placeholder="La dictée provisoire apparait ici..."
        />
      </Card>

      <div className="dictaphone-bottom-controls">
        {/* Indicateur d'enregistrement (barres animées) */}
        <div
          className={`recording-indicator-waves ${
            isFakePaused ? 'paused' : isFakeRecording ? 'active' : 'inactive'
          }`}
        >
          <div className="recording-dot"></div>
          {[...Array(30)].map((_, i) => (
            <div className="wave-bar" key={i}></div>
          ))}
        </div>

        {/* Boutons du bas : Supprimer, Annexe, Envoyer/Transcrire */}

        <div className="dictaphone-row">

<div className="dictaphone-first-row">
          <ButtonRecord
            icon={FaCamera}
            variant={
              annexText.trim() ? 'reporttype-mail' : 'reporttype-consultation'
            }
            onClick={handleToggleAnnex}
          >
          </ButtonRecord>
</div>
          <div className="dictaphone-second-row">
          <ButtonRecord
            icon={FaTrash}
            variant="danger"
            onClick={handleDelete}
            
          >
            
          </ButtonRecord>
          

          {isTranscribeOff && !isCapacitor && isSendDisable ? (
            <ButtonRecord
              
              icon={FaCommentDots}
              variant="cyan"
              disabled={!isRecording}
              onClick={handleTranscribe}

            >
              
            </ButtonRecord>
          ) : (
            <ButtonRecord
              icon={FaPaperPlane}
              variant="success"
              onClick={handleSend}
              disabled={!transcript.trim() && !annexText.trim()}
              
 
            >
              
            </ButtonRecord>
          )}
</div>
 
          <div className="dictaphone-third-row">
              {/* Nouveau bouton Sauver comme brouillon */}
  <ButtonRecord
    icon={FaFile}
    variant={isProMode ? "warning" : "placeholder"}
    onClick={handleSaveAsDraft}
    disabled={!currentDraftId && isProMode}
  >
  </ButtonRecord>
          </div>

        </div>

        {/* Bouton dictée (micro) */}
        <div className="dictaphone-center-row">
          <button
            className="record-button"
            onClick={triggerRecord}
          >
            {isFakeRecording ? (
              isFakePaused ? (
                <>
                  <FaPlay /> 
                </>
              ) : (
                <>
                  <FaPause /> 
                </>
              )
            ) : (
              <>
                <FaMicrophone /> 
              </>
            )}
          </button>
        </div>
      </div>

      {/* Overlay type de rapport */}
      {showReportTypePanel && (
        <div className="dictaphone-overlay">
          <Card className="dictaphone-overlay-card">
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <h4>Types de rapport</h4>
              <Button icon={FaTimes} variant="secondary" onClick={() => setShowReportTypePanel(false)}>
                Fermer
              </Button>
            </div>
            <ReportTypeSelector
              reportTypes={reportTypes}
              onSelect={handleSelectReportType}
              onToggleFavorite={handleToggleFavorite}
            />
          </Card>
        </div>
      )}

      {/* Overlay brouillons */}
      {draftPanelOpen && (
        <div className="dictaphone-overlay">
          <Card className="dictaphone-overlay-card">
            <DraftsPanel
              activeDrafts={drafts.filter((d) => d.status === 'active')}
              sentDrafts={drafts.filter((d) => d.status === 'sent')}
              deletedDrafts={drafts.filter((d) => d.status === 'deleted')}
              onSelectDraft={handleSelectDraft}
              onDeleteDraft={handleDeleteDraft}
              onDeleteAllDraft={handleDeleteAllDrafts}
              onRestoreDeleted={handleRestoreDeleted}
              onClose={() => setDraftPanelOpen(false)}
              formatDate={formatDate}
            />
          </Card>
        </div>
      )}

      {/* Overlay annexes */}
      {annexOpen && (
        <div className="dictaphone-overlay">
          <div className="dictaphone-overlay-card-annex">
            <AnnexPanel
              annexText={annexText}
              setAnnexText={setAnnexText}
              onPaste={handlePasteAnnex}
              onClearAnnex={handleClearAnnex}
              onTakePhoto={handleTakePhoto}
              onSelectFromLibrary={handleSelectFromLibrary}
              fileInputRef={fileInputRef}
              onFileChange={onFileChange}
              onClose={() => setAnnexOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}