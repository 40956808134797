import { useState, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { FilesystemDirectory } from '@capacitor/filesystem';
import {
  Microphone
} from '@mozartec/capacitor-microphone';
import {  isCapacitor } from '../App';

export function useUniversalRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  // Références pour l'enregistrement Web
  const mediaRecorderRef = useRef(null);
  const mediaChunksRef = useRef([]);


  /**
   * Lance l'enregistrement selon la plateforme
   */
  async function startUniversalRecording() {
    // S'il y avait déjà un enregistrement, on reset d'abord
    await resetUniversalRecorder();

    if (isCapacitor) {
      await startCapacitorRecording();
    } else {
      await startWebRecording();
    }
    setIsRecording(true);
    setIsPaused(false);
  }

  /**
   * Arrête l'enregistrement et renvoie un Blob audio (webm ou m4a selon la plateforme)
   */
    async function stopUniversalRecording() {
    setIsRecording(false);
    setIsPaused(false);

    if (isCapacitor) {
      return stopCapacitorRecording();
    } else {
      return stopWebRecording();
    }
  }

  /**
   * Met en pause l'enregistrement (uniquement Web).
   * Sur Capacitor, cette fonctionnalité n'est pas officiellement supportée.
   */
  function pauseUniversalRecording() {
    if (isCapacitor) {
      console.warn('Pause non supportée sur Capacitor Microphone. Ignoré.');
      return;
    }

    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause();
      setIsPaused(true);
      console.log('Web recording paused.');
    }
  }

  /**
   * Reprend l'enregistrement (uniquement Web).
   * Sur Capacitor, cette fonctionnalité n'est pas officiellement supportée.
   */
  function resumeUniversalRecording() {
    if (isCapacitor) {
      console.warn('Resume non supporté sur Capacitor Microphone. Ignoré.');
      return;
    }

    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder && mediaRecorder.state === 'paused') {
      mediaRecorder.resume();
      setIsPaused(false);
      console.log('Web recording resumed.');
    }
  }

  /**
   * Réinitialise tout : arrête un enregistrement éventuel, vide les références.
   * A appeler si on veut repartir de zéro.
   */
  async function resetUniversalRecorder() {
    // Si on est en train d'enregistrer, on arrête
    if (isRecording) {
      await stopUniversalRecording();
    }

    // Nettoyage côté Web
    if (mediaRecorderRef.current) {
      try {
        if (mediaRecorderRef.current.state !== 'inactive') {
          mediaRecorderRef.current.stop();
        }
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      } catch (err) {
        console.warn('Erreur lors du reset web recorder:', err);
      }
      mediaRecorderRef.current = null;
    }
    mediaChunksRef.current = [];

    // (Pas grand-chose à nettoyer côté Capacitor,
    // le stopRecording fait déjà le nécessaire)

    setIsRecording(false);
    setIsPaused(false);
    console.log('Universal recorder reset.');
  }

  /**
   * Enregistrement sur Capacitor (M4A via @mozartec/capacitor-microphone)
   */
  async function startCapacitorRecording() {
    try {
      // Vérifie/demande la permission micro
      const perms = await Microphone.requestPermissions();
      console.log('Permissions micro:', perms);

      // Sur Android => perms.recordAudio: 'granted' | 'denied'
      // Sur iOS => perms.microphone: 'granted' | 'denied'
      if (
        (perms.recordAudio && perms.recordAudio !== 'granted') ||
        (perms.microphone && perms.microphone !== 'granted')
      ) {
        alert('Permission micro refusée. Impossible d’enregistrer (Capacitor).');
        return;
      }

      const options = {
        android: {
          audioSource: 1,         // MediaRecorder.AudioSource.MIC
          outputFormat: 2,        // MediaRecorder.OutputFormat.MPEG_4
          audioEncoder: 3,        // MediaRecorder.AudioEncoder.AAC
          sampleRate: 44100,
          bitRate: 128000,
          fileName: 'universalRecording.m4a',
          directory: FilesystemDirectory.Data,
        },
        ios: {
          format: 'm4a',
          fileName: 'universalRecording.m4a',
          directory: FilesystemDirectory.Data,
          sampleRate: 44100,
          bitRate: 128000,
          channels: 2,
        },
      };

      await Microphone.startRecording(options);
      console.log('Capacitor recording started...');
    } catch (err) {
      console.error('startCapacitorRecording error:', err);
    }
  }

  async function stopCapacitorRecording() {
    try {
      const result = await Microphone.stopRecording();
      console.log('Capacitor recording stopped. Result:', result);

      if (result.path) {
        // On a un chemin natif (ex: file:///data/user/0/<package>/files/universalRecording.m4a)
        // Convertissons-le en URL accessible par fetch
        const fileUrl = Capacitor.convertFileSrc(result.path);
        const audioResponse = await fetch(fileUrl);
        const audioBlob = await audioResponse.blob();

        // Notre Blob est typiquement "audio/mp4" ou "audio/m4a"
        console.log('Capacitor audio Blob:', audioBlob);
        return audioBlob;
      } else {
        console.warn('Aucun path renvoyé par stopRecording (Capacitor).');
        return new Blob();
      }
    } catch (err) {
      console.error('stopCapacitorRecording error:', err);
      return new Blob();
    }
  }

  /**
   * Enregistrement web (MediaRecorder + getUserMedia)
   * => format audio/webm (Opus) par défaut
   */
  async function startWebRecording() {
    // Vérifier la compatibilité
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Enregistrement Web non supporté par ce navigateur.');
      return;
    }

    // Demande de permission d'accès au micro
    try {
      const permission = await navigator.permissions.query({ name: 'microphone' });
      if (permission.state === 'denied') {
        alert('L\'accès au microphone a été refusé. Veuillez autoriser l\'accès dans les paramètres de votre navigateur.');
        return;
      }
    } catch (err) {
      console.warn('La vérification des permissions du micro a échoué:', err);
      // On continue car certains navigateurs ne supportent pas l'API permissions
    }

    
  // Lister les périphériques audio et alerte si pas 
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const hasMicrophone = devices.some((d) => d.kind === 'audioinput');

    if (!hasMicrophone) {
      // Afficher une notification / alerte
      alert('Aucun microphone physique détecté. Veuillez brancher ou activer un micro.');
      return;
    }
  } catch (err) {
    console.warn('Impossible de lister les périphériques:', err);
    alert('Aucun microphone physique détecté. Veuillez brancher ou activer un micro.');
  }

    try {
      // Demande accès au micro
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Crée un MediaRecorder
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      mediaChunksRef.current = [];

      // On accumule les "chunks" dans un tableau
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          mediaChunksRef.current.push(event.data);
        }
      };

      // On démarre
      mediaRecorder.start();
      console.log('Web recording started (MediaRecorder)...');
    } catch (err) {
      console.error('startWebRecording error:', err);
      alert('Impossible de démarrer l’enregistrement web : ' + err);
    }
  }

  async function stopWebRecording() {
    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      console.warn('Aucun mediaRecorder en cours (web).');
      return new Blob();
    }

    return new Promise((resolve) => {
      // Lorsqu'on arrête, on attend l’événement "stop" pour combiner les chunks
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(mediaChunksRef.current, { type: 'audio/webm' });
        console.log('Web recording stopped, Blob:', audioBlob);

        // Libère la ressource micro
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());

        // Nettoyage
        mediaRecorderRef.current = null;
        mediaChunksRef.current = [];

        resolve(audioBlob);
      };

      mediaRecorder.stop();
    });
  }

  return {
    isRecording,
    isPaused,
    setIsRecording,
    startUniversalRecording,
    stopUniversalRecording,
    pauseUniversalRecording,
    resumeUniversalRecording,
    resetUniversalRecorder,
  };
}