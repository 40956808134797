// src/UI/Card.js

import React from 'react';
import './card.css';

function Card({ 
  children, 
  className = '', 
  style = {}, 
  backgroundColor, 
  ...props 
}) {
  // Merge any inline styles with our custom background color variable
  const mergedStyle = { ...style };
  if (backgroundColor) {
    mergedStyle['--cardBg'] = backgroundColor;
  }

  return (
    <div 
      className={`card ${className}`} 
      style={mergedStyle} 
      {...props}
    >
      {children}
    </div>
  );
}

export default Card;
