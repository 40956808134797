// GuideUtilisationPage.js

import React, { useState } from 'react';
import './guide.css';

// Import icons from react-icons
import {
  FaMicrophone,
  FaFileAlt,
  FaGlobe,
  FaClipboardList,
  FaLanguage,
  FaCamera,
  FaPlane,
  FaShieldAlt
} from 'react-icons/fa';

// Import your reusable Card
import Card from '../styles/card.js';

// Import your reusable Icon component
import Icon from '../styles/icon.js';

function GuideUtilisationPage() {
  const [showLanguages, setShowLanguages] = useState(false);

  const toggleLanguages = () => {
    setShowLanguages((prev) => !prev);
  };

  return (
    <div className="guide-page">
      {/* En-tête */}
      <header className="guide-header">
        <h1>Comment ça marche&nbsp;?</h1>
        <p>Clarity sauve votre temps. Votre temps sauve des vies.</p>
      </header>

      {/* Étapes : 1, 2, 3 */}
      <section className="guide-steps">

        {/* Étape 1 */}
        <Card className="guide-step">
          <div className="step-header">
            {/* Replaces <FaMicrophone size={24}/> */}
            <Icon
              icon={FaMicrophone}
              size={24}
              title="1. Dictez"
              /* optional custom background/color if you want a circle, e.g.:
              backgroundColor="#960018"
              color="#fff"
              */
            />
            
          </div>
          <p>
            Dictez votre <strong>consultation</strong>, <strong>note de suite</strong>,
            <strong> lettre</strong>, etc. comme à votre secrétaire, sans ponctuation ni mise en page.
            Clarity s’occupe de tout.
          </p>
        </Card>

        {/* Étape 2 */}
        <Card className="guide-step">
          <div className="step-header">
            {/* Replaces <FaFileAlt size={24}/> */}
            <Icon icon={FaFileAlt} size={24} title="2. Recevez" />

          </div>
          <p>
            Votre rapport est mis en forme <strong>en quelques secondes</strong>. Copiez <strong>tout le rapport</strong> ou <strong>section par section</strong> (anamnèse, status, etc.) depuis
            notre site dans votre logiciel médical, ou partagez-le en PDF, Word ou par e-mail.
          </p>
        </Card>

        {/* Étape 3 */}
        <Card className="guide-step">
          <div className="step-header">
            {/* Replaces <FaGlobe size={24}/> */}
            <Icon icon={FaGlobe} size={24} title="3. Améliorez" />

          </div>
          <p>
            Corrigez et complétez vos rapports pour <strong>former Clarity</strong> à vos habitudes.
            Plus vous l’utilisez, plus Clarity devient précis.
          </p>
        </Card>
      </section>

      {/* Bouton d'appel à l'action */}
      <div className="cta-container">
        <a
          className="cta-button"
          href="https://t.me/ClarityGBM_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Essayez Clarity gratuitement
        </a>
      </div>

      {/* Types de rapport */}
      <Card className="report-types">
        <div className="section-header">
          {/* Replaces <FaClipboardList size={24}/> */}
          <Icon icon={FaClipboardList} size={24} title="Types de rapport" />
          
        </div>
        <p>
          Clarity permet de <strong>générer plusieurs types de rapports médicaux</strong> selon des
          schémas pré-conçus : consultations, lettre, note de suite, e-mail, rapports pour assurances,
          etc.
        </p>
        <p>
          Selon le type de rapport choisi, il existe une structure pré-établie, que vous pouvez
          personnaliser. Les sections-clés (anamnèse, status, etc.) sont identifiées automatiquement
          pour des rapports clairs et structurés.
        </p>
      </Card>

      {/* AddScan */}
      <Card className="report-types">
        <div className="section-header">
          {/* Replaces <FaCamera size={24}/> */}
          <Icon icon={FaCamera} size={24} title="AddScan" />
          
        </div>
        <p>
          Clarity propose la fonction <strong>AddScan</strong>, pour prendre des photos de documents
          (listes de médicaments, etc.) et les <strong>intégrer directement</strong> dans votre rapport.
        </p>
        <p>
          Par exemple, pour un patient transféré : prenez en photo la lettre de sortie, Clarity
          <strong> analyse et traduit</strong> automatiquement les diagnostics et traitements, et les
          incorpore à votre nouveau rapport.
        </p>
      </Card>

      {/* Traduction */}
      <Card className="languages">
        <div className="section-header">
          {/* Replaces <FaLanguage size={24}/> */}
          <Icon icon={FaLanguage} size={24} title="Traduction" />
          
        </div>
        <p>
          Clarity vous permet de dicter et recevoir vos rapports dans <strong>plus de 50 langues</strong>,
          y compris les <strong>dialectes suisses-allemands</strong>. Parlez en français et obtenez le
          texte en allemand, ou l’inverse.
        </p>
        <p>
          Clarity peut aussi enregistrer la voix d’un patient ne parlant pas votre langue,
          et <strong>traduire en direct</strong> l’anamnèse recueillie.
        </p>
        {/* Voir/Cacher liste complète */}
        <button className="toggle-button" onClick={toggleLanguages}>
          {showLanguages ? 'Cacher la liste complète' : 'Voir la liste complète'}
        </button>
        {showLanguages && (
          <div className="language-grid">
            <span>🇿🇦 Afrikaans</span>
            <span>🇩🇪 Allemand</span>
            <span>🇬🇧 Anglais</span>
            <span>🇸🇦 Arabe</span>
            <span>🇦🇲 Arménien</span>
            <span>🇦🇿 Azéri</span>
            <span>🇧🇾 Biélorusse</span>
            <span>🇧🇦 Bosniaque</span>
            <span>🇧🇬 Bulgare</span>
            <span>🇪🇸 Catalan</span>
            <span>🇨🇳 Chinois</span>
            <span>🇰🇷 Coréen</span>
            <span>🇭🇷 Croate</span>
            <span>🇩🇰 Danois</span>
            <span>🇪🇸 Espagnol</span>
            <span>🇪🇪 Estonien</span>
            <span>🇫🇮 Finnois</span>
            <span>🇫🇷 Français</span>
            <span>🇪🇸 Galicien</span>
            <span>🏴 Gallois</span>
            <span>🇬🇷 Grec</span>
            <span>🇮🇳 Hindi</span>
            <span>🇭🇺 Hongrois</span>
            <span>🇮🇱 Hébreu</span>
            <span>🇮🇩 Indonésien</span>
            <span>🇮🇸 Islandais</span>
            <span>🇮🇹 Italien</span>
            <span>🇯🇵 Japonais</span>
            <span>🇮🇳 Kannada</span>
            <span>🇰🇿 Kazakh</span>
            <span>🇱🇻 Letton</span>
            <span>🇱🇹 Lituanien</span>
            <span>🇲🇰 Macédonien</span>
            <span>🇲🇾 Malais</span>
            <span>🇳🇿 Maori</span>
            <span>🇮🇳 Marathi</span>
            <span>🇳🇴 Norvégien</span>
            <span>🇳🇱 Néerlandais</span>
            <span>🇳🇵 Népalais</span>
            <span>🇵🇰 Ourdou</span>
            <span>🇮🇷 Persan</span>
            <span>🇵🇱 Polonais</span>
            <span>🇵🇹 Portugais</span>
            <span>🇷🇴 Roumain</span>
            <span>🇷🇺 Russe</span>
            <span>🇷🇸 Serbe</span>
            <span>🇸🇰 Slovaque</span>
            <span>🇸🇮 Slovène</span>
            <span>🇸🇪 Suédois</span>
            <span>🇰🇪 Swahili</span>
            <span>🇵🇭 Tagalog</span>
            <span>🇮🇳 Tamoul</span>
            <span>🇨🇿 Tchèque</span>
            <span>🇹🇭 Thaï</span>
            <span>🇹🇷 Turc</span>
            <span>🇺🇦 Ukrainien</span>
            <span>🇻🇳 Vietnamien</span>
          </div>
        )}
      </Card>

      {/* Mode avion */}
      <Card className="report-types">
        <div className="section-header">
          {/* Replaces <FaPlane size={24}/> */}
          <Icon icon={FaPlane} size={24} title="Mode avion" />
          
        </div>
        <p>
          En cas de connexion internet instable, Clarity enregistre votre dictée hors-ligne. Dès que la connexion
          est rétablie, il génère le rapport. Vous pouvez ainsi <strong>dicter où que vous soyez</strong>.
        </p>
      </Card>

      {/* Sécurité des données */}
      <Card className="report-types">
        <div className="section-header">
          {/* Replaces <FaShieldAlt size={24}/> */}
          <Icon icon={FaShieldAlt} size={24}  title="Sécurité des données" />
          
        </div>
        <p>
          Clarity s’appuie sur des <strong>IA spécialisées</strong> dans la rédaction de rapports médicaux,
          hébergées sur des serveurs suisses sécurisés. Vos données restent <strong>confidentielles</strong>.
        </p>
      </Card>
    </div>
  );
}

export default GuideUtilisationPage;
