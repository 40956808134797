import axios from 'axios';

// Créez une instance axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

// Compteur de requêtes en cours
let requestCount = 0;

// Fonction pour mettre à jour le loader
function updateLoader() {
  console.log(`Mise à jour du loader: ${requestCount} requêtes en cours`);
  const event = new CustomEvent('loading', {
    detail: { isLoading: requestCount > 0 }
  });
  window.dispatchEvent(event);
}

// Fonction pour styliser les logs
function logWithStyle(message, type = 'info') {
  if (process.env.NODE_ENV === 'development') {
    const styles = {
      info: 'color: #960018; font-weight: bold',
      error: 'color: #ff0000; font-weight: bold',
      success: 'color: #00967e; font-weight: bold'
    };

    console.log(`%c API: ${message}`, styles[type]);
  }
}

// Intercepteur de requête
api.interceptors.request.use(
  (config) => {
    requestCount++;
    logWithStyle(`Requête envoyée vers ${config.url}. Requêtes actives: ${requestCount}`, 'info');
    updateLoader();
    return config;
  },
  (error) => {
    requestCount--;
    logWithStyle(`Erreur de requête: ${error.message}. Requêtes actives: ${requestCount}`, 'error');
    updateLoader();
    return Promise.reject(error);
  }
);

// Intercepteur de réponse
api.interceptors.response.use(
  (response) => {
    requestCount--;
    logWithStyle(`Réponse reçue de ${response.config.url}. Requêtes actives: ${requestCount}`, 'success');
    updateLoader();
    return response;
  },
  (error) => {
    requestCount--;
    logWithStyle(`Erreur de réponse: ${error.message}. Requêtes actives: ${requestCount}`, 'error');
    updateLoader();
    return Promise.reject(error);
  }
);

export default api; 