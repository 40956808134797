import React, { useMemo } from 'react';
import { FaStar } from 'react-icons/fa';

// Exporter la fonction pour qu'elle puisse être utilisée ailleurs
export function getButtonClass(typeName) {
  const lower = typeName.toLowerCase();
  switch (lower) {
    case 'consultation': return 'button-blue';
    case 'note': return 'button-yellow';
    case 'lettre': return 'button-green';
    case 'texte': return 'button-orange';
    case 'mail': return 'button-cyan';
    case 'traduction': return 'button-pink';
    case 'examen': return 'button-purple';
    case 'liste': return 'button-cyan';
    case 'diagnostic': return 'button-mint';
    case 'transmission': return 'button-indigo';
    case 'entree': return 'button-brown';
    case 'synthèse': return 'button-pink';
    case 'echocardiographie':
    case 'ergometrie':
    case 'coronarographie':
    case 'ecg':
      return 'button-pink';
    case 'inconnu': return 'button-red';
    default: return 'button-info';
  }
}
const defaultsTypes = ['consultation', 'texte', 'note', 'mail'];

export default function ReportTypeSelector({
  reportTypes = [],
  onSelect,
  onToggleFavorite
}) {
  

  const processedTypes = useMemo(() => {
    // On met à jour les types en gardant les favoris existants et en ajoutant les défauts
    let updated = reportTypes.map((rt) => {
      const lowerName = rt.name?.toLowerCase?.();
      if (defaultsTypes.includes(lowerName)) {
        return { ...rt, favorite: true };
      }
      return rt;
    });

    // Tri avec les favoris en premier
    updated = [...updated].sort((a, b) => {
      if (a.favorite && !b.favorite) return -1;
      if (!a.favorite && b.favorite) return 1;
      return 0;
    });
    return updated;
  }, [reportTypes]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {processedTypes.map((rt, index) => {
        return (
          <div
            key={rt.name}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '10px',
            }}
          >
            <button
              className={`button ${getButtonClass(rt.name)}`}
              onClick={() => onSelect(rt.name)}
            >
              {<span style={{ textTransform: "capitalize" }}>{rt.name} </span>}
            </button>

            <FaStar
              onClick={() => onToggleFavorite(rt.name)}
              style={{
                cursor: 'pointer',
                color: rt.favorite ? 'gold' : '#999'
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
