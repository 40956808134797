import React, {  useState } from "react";
import EchoReportView from "./EchoReportView";
import Button from "../../Helpers/Button";
import { FaChartLine } from "react-icons/fa";

export default function PopoverBullseye({ btnRef, bullseyeData, onBullseyeChange }) {
  const [showBullseye, setShowBullseye] = useState(false);

  const toggleBullseye = () => {
    setShowBullseye(prev => !prev);
  };

  return (
    <div>
      <Button icon={FaChartLine} variant="orange" ref={btnRef} onClick={toggleBullseye}>
        Schéma cinétique
      </Button>

      <EchoReportView
        anchorEl={btnRef.current}
        visible={showBullseye}
        onClose={() => setShowBullseye(false)}
        // on récupère l'état depuis le parent :
        bullseyeData={bullseyeData}
        // on passe le callback directement
        onBullseyeChange={onBullseyeChange}
      />
    </div>
  );
}
