// src/components/AnnexPanel.js
import React, { useState } from 'react';
import Button from '../Helpers/Button';
import Card from '../styles/card';
import { FaPaste, FaEraser, FaCamera, FaFileImport, FaTimes, FaPhotoVideo } from 'react-icons/fa';
import ScreenshotPicker from './ScreenshotPicker'; 
import {  isCapacitor } from '../App';

export default function AnnexPanel({
  annexText,
  setAnnexText,
  onPaste,
  onClearAnnex,
  onTakePhoto,
  onSelectFromLibrary,
  fileInputRef,
  onFileChange,
  onClose
}) {
  const [showScreenshotPicker, setShowScreenshotPicker] = useState(false);
  const [isProMode] = useState(() => localStorage.getItem('isProMode') === 'true');

  function handleScreenshotDone(recognizedText) {
    // Append recognized text to the annex
    setAnnexText((prev) => prev + `\n${recognizedText}\n`);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      onFileChange({ target: { files } });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <Card 
      style={{ width: '90%', maxWidth: '400px', overflowY: 'auto' }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    > 
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <h4 style={{ marginTop: 0, marginBottom: 0 }}>Annexe</h4>
        <Button 
        icon={FaTimes}
        variant="secondary" 
        onClick={onClose}
        style={{ marginTop: 0, marginBottom: 0 }}>
          Fermer
        </Button>
      </div>
      <textarea
        style={{
          width: '100%',
          height: '180px',
          borderRadius: '4px',
          padding: '6px',
          marginBottom: '8px',
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          resize: 'vertical'
        }}
        value={annexText}
        onChange={(e) => setAnnexText(e.target.value)}
        placeholder="Contenu de l'annexe..."
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          {isCapacitor ? (
            <>
              <Button 
              icon={FaCamera}
              variant="reporttype-consultation"
               onClick={onTakePhoto}
               collapseOnSmallScreen={false}
               >
                Scanner
              </Button>
              <Button 
              icon={FaPhotoVideo}
              variant="info"
              onClick={onSelectFromLibrary}
              collapseOnSmallScreen={false}
              >
                Librairie
              </Button>
            </>
          ) : (
            <>
              <Button 
              icon={FaCamera}
              variant="reporttype-consultation"
              onClick={() => setShowScreenshotPicker(true)}
              collapseOnSmallScreen={false}
              >
                Capture d'écran
              </Button>
              <Button icon={FaFileImport} variant="info" onClick={onSelectFromLibrary}
                collapseOnSmallScreen={false}
                >
                Importer
              </Button>
            </>
          )}
          {isProMode && (
            <Button 
            icon={FaPaste}
            variant="info" 
            onClick={onPaste}>
            Coller
          </Button>
          )}
        </div>
        <Button 
        icon={FaEraser}
        variant="danger" 
        onClick={onClearAnnex}>
           Vider
        </Button>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/*,.pdf,.doc,.docx"
        onChange={onFileChange}
      />

      {showScreenshotPicker && (
        <ScreenshotPicker
          onClose={() => setShowScreenshotPicker(false)}
          onScreenshotDone={handleScreenshotDone}
        />
      )}
    </Card>
  );
}