import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import Bullseye17Segments from "./BullseyeDiagram";
import { FaDownload, FaTimes, FaEllipsisV, FaCopy} from "react-icons/fa";
import Button from "../../Helpers/Button";
import domtoimage from "dom-to-image";

export default function EchoReportView({
  anchorEl,
  visible,
  onClose,
  bullseyeData = {},
  onBullseyeChange,
}) {
  const popperRef = useRef(null);
  const optionsButtonRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

 usePopper(
    optionsButtonRef.current,
    null,
    {
      placement: "bottom-end",
      modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    }
  );

  const { styles, attributes } = usePopper(anchorEl, popperRef.current, {
    placement: "auto",
    modifiers: [
      { name: "offset", options: { offset: [0, 10] } },
      { name: "preventOverflow", options: { padding: 8 } },
    ],
  });

  // Applique la même anomalie à tous les segments
  const applyToAll = (anomaly) => {
    const segments = [
      "apex",
      "basal_anterior",
      "basal_anteroseptal",
      "basal_inferoseptal",
      "basal_inferior",
      "basal_inferolateral",
      "basal_anterolateral",
      "mid_anterior",
      "mid_anteroseptal",
      "mid_inferoseptal",
      "mid_inferior",
      "mid_inferolateral",
      "mid_anterolateral",
      "apical_anterior",
      "apical_septal",
      "apical_inferior",
      "apical_lateral",
    ];
    const newData = {};
    segments.forEach((seg) => {
      newData[seg] = anomaly;
    });
    onBullseyeChange(newData);
    setShowOptions(false);
  };

  const handleCopySVG = () => {
    const node = document.getElementById("bullseye-wrapper");
    if (!node) return;
  
    // On choisit un facteur d'échelle, disons x2
    const scale = 1;
  
    // Récupère la taille courante
    const style = window.getComputedStyle(node);
    const width = parseInt(style.getPropertyValue('width'), 10);
    const height = parseInt(style.getPropertyValue('height'), 10);
  
    domtoimage
      .toPng(node, {
        width: width * scale,
        height: height * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          // On peut aussi retirer les éventuelles marges ou backgrounds
          // si besoin, par ex : background: '#fff'
        },
      })
      .then((dataUrl) => {
        return fetch(dataUrl);
      })
      .then((res) => res.blob())
      .then((blob) => {
        // Création du ClipboardItem
        const clipboardItem = new ClipboardItem({
          "image/png": blob, // pour un PNG
          // "image/svg+xml": blob, si c’est un SVG
        });
        return navigator.clipboard.write([clipboardItem]);
      })
      .then(() => {
        console.log("Image copiée dans le presse-papier !");
      })
      .catch((err) => {
        console.error("Erreur lors de la copie :", err);
      });
  };

  // Téléchargement via dom-to-image
  const handleDownloadPNG = () => {
    const node = document.getElementById("bullseye-wrapper");
    if (!node) return;
  
    // On choisit un facteur d'échelle, disons x2
    const scale = 1;
  
    // Récupère la taille courante
    const style = window.getComputedStyle(node);
    const width = parseInt(style.getPropertyValue('width'), 10);
    const height = parseInt(style.getPropertyValue('height'), 10);
  
    domtoimage
      .toPng(node, {
        width: width * scale,
        height: height * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          // On peut aussi retirer les éventuelles marges ou backgrounds
          // si besoin, par ex : background: '#fff'
        },
      })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `bullseye_${crypto.randomUUID()}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('dom-to-image =>', err);
      });
  };

  if (!visible) return null;

  return (
    <div
      ref={popperRef}
      style={{
        ...styles.popper,
        zIndex: 9999,
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 6,
        padding: "8px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
      }}
      {...attributes.popper}
      onClick={(e) => e.stopPropagation()}
    >
      {/* Barre de boutons */}
      <div
        style={{
          marginBottom: 8,
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <Button icon={FaDownload} variant="word" onClick={handleDownloadPNG}>
            Télécharger
          </Button>
          <Button icon={FaCopy} variant="info" onClick={handleCopySVG}>
            Copier
          </Button>
        </div>

        <div style={{ display: "flex", gap: "8px" }}>
          <div style={{ position: "relative" }}>
            <Button
              ref={optionsButtonRef}
              icon={FaEllipsisV}
              variant="print"
              onClick={() => setShowOptions(!showOptions)}
            />
            {showOptions && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  marginTop: "8px",
                  background: "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "4px 0",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                  zIndex: 9999,
                  minWidth: "160px",
                }}
              >
                <div style={{
                  padding: "8px 12px",
                  borderBottom: "1px solid #eee",
                  fontWeight: "bold",
                  color: "#666"
                }}>
                  Tout changer en:
                </div>
                <button
                  onClick={() => applyToAll("Normal")}
                  style={btnStyle}
                >
                  <div style={{ ...colorBox, backgroundColor: "lightgreen" }} />
                  Normal
                </button>
                <button
                  onClick={() => applyToAll("Hypokinésie légère")}
                  style={btnStyle}
                >
                  <div style={{ ...colorBox, backgroundColor: "#ffe0b3" }} />
                  Hypo légère
                </button>
                <button
                  onClick={() => applyToAll("Hypokinésie modérée")}
                  style={btnStyle}
                >
                  <div style={{ ...colorBox, backgroundColor: "#ffbf80" }} />
                  Hypo modérée
                </button>
                <button
                  onClick={() => applyToAll("Hypokinésie sévère")}
                  style={btnStyle}
                >
                  <div style={{ ...colorBox, backgroundColor: "#ff9f40" }} />
                  Hypo sévère
                </button>
                <button
                  onClick={() => applyToAll("Non évaluable")}
                  style={btnStyle}
                >
                  <div style={{ ...colorBox, backgroundColor: "#fff" }} />
                  Non évaluable
                </button>
              </div>
            )}
          </div>

          <Button icon={FaTimes} variant="secondary" onClick={onClose} />
        </div>
      </div>

      {/* ICI : on englobe Bullseye + légende dans un <div id="bullseye-wrapper"> */}
      <div id="bullseye-wrapper">
        <Bullseye17Segments
          className="bullseye-diagram"
          value={bullseyeData}
          onChange={onBullseyeChange}
          width={300}
          height={300}
        />
      </div>
    </div>
  );
}

/* Styles inline utiles pour les boutons du menu */
const btnStyle = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "8px 12px",
  border: "none",
  background: "none",
  textAlign: "left",
  cursor: "pointer",
  fontSize: "14px",
};

const colorBox = {
  width: 12,
  height: 12,
  marginRight: 8,
  border: "1px solid #ccc",
};