import axios from 'axios';

/**
 * doOCRWithGoogleVision
 * @param {string} base64Data - The raw base64 (no data: prefix).
 * @param {string} googleApiKey - Google Cloud Vision API key.
 * @param {string} [mimeType='image/png'] - e.g. 'application/pdf' or 'image/png'.
 * @returns {Promise<string>} - The extracted text.
 */
export async function doOCRWithGoogleVision(base64Data, googleApiKey, mimeType = 'image/png') {
  // Check if PDF
  const isPDF = mimeType.toLowerCase().includes('pdf');

  // The endpoint differs for PDF vs. image
  const endpoint = isPDF
    ? `https://vision.googleapis.com/v1/files:annotate?key=${googleApiKey}`
    : `https://vision.googleapis.com/v1/images:annotate?key=${googleApiKey}`;

  // Build request body differently for PDF or image
  let requestBody;
  if (isPDF) {
    // For small PDFs => synchronous "files:annotate" 
    requestBody = {
      requests: [
        {
          inputConfig: {
            content: base64Data,        // raw base64
            mimeType: 'application/pdf' // must match
          },
          features: [
            { type: 'DOCUMENT_TEXT_DETECTION' } 
          ]
        }
      ]
    };
  } else {
    // For images => "images:annotate"
    requestBody = {
      requests: [
        {
          image: {
            content: base64Data
          },
          features: [
            { type: 'TEXT_DETECTION' }
          ]
        }
      ]
    };
  }

  // Make the POST request
  const response = await axios.post(endpoint, requestBody, {
    headers: { 'Content-Type': 'application/json' }
  });

  // Parse response
  if (isPDF) {
    // For files:annotate, the response format is slightly different. 
    // It's typically: { responses: [ { fullTextAnnotation: { text: ... } } ] }
    // But it can also nest within responses[0].responses[0].
    const data = response.data;
    if (
      data &&
      data.responses &&
      data.responses[0] &&
      data.responses[0].fullTextAnnotation &&
      data.responses[0].fullTextAnnotation.text
    ) {
      return data.responses[0].fullTextAnnotation.text;
    } else if (
      data.responses &&
      data.responses[0] &&
      data.responses[0].responses &&
      data.responses[0].responses[0] &&
      data.responses[0].responses[0].fullTextAnnotation
    ) {
      return data.responses[0].responses[0].fullTextAnnotation.text;
    }
    // If no text found:
    return '';
  } else {
    // For images:annotate
    const data = response.data;
    if (
      data &&
      data.responses &&
      data.responses[0] &&
      data.responses[0].fullTextAnnotation &&
      data.responses[0].fullTextAnnotation.text
    ) {
      return data.responses[0].fullTextAnnotation.text;
    }
    return '';
  }
}