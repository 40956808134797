import React, { useEffect } from 'react';
import './Notification.css';

function Notification({ message, type, onClose }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 2000); // La notification disparaîtra après 5 secondes

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]); // Le tableau de dépendances vide évite les re-rendus continus

  return (
    <div className={`notification notification-${type}`}>
      {message}
    </div>
  );
}

export default Notification;
